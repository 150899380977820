import axios from "axios";
import { initAPI, redirectToLogin } from "../../config/api";
import * as ActionTypes from "./type";
import { notification } from "antd";

export function updateSetting(params) {
	initAPI();

	return async dispatch => {
		await axios
			.post(`setting/update`, params)
			.then(res => {
				if (res.data.code === 200) {
					notification.success({
						message: res.data.message,
						placement: "topRight",
						top: 50,
						duration: 3
					});
					dispatch({
						type: ActionTypes.UPDATE_SETTING,
						params,
						redirect: true
					});
				} else {
					notification.error({
						message: res.data.message,
						placement: "topRight",
						top: 50,
						duration: 3
					});
				}
			})
			.catch(async err => {
				let msg = err.response.data.message;
				if (err && err.response && err.response.status === 401) {
					msg = err.response.data.message;
					await redirectToLogin(err.response.status);
				}
				notification.error({
					message: msg,
					placement: "topRight",
					top: 50,
					duration: 3
				});
			});
	};
}

export function settingWebsite(params) {
	initAPI();

	return async dispatch => {
		await axios
			.post(`setting/website`, params)
			.then(res => {
				if (res.data.code === 200) {
					const data = res.data.data;
					dispatch({
						type: ActionTypes.SETTING_WEBSITE,
						data
					});
				} else {
					notification.error({
						message: res.data.message,
						placement: "topRight",
						top: 50,
						duration: 3
					});
				}
			})
			.catch(async err => {
				let msg = err.response.data.message;
				if (err && err.response && err.response.status === 401) {
					msg = err.response.data.message;
					await redirectToLogin(err.response.status);
				}
				notification.error({
					message: msg,
					placement: "topRight",
					top: 50,
					duration: 3
				});
			});
	};
}

export function uploadImage(data) {
	initAPI();

	return async dispatch => {
		await axios
			.post(`question/upload`, data)
			.then(res => {
				if (res.data.code === 200) {
					const data = res.data.data;
					dispatch({ type: "UPLOAD_IMAGE", data });
				} else {
					notification.error({
						message: res.data.message,
						placement: "topRight",
						top: 50,
						duration: 3
					});
				}
			})
			.catch(async err => {
				let msg = err.response.data.message;
				if (err && err.response && err.response.status === 401) {
					msg = err.response.data.message;
					await redirectToLogin(err.response.status);
				}
				notification.error({
					message: msg,
					placement: "topRight",
					top: 50,
					duration: 3
				});
			});
	};
}
