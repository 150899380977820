import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { notification } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { getContactBook } from "../redux/contactbook/action";
import { createRegistation } from "../redux/register/action";
import { settingWebsite } from "../redux/setting/action.js";
import { logout } from "../redux/auth/action";

class Index extends Component {
	constructor(props) {
		super();
		this.state = {
			phoneNumber: "",

			ModalText: "",
			visible: false,
			confirmLoading: false,

			name: "",
			phone: "",
			email: "",
			subject: "",
			school: "",
			classroom: ""
		};
	}

	handleChange = e => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value
		});
	};

	handleSubmit = async e => {
		e.preventDefault();
		const data = {
			phone: this.state.phoneNumber
		};

		if (this.state.phoneNumber !== "") {
			await this.props.getContactBook(data);

			await this.props.history.push("/so-lien-lac");
		} else {
			this.phoneInput.focus();
			notification.error({
				message: "Không được để trống",
				placement: "topRight",
				top: 50,
				duration: 3
			});
		}
	};

	componentDidMount = async () => {
		window.location.href = '/login';
		await this.props.settingWebsite();
	};
	showModal = () => {
		this.setState({
			visible: true
		});
	};

	handleCancel = () => {
		this.setState({
			visible: false
		});
	};

	handleRegister = async () => {
		if (this.state.name === "") {
			this.nameInput.focus();
			notification.error({
				message: "Họ tên không được để trống",
				placement: "topRight",
				top: 50,
				duration: 3
			});
		} else if (this.state.phone === "") {
			this.phoneInput.focus();
			notification.error({
				message: "Số điện thoại không được để trống",
				placement: "topRight",
				top: 50,
				duration: 3
			});
		} else if (this.state.email === "") {
			this.emailInput.focus();
			notification.error({
				message: "Email không được để trống",
				placement: "topRight",
				top: 50,
				duration: 3
			});
		} else if (this.state.subject === "") {
			this.subjectInput.focus();
			notification.error({
				message: "Môn học không được để trống",
				placement: "topRight",
				top: 50,
				duration: 3
			});
		} else if (this.state.school === "") {
			this.schoolInput.focus();
			notification.error({
				message: "Trường không được để trống",
				placement: "topRight",
				top: 50,
				duration: 3
			});
		} else if (this.state.classroom === "") {
			this.classroomInput.focus();
			notification.error({
				message: "Lớp không được để trống",
				placement: "topRight",
				top: 50,
				duration: 3
			});
		} else {
			const data = {
				fullname: this.state.name,
				subject: this.state.subject,
				classroom: this.state.classroom,
				school: this.state.school,
				email: this.state.email,
				phone: this.state.phone
			};
			await this.props.createRegistation(data);
			await this.setState({
				name: "",
				subject: "",
				classroom: "",
				school: "",
				email: "",
				phone: ""
			});
		}
	};

	logout = async () => {
		await localStorage.clear();
		await localStorage.removeItem("persist:app");
		await this.props.logout();
	};

	renderButton = () => {
		if (this.props.token !== null) {
			return (
				<ul>
					<li>
						<Link to="/app/classrooms" className="btn scroll-to">
							Tài khoản
						</Link>
					</li>
					<li>
						<a
							href="/so-lien-lac"
							id="myBtn"
							onClick={this.showModal}
							className="btn scroll-to">
							Sổ liên lạc
						</a>
					</li>
				</ul>
			);
		} else {
			return (
				<ul>
					<li>
						<Link to="/login" className="btn scroll-to">
							Đăng nhập
						</Link>
					</li>
					<li>
						<Link to="/signup" className="btn scroll-to">
							Tạo tài khoản
						</Link>
					</li>
					<li>
						<a
							href="/so-lien-lac"
							id="myBtn"
							onClick={this.showModal}
							className="btn scroll-to"
						>
							Sổ liên lạc
						</a>
					</li>
				</ul>
			);
		}
	};

	render() {
		return (
			<>			
			</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		setting: state.setting.data,
		token: state.auth.token,
		user: state.auth.user
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{ createRegistation, getContactBook, settingWebsite, logout },
		dispatch
	);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
