import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showDocument } from "../../redux/document/action";

class Documentshow extends Component {
	constructor(props) {
		super();
	}

	async componentDidMount() {
		await this.props.showDocument(this.props.match.params.id);
	}

	render() {
		let type = 'PDF';
		if (this.props.document) {
			if (this.props.document.doc_link && this.props.document.doc_link.indexOf('drive.google.com') > 0) {
				type = 'GOOGLE_DRIVE';
			}
		}
		return (
			<>
				<div className="message-show col-center page-container">
					<div className="page-title">
						<h2>Tài liệu</h2>
					</div>
					<div className="message-show-container">
						<h3
							style={{
								fontSize: "16px",
								fontWeight: 600,
								marginBottom: 20
							}}
						>
							{this.props.document ? this.props.document.name : null}
						</h3>
						{
							type === 'GOOGLE_DRIVE' ?
								<div><iframe title="Doc" frameBorder={0} width="100%" height="500px" src={this.props.document ? this.props.document.doc_link + '?embedded=true' : null}></iframe></div>
								:
								<div>
									<iframe title="Doc" src={this.props.document ? 'https://docs.google.com/viewer?url=' + this.props.document.doc_link + '&embedded=true' : null} width="100%" height="500px" frameBorder="0"></iframe>
								</div>
						}
					</div>
				</div>
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		document: state.document.document
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ showDocument }, dispatch);
}

let DocumentShowContainer = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Documentshow)
);

export default DocumentShowContainer;
