import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import { listMember } from '../redux/member/action';

class Row extends Component {
	constructor(props) {
		super();
		this.state = {};
	}

	render() {
		const { fullname, total_point, total_testing } = this.props.obj;
		return (
			<tr>
				<td width={50} style={{ textAlign: 'center' }}>
					{this.props.index + 1}
				</td>
				<td>
					<span>{fullname}</span>
				</td>
				<td style={{ textAlign: 'center' }}>
					{total_testing > 0
						? Math.round(total_point / total_testing)
						: 0}
				</td>
				<td style={{ textAlign: 'center' }}>{total_testing}</td>
			</tr>
		);
	}
}

class Member extends Component {
	constructor(props) {
		super(props);
		const date = new Date();
		let month = date.getMonth() + 1;
		let year = date.getFullYear();
		this.state = {
			keyword: '',
			month: month,
			year: year,
			limit: 20
		};
	}

	fetchRows() {
		if (this.props.members instanceof Array) {
			return this.props.members.map((object, i) => {
				return (
					<Row
						obj={object}
						key={object._id}
						index={i}
						{...this.props}
					/>
				);
			});
		}
	}

	onChange = async e => {
		var name = e.target.name;
		var value = e.target.value;
		await this.setState({
			[name]: value,
		});
	};

	handleChangeSelect = async e => {
		var name = e.target.name;
		var value = e.target.value;
		await this.setState({
			[name]: value,
		});
		await this.props.listMember(this.getData());
	};

	getData = (pageNumber = 1) => {
		const data = {
			page: pageNumber,
			limit: this.state.limit,
			id: this.props.class_id,
			month: parseInt(this.state.month),
			year: parseInt(this.state.year),
		};
		if (this.state.keyword != null) {
			data['keyword'] = this.state.keyword;
		}
		return data;
	};

	async componentDidMount() {
		await this.props.listMember(this.getData());
		if (this.props.limit) {
			await this.setState({
				limit: this.props.limit,
			});
		}
	}

	onSubmit = e => {
		e.preventDefault();
		this.props.listMember(this.getData());
	};

	fetchMonth = () => {
		const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
		return month.map(month => {
			return (
				<option value={month} key={month}>
					Tháng {month}
				</option>
			);
		});
	};

	handleChangePage = async (pageNumber) => {
		await this.props.listMember(this.getData(pageNumber));
	};

	fetchYear = () => {
		const datetime = new Date();

		const yearNow = datetime.getFullYear();

		const yearAgo = datetime.getFullYear() - 2;

		var arr = [];
		var i;
		for (i = parseInt(yearAgo); i <= parseInt(yearNow); i++) {
			arr.push(i);
		}

		if (arr.length !== 0) {
			return arr.map(year => {
				return (
					<option value={year} key={year}>
						Năm {year}
					</option>
				);
			});
		}
	};

	render() {
		return (
			<>
				<div id="header-home">
					<div className="page-title">
						<h2>Thành viên</h2>
					</div>
					<div className="page-tools">
						<div className="search-class">
							<form onSubmit={this.onSubmit}>
								<div className="form-group">
									<input
										type="text"
										placeholder="Tìm kiếm thành viên..."
										name="keyword"
										className="input-v2"
										onChange={this.onChange}
									/>
									<i
										className="fa fa-search"
										aria-hidden="true"></i>
								</div>
							</form>
						</div>
						<div className="download">
							<select
								style={{ marginLeft: 20 }}
								className="input-v2"
								name="month"
								onChange={this.handleChangeSelect}
								value={this.state.month}>
								<option value="">Chọn tháng</option>
								{this.fetchMonth()}
							</select>
							<select
								style={{ marginLeft: 20 }}
								className="input-v2"
								name="year"
								onChange={this.handleChangeSelect}
								value={this.state.year}>
								{this.fetchYear()}
							</select>
						</div>
					</div>
				</div>
				<div id="member">
					
					<div className="table-list">
						<table className="table table-striped">
							<thead>
								<tr>
									<th style={{ textAlign: 'center' }}>STT</th>
									<th>Thành viên</th>
									<th width={150} style={{ textAlign: 'center' }}>
										Điểm TB
									</th>
									<th width={150} style={{ textAlign: 'center' }}>
										Số bài nộp
									</th>
								</tr>
							</thead>
							<tbody>{this.fetchRows()}</tbody>
						</table>
					</div>
					<div style={{ marginTop: 10 }}>
						<Pagination
							activePage={this.props.page}
							itemsCountPerPage={this.props.limit}
							totalItemsCount={this.props.total}
							pageRangeDisplayed={5}
							onChange={this.handleChangePage}
						/>
					</div>
				</div>
			</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		members: state.member.members,
		limit: state.classroom.limit,
		page: state.member.page,
		total: state.member.total
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ listMember }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Member));
