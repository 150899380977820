export const LIST_TESTING = "LIST_TESTING";
export const LIST_EXAM = "LIST_EXAM";
export const SHOW_TESTING = "SHOW_TESTING";
export const DETAIL_TESTING = "DETAIL_TESTING";
export const CREATE_TESTING = "CREATE_TESTING";
export const CLICK_ANSWER = "CLICK_ANSWER";
export const RESET = "RESET";
export const INIT_ANSWER = "INIT_ANSWER";
export const COUNT_PENDING = "COUNT_PENDING";
export const VIEW_ANSWER = "VIEW_ANSWER";
export const RESET_VIEWDATA = "RESET_VIEWDATA";
export const EXAM_PREVIEW = "EXAM_PREVIEW";
export const ERROR = "ERROR";
export const ERROR_RESET = "ERROR_RESET";
