import * as ActionTypes from "./type";
const initialState = {
	classrooms: [],
	category: null,
	chapterCategories: [],
	classroom: {},
	total: 0,
	page: 1,
	limit: 20,
	ids: [],
	overviewClass: null,
	subjectData: null,
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.LIST_CLASSROOM:
			return {
				...state,
				classrooms: action.classrooms,
				total: action.total,
				limit: action.limit,
				ids: [],
			};
		case ActionTypes.SHOW_CLASSROOM:
			return {
				...state,
				classroom: action.classroom,
			};
		case ActionTypes.SHOW_CATEGORY:
			return {
				...state,
				category: action.category,
			};
		case ActionTypes.LIST_CHAPTER_CATEGORY:
			return {
				...state,
				chapterCategories: action.chapterCategories,
			};
		case ActionTypes.OVERVIEW_CLASSROOM:
			return {
				...state,
				overviewClass: action.overview,
			};
		case ActionTypes.CLASSROOM_ACCESS:
			return {
				...state,
				accessData: action.data,
			};
		case ActionTypes.SUBJECT_SHOW:
			return {
				...state,
				subjectData: action.data,
			};

		default:
			return state;
	}
};

export default reducer;
