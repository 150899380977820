import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { countPending } from "../redux/testing/action";
import * as Icon from "react-feather";

class MenuClasses extends Component {
	constructor(props) {
		super();
		this.state = {
			count: 0,
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (this.props.count !== nextProps.count) {
			this.setState({
				count: nextProps.count,
			});
		}
	}

	async componentDidMount() {
		await this.props.countPending({
			classroom_id: this.props.match.params.id,
		});

		await this.setState({
			count: this.props.count,
		});
	}

	render() {
		const path = this.props.match.url;
		const { name } = this.props.classroom;
		return (
			<>
				<div className="list col-left leftMenu">
					<div className="sidebar-logo">
						<a href="/"><img src="https://cdn.luyenthitiendat.vn/assets/v2/logo.svg" alt="DCV" /></a>
					</div>
					<div className="box-title" style={{ display: 'none' }}>
						{
							this.props.classroom.image ? <div className="box-yellow"><img src={this.props.classroom.image} alt="" /></div> : null
						}
						<div className="title-left">
							<h2>{name}</h2>
						</div>
					</div>
					<ul>
					<li>
							<NavLink
								to={"/app/classrooms"}
								className="click_t_menu"
								activeClassName="active"
								exact
							>
								<div className="class-menu-icon">
									<Icon.Tag style={{ width: '20px', color: '#aaa' }} />
								</div>
								Danh sách lớp
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`${path}`}
								className="click_t_menu"
								activeClassName="active"
								exact
							>
								<div className="class-menu-icon">
									<Icon.Grid style={{ width: '20px', color: '#aaa' }} />
								</div>
								Tổng quan
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`${path}/members`}
								className="click_t_menu"
								activeClassName="active"
							>
								<div className="class-menu-icon">
									<Icon.Users style={{ width: '20px', color: '#aaa' }} />
								</div>
								Thành viên
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`${path}/testings`}
								className="click_t_menu"
								activeClassName="active"
							>
								<div className="class-menu-icon">
									<Icon.Book style={{ width: '20px', color: '#aaa' }} />
								</div>
								Bài học
								{this.state.count !== 0 ? (
									<div className="notification">
										<i
											className="fa fa-bell"
											aria-hidden="true"
										></i>
										<span>{this.state.count}</span>
									</div>
								) : null}
							</NavLink>
						</li>
						<li>
							<a
								href={
									this.props.subjectData !== null &&
										this.props.subjectData.support_fb_link
										? this.props.subjectData.support_fb_link
										: "https://m.me/107291767347753"
								}
								className="click_t_menu"
								target="_blank"
								rel="noopener noreferrer"
							>
								<div className="class-menu-icon">
									<Icon.HelpCircle
										style={{ width: '20px', color: '#aaa' }}
									/>
								</div>
								Hỏi đáp
							</a>
						</li>
						<li style={{ display: "none" }}>
							<NavLink
								to={`${path}/documents`}
								className="click_t_menu"
								activeClassName="active"
							>
								<div className="class-menu-icon">
									<Icon.BookOpen style={{ width: '20px', color: '#aaa' }} />
								</div>
								Kho tài liệu
							</NavLink>
						</li>
					</ul>
				</div>
			</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		classroom: state.classroom.classroom,
		count: state.testing.countPending,
		subjectData: state.classroom.subjectData,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ countPending }, dispatch);
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(MenuClasses)
);
