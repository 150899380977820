import * as ActionTypes from "./type";
import axios from "axios";
import { initAPI, notify, responseError, setCookie } from "../../config/api";

export const logout = () => {
	return dispatch => {
		dispatch({ type: ActionTypes.LOGOUT });
	};
};

export function login(data) {
	initAPI();
	return async dispatch => {
		await axios
			.post(`/auth/signin`, data)
			.then(res => {
				notify(res);
				if (res.data.code === 200) {
					setCookie('TOKEN', res.data.data.token, 7);
					let token = res.data.data.token;
					let user = res.data.data;
					dispatch({ type: ActionTypes.LOGIN, token, user });
				}
			})
			.catch(async err => {
				responseError(err, false);
			});
	};
}

export function signup(data) {
	initAPI();
	return async dispatch => {
		await axios
			.post(`/auth/signup`, data)
			.then(res => {
				notify(res);
				if (res.data.code === 200) {
					setCookie('TOKEN', res.data.data.token, 7);
					let token = res.data.data.token;
					dispatch({ type: ActionTypes.SIGNUP, token });
				}
			})
			.catch(async err => {
				responseError(err, false);
			});
	};
}

export function forgotPassword(data) {
	initAPI();
	return async dispatch => {
		await axios
			.post(`/forgot-password`, data)
			.then(res => {
				notify(res);
			})
			.catch(async err => {
				responseError(err, false);
			});
	};
}
