import * as ActionTypes from './type';
const initialState = {
	members: [],	
	page: 1,
	total: 0,	
	limit: 20,
	ids: [],
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.LIST_MEMBER:
			return {
				...state,
				members: action.members,
				total: action.total,
				limit: action.limit,
				ids: [],
			};
		case 'PAGING':
			return {
				...state,
				page: action.page
			};
		default:
			return state;
	}
};

export default reducer;
