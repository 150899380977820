import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showMessage } from "../../redux/message/action";

class MessageShow extends Component {
	constructor(props) {
		super();
	}

	async componentDidMount() {
		await this.props.showMessage(this.props.match.params.id);
	}

	render() {
		return (
			<>
			<div id="header-home">
					<div className="page-title">
						<h2>Thông báo</h2>
					</div>
				</div>
				<div className="message-show col-center page-container">
					<div className="message-show-container">
						<h3 style={{fontSize: "20px",fontWeight: 700, marginBottom: 30}}>
							{this.props.message ? this.props.message.name : null }
						</h3>

						<div className="message-body">{this.props.message ? this.props.message.content : null }</div>
					</div>
				</div>
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		message: state.message.message
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ showMessage }, dispatch);
}

let MessageShowContainer = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(MessageShow)
);

export default MessageShowContainer;
