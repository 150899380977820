import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { forgotPassword } from '../redux/auth/action';

class ForgetPassword extends Component {
	constructor(props) {
		super();
		this.state = {
			email: '',
		};
	}
	componentDidMount() {
		const { token, updateToken } = this.context;
		this.setState({
			token,
			updateToken,
		});
	}
	handleChange = e => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value,
		});
	};

	handleSubmit = e => {
		e.preventDefault();
		const data = {
			email: this.state.email,
		};
		this.props.forgotPassword(data);
	};
	render() {
		return (
			<div id="box-login" className="abcd">
				<div className="login-left">
					<img className="shape-2" src="https://cdn.luyenthitiendat.vn/assets/v2/anhdaidien.svg" alt="Dai Co Viet" />
					<a href="/" className="logo">
						<img src="https://cdn.luyenthitiendat.vn/assets/v2/logo-dcv.svg" alt="Dai Co Viet" />
					</a>
				</div>
				<div className="login-right">
					<div className="login-heading">
						<h2>Quên mật khẩu</h2>
						<div className="registration">
							<span>Đã có tài khoản?</span>
							<Link className="signup" to="/login">Đăng nhập</Link>
						</div>
					</div>
					<form onSubmit={this.handleSubmit}>
						<div className="formcontrol">
							<div className="controlinput">
								<input
								className="input-v2"
									type="text"
									placeholder="Nhập vào tài khoản email của bạn"
									value={this.state.email}
									onChange={this.handleChange}
									name="email"
								/>
							</div>
						</div>
						<button type="submit" className="button-v2">
							Tiếp tục
						</button>
					</form>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		token: state.auth.token,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ forgotPassword }, dispatch);
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ForgetPassword),
);
