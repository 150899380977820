import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { imgURL } from "../config/config";
import Moment from "moment";
import Teacher from "../../src/teacher.png";
import * as Icon from 'react-feather';
import { Modal } from "antd";
import { showProfile, updateProfile, changePassword } from "../redux/profile/action";
import { notify } from "../config/api";

class Profile extends Component {
	constructor(props) {
		super();
		this.state = {
			_id: "",
			fullname: "",
			email: "",
			phone: "",
			avatar: "",
			code: "",
			gender: "",
			parent_phone: "",
			classroom: "",
			school: "",
			selectedFile: null,
			point: "",
			dob: "",
			created_at: null,
			visibleResult: false,
			password: "",
			new_password: "",
			isChangePass: false
		};
	}

	handleClickChangePass = (e) => {
		this.setState({
			isChangePass: true
		});
	}

	async shouldComponentUpdate(newProps, newState) {
		if (this.state.avatar !== newProps.userInfo.avatar) {
			this.setState({
				avatar: newProps.userInfo.avatar,
			});
			return true;
		}
		if (this.props.userInfo.phone !== newProps.userInfo.phone) {
			var visibleResult = false;
			if (
				newProps.userInfo.phone === "" ||
				newProps.userInfo.phone == null
			) {
				visibleResult = true;
			}
			this.setState({
				phone: newProps.userInfo.phone,
				visibleResult,
			});
			return true;
		}
	}

	UNSAFE_componentWillReceiveProps(newProps) { }

	async componentDidMount() {
		await this.props.showProfile();
		if (this.props.userInfo) {
			var {
				_id,
				fullname,
				email,
				phone,
				avatar,
				code,
				gender,
				parent_phone,
				classroom,
				school,
				point,
				dob,
				created_at,
			} = this.props.userInfo;
			var visibleResult = false;
			if (phone === "" || phone == null) {
				visibleResult = true;
			}

			this.setState({
				_id,
				fullname,
				email,
				phone,
				avatar,
				code,
				gender,
				parent_phone: parent_phone == null ? "" : parent_phone,
				classroom: classroom == null ? "" : classroom,
				school: school == null ? "" : school,
				point,
				dob,
				visibleResult,
			});

			if (created_at) {
				this.setState({
					created_at: Moment(created_at).format(
						"DD/MM/YYYY"
					)
				})
			}
		}
	}

	handleChange = (e) => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value,
		});
	};

	onChangeHandler = (event) => {
		this.setState({
			files: event.target.files[0],
		});

		if (event.target.files[0]) {
			this.setState({
				avatar: "1",
			});
			var reader = new FileReader();
			reader.onload = function (e) {
				document.getElementById("sAvatar").src = e.target.result;
			};

			reader.readAsDataURL(event.target.files[0]);
		}
	};

	handleCancel = () => {
		this.setState({
			isChangePass: false
		});
	};

	handleSubmit = async (e) => {
		e.preventDefault();

		const data = new FormData();
		data.append("id", this.state._id);
		data.append("fullname", this.state.fullname);
		data.append("email", this.state.email);
		data.append("phone", this.state.phone);
		data.append("avatar", this.state.files);
		data.append("code", this.state.code);
		data.append("gender", this.state.gender);
		data.append("parent_phone", this.state.parent_phone);
		data.append("school", this.state.school);
		data.append("classroom", this.state.classroom);
		data.append("dob", this.state.dob);

		await this.props.updateProfile(data);
		if (this.props.token !== null) {
			await this.props.showProfile();
		} else {
			this.props.history.push("/login");
		}
	};

	replaceAvatar = () => {
		if (this.state.fullname != null) {
			const name = this.state.fullname;
			let arr = name.split(" ");
			let length = parseFloat(arr.length) - 1;
			let firstLetter = arr[0][0];
			let secondLetter = arr[length][0];
			return firstLetter + secondLetter;
		}
	};

	randomColor = () => {
		const color =
			"#" +
			"0123456789abcdef"
				.split("")
				.map(function (v, i, a) {
					return i > 5 ? null : a[Math.floor(Math.random() * 16)];
				})
				.join("");
		return color;
	};

	handleChangePassword = async (e) => {
		e.preventDefault();
		const data = {
			password: this.state.password,
			new_password: this.state.new_password,
			confirm: this.state.confirm,
		};

		if (this.state.password === "") {
			const res = {
				data: {
					code: 500,
					message: "Vui lòng nhập mật khẩu cũ!",
				},
			};
			notify(res);
			return false;
		}

		if (this.state.new_password === "") {
			const res = {
				data: {
					code: 500,
					message: "Vui lòng nhập mật mới!",
				},
			};
			notify(res);
			return false;
		}

		if (this.state.confirm === "") {
			const res = {
				data: {
					code: 500,
					message: "Vui lòng nhập lại mật khẩu mới!",
				},
			};
			notify(res);
			return false;
		}

		if (this.state.new_password !== this.state.confirm) {
			const res = {
				data: {
					code: 500,
					message: "Mật khẩu xác nhận không đúng!",
				},
			};
			notify(res);
			return false;
		}

		if (
			data.password !== "" &&
			data.new_password !== "" &&
			data.confirm === data.new_password
		) {
			this.props.changePassword(data);
			if (this.props.token === null) {
				this.props.history.push("/login");
			}
		}
	};

	render() {
		return (
			<>
				<div id="header-home">
					<div className="page-title">
						<h2>Thông tin tài khoản</h2>
					</div>
				</div>
				<div id="profile" className="content page-container">
					<div className="main-profile">
						<form
							ref={(el) => (this.myFormRef = el)}
							onSubmit={this.handleSubmit}
						>
							<div className="form-group form-group-top">
								<div className="col-avatar">
									<div className="avata-profile">
										{this.state.avatar != null ? (
											<div className="img-replace img-raised rounded-circle img-fluid">
												<img
													id="sAvatar"
													src={
														this.state.avatar !=
															null
															? imgURL +
															this.state.avatar
															: ""
													}
													alt="avatar"
													className="replace-avatar"
												/>
											</div>
										) : (
											<div className="avatar-contact-book">
												<div
													className="replace-avatar"
													style={{
														backgroundColor:
															"#7519B7",
													}}
												>
													{this.replaceAvatar()}
												</div>
											</div>
										)}
									</div>
								</div>
								<div className="col-profile col-profile-right">
									<p className="name">
										{this.state.fullname}
									</p>
									<div className="user-info-meta"><span>Mã học sinh: <strong>{this.state.code}</strong></span> <span>Ngày tham gia: <strong>{this.state.created_at}</strong></span></div>
									<div className="update-file">
										<button className="btn-file">
											<Icon.Image style={{ width: '14px', color: '#fff', stroke: '#fff' }} />
										</button>
										<input
											type="file"
											onChange={this.onChangeHandler}
											name="files"
										/>
									</div>
								</div>
							</div>
							{/* Code */}
							<div className="form-group">
								<div className="col-6">
									<span>Họ và tên</span>
									<div className="control-input">
										<input
											type="text"
											value={this.state.fullname}
											onChange={this.handleChange}
											name="fullname"
											className="input-v2"
										/>
									</div>
								</div>
								<div className="col-6">
									<span>Giới tính</span>
									<div className="gender">
										<select
											value={this.state.gender}
											name="gender"
											className="input-v2"
											onChange={this.handleChange}
										>
											<option value="">Giới tính</option>
											<option
												value="Male"
												{...(this.props.userInfo
													.gender === "Male"
													? 'selected="selected"'
													: "")}
											>
												Nam
											</option>
											<option
												value="Female"
												{...(this.props.userInfo
													.gender === "Female"
													? 'selected="selected"'
													: "")}
											>
												Nữ
											</option>
										</select>
									</div>
								</div>
							</div>
							{/* end */}
							{/* Họ và tên */}
							<div className="form-group">
								<div className="col-6">
									<span>Ngày sinh</span>
									<div className="control-input">
										<input
											type="date"
											value={this.state.dob}
											placeholder="dd/mm/yyyy"
											onChange={this.handleChange}
											name="dob"
											className="input-v2"
										/>
									</div>

								</div>
								<div className="col-6">
									<span>Email</span>
									<div className="control-input">
										<input
											type="text"
											value={this.state.email}
											onChange={this.handleChange}
											name="email"
											className="input-v2"
										/>
									</div>
								</div>
							</div>
							{/* end */}
							{/* sđt hoc sinh */}
							<div className="form-group">
								<div className="col-6">
									<span>Số điện thoại</span>
									<div className="control-input">
										<input
											type="text"
											value={this.state.phone}
											onChange={this.handleChange}
											name="phone"
											ref={(input) => {
												this.phoneInput = input;
											}}
											className="input-v2"
										/>
									</div>
								</div>
								<div className="col-6">
									<span>SĐT phụ huynh</span>
									<div className="control-input">
										<input
											type="text"
											value={this.state.parent_phone}
											onChange={this.handleChange}
											name="parent_phone"
											className="input-v2"
										/>
									</div>
								</div>
							</div>
							<div className="form-group">
								<div className="col-6">
									<span>Trường</span>
									<div className="control-input">
										<input
											type="text"
											value={this.state.school}
											onChange={this.handleChange}
											name="school"
											className="input-v2"
										/>
									</div>
								</div>
								<div className="col-6">
									<span>Lớp</span>
									<div className="control-input">
										<input
											type="text"
											value={this.state.classroom}
											onChange={this.handleChange}
											name="classroom"
											className="input-v2"
										/>
									</div>
								</div>
							</div>
							{/* end */}
							{/* Lớp*/}
							<div className="form-group">

								<div className="col-6">
									<span>Mục tiêu điểm thi</span>
									<div className="control-input">
										<input
											type="text"
											name=""
											placeholder="Tổng điểm thi 3 môn"
											className="input-v2"
										/>
									</div>
								</div>
							</div>
							<div className="button_profile">
								<button type="submit" className="button-v2">
									Cập nhật
								</button>
								<span style={{ marginLeft: 30, cursor: 'pointer' }} onClick={this.handleClickChangePass}>Đổi mật khẩu</span>
							</div>
						</form>
					</div>
				</div>
				<Modal
					// title="Thông báo kết quả"
					bodyStyle={{
						minHeight: 200,
					}}
					closable={false}
					visible={this.state.visibleResult}
					onOk={() => {
						this.setState({ visibleResult: false });
						this.phoneInput.focus();
					}}
					onCancel={() => console.log("đóng")}
					okText="Đồng ý"
					cancelText="Làm lại"
					cancelButtonProps={{
						style: {
							display: "none",
						},
					}}
				>
					<div className="widget">
						<div className="widget-left">
							<img src={Teacher} alt="hinh anh" />
						</div>
						<div className="widget-right">
							<p className="widget-info">
								Vui lòng nhập số điện thoại để có thể dễ dàng
								tìm kiếm thông tin và đăng nhập bằng số điện
								thoại!
							</p>
						</div>
					</div>
				</Modal>

				<Modal
					footer={null}
					width={500}
					closable={true}
					visible={this.state.isChangePass}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					okText="Bắt đầu ngay"
					cancelText="Làm lại"
					cancelButtonProps={{
						style: {
							display: "none",
						},
					}}
				>
					<h3 className="text-center">Đổi mật khẩu</h3>
					<form
						onSubmit={this.handleSubmit}
						action=""
						method="post"
						id="from_change_pass"
					>
						<div className="formcontrol">
							<div className="controlinput">
								<input
									type="password"
									placeholder="Mật khẩu cũ..."
									value={this.state.password}
									onChange={this.handleChange}
									name="password"
									className="form-control"
									required
								/>
							</div>
						</div>
						<div className="formcontrol">
							<div className="controlinput">
								<input
									type="password"
									placeholder="Mật khẩu mới..."
									value={this.state.new_password}
									onChange={this.handleChange}
									name="new_password"
									id="new_password"
									className="form-control"
									required
								/>
							</div>
						</div>
						<div className="formcontrol">
							<div className="controlinput">
								<input
									type="password"
									placeholder="Xác nhận mật khẩu..."
									value={this.state.confirm}
									onChange={this.handleChange}
									name="confirm"
									className="form-control"
									required
								/>
							</div>
						</div>
						<button type="submit" className="btn-1" onClick={this.handleChangePassword}>
							Xác nhận
						</button>
					</form>

				</Modal>
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		userInfo: state.profile.userInfo,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ showProfile, updateProfile, changePassword }, dispatch);
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Profile)
);
