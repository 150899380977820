import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getVideo, resetVideo } from "../redux/video/action";
import $ from "jquery";
const base64 = require("base-64");

class Videos extends Component {
	constructor(props) {
		super();
		this.state = {
			code: "",
			id: null
		};
	}

	componentDidMount = async () => {
		if (this.props.video !== null) {
			await this.setState({
				id: this.props.video.v_id
			});

			if (this.state.id !== null) {
				await this.handlePlyrScript();
			}
		} else {
			this.props.history.push(
				"/app/classrooms/" + this.props.class_id + "/search-video"
			);
		}
		$(".modal-video").removeClass("show-modal");
	};

	handlePlyrScript = async () => {
		await this.removeScript();
		await this.addScript();
	};

	addScript = () => {
		const plyrscript = document.createElement("script");
		plyrscript.innerText =
			"var player = new Plyr('#player', {youtube: { noCookie: true, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 0 }});";
		document.body.appendChild(plyrscript);
	};

	removeScript = () => {
		var nodeList = document.getElementsByTagName("script");
		for (var i = 0; i < nodeList.length; i++) {
			if (
				nodeList[i].innerText ===
				"var player = new Plyr('#player', {youtube: { noCookie: true, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 0 }});"
			) {
				nodeList[i].remove();
			}
		}
	};

	render() {
		let videoName = "";
		if (this.state.id != null) {
			videoName = this.props.video.name;
		}
		return (
			<div id="list-video" className="page-container">
				<div className="page-title">
					<h2>Video: {videoName}</h2>
				</div>
				<div className="box-video">
					<div className="video">
						{this.state.id !== null ? (
							<div
								id="player"
								data-plyr-provider="youtube"
								data-plyr-embed-id={base64.decode(
									this.state.id
								)}
							/>
						) : (
							<div style={{ padding: 10 }}>
								Nhập code để xem video
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		video: state.video.video,
		code: state.video.code
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ getVideo, resetVideo }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Videos));
