import * as ActionTypes from './type';

const initialState = {
    contactBook: null,
    code: null,
}

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case ActionTypes.CONTACT_BOOK:
            return {
                ...state,
                contactBook: action.contactBook,
                code: action.code,
            };


        default:
            return state;
    }
}
export default reducer;