import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {showStatistic} from '../redux/profile/action';
import * as Icon from 'react-feather';

class StatusMain extends Component {
	constructor(props) {
		super();
		this.state = {
			num_classroom: '',
			total_testing: '',
		};
	}

	async componentDidMount() {
		await this.props.showStatistic();
		if (this.props.dataSatistic) {
			var {num_classroom, total_testing} = this.props.dataSatistic;
			this.setState({
				num_classroom,
				total_testing,
			});
		}
	}

	render() {
		return (
			<>
				<div className="status col-right">
					<div className="box">
						<h2>Thống kê</h2>
						<div className="question">
							<Icon.FileText style={{ width: '20px', cursor: 'pointer', color: '#666' }} />
							<span>
								Tổng kiểm tra: <b>{this.state.total_testing}</b>
							</span>
						</div>
						<div className="question">
							<Icon.Tag style={{ width: '20px', cursor: 'pointer', color: '#666' }} />
							<span>
								Số lớp tham gia:{' '}
								<b>{this.state.num_classroom}</b>
							</span>
						</div>
					</div>
				</div>
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		dataSatistic: state.profile.dataSatistic,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({showStatistic}, dispatch);
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(StatusMain),
);
