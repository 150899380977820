import React, { Component } from 'react';
import Moment from 'moment';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { imgURL } from '../config/config';
import { getContactBook } from '../redux/contactbook/action';
class Row extends Component {
	constructor(props) {
		super();
		this.state = {
			check: false,
		};
	}
	render() {
		return (
			<tr>
				<td>
					{this.props.obj.exam.code} - {this.props.obj.exam.name}
				</td>
				<td style={{ textAlign: 'center' }}>{this.props.obj.point}</td>
				<td style={{ textAlign: 'right' }}>
					{Moment(this.props.obj.created_at).format('DD/MM/YYYY')}{' '}
				</td>
			</tr>
		);
	}
}

class RowAttend extends Component {
	constructor(props) {
		super();
		this.state = {
			check: false,
		};
	}
	render() {
		return (
			<tr>
				<td>
					{Moment(this.props.obj.attended_date).format('DD/MM/YYYY')}{' '}
				</td>
				<td style={{ textAlign: 'right' }}>
					{Moment(this.props.obj.attended_date).format('HH:ss')}{' '}
				</td>
			</tr>
		);
	}
};

class RowReview extends Component {
	constructor(props) {
		super();
		this.state = {
			check: false,
		};
	}
	render() {
		return (			
			<tr>
				<td>
					{this.props.obj.teacher}
				</td>
				<td style={{ textAlign: 'left' }}>
					{this.props.obj.comment}
				</td>
			</tr>
		);
	}
};

class ContactBook extends Component {
	constructor(props) {
		super();
		this.state = {
			user: null,
			month: '',
			year: new Date().getFullYear(),
			classroom_id: '',
			total: 0,
			phone: null,
		};
	}
	handleChange = e => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value,
		});
	};
	handleClick = async e => {
		e.preventDefault();
		if (this.state.phone !== null) {
			await this.props.getContactBook({ phone: this.state.phone });
		}
	}

	handleChangeSelect = async e => {
		var name = e.target.name;
		var value = e.target.value;
		await this.setState({
			[name]: value,
		});
		const data = {
			phone: this.state.phone,
			month: parseInt(this.state.month),
			year: parseInt(this.state.year),
			classroom_id: this.state.classroom_id,
		};
		await this.props.getContactBook(data);
	};
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.contactBook) {
			const {
				user,
				bills,
				chuyencan,
				classrooms,
				testings,
				avg_point,
				rank,
				attendList,
				reviews
			} = nextProps.contactBook;
			var total = 0;
			bills.forEach(bill => {
				total += parseFloat(bill.total);
			});
			this.setState({
				user: user,
				bills,
				chuyencan,
				classrooms,
				testings,
				avg_point,
				rank,
				total,
				attendList,
				reviews
			});
		}
	}
	async componentDidMount() {
		if (this.props.contactBook != null) {
			const {
				user,
				bills,
				chuyencan,
				classrooms,
				testings,
				avg_point,
				rank,
				attendList,
				reviews
			} = this.props.contactBook;
			var total = 0;
			bills.forEach(bill => {
				total += parseFloat(bill.total);
			});
			this.setState({
				user: user,
				bills,
				chuyencan,
				classrooms,
				testings,
				avg_point,
				rank,
				total,
				attendList,
				reviews
			});
		}
	}
	stringClass = data => {
		if (data instanceof Array) {
			const arrCopy = Array.from(data, ele => ele.name);
			return arrCopy.join(', ');
		}
		return null;
	};
	fetchRowsClass() {
		if (this.state.classrooms instanceof Array) {
			return this.state.classrooms.map((obj, i) => {
				return (
					<option value={obj._id} key={obj._id.toString()}>
						{obj.name}
					</option>
				);
			});
		}
	}
	fetchRows() {
		if (
			this.state.testings instanceof Array &&
			this.state.testings.length > 0
		) {
			return this.state.testings.map((object, i) => {
				return (
					<Row
						obj={object}
						key={object._id}
						index={i}
						{...this.props}
					/>
				);
			});
		} else {
			return (
				<tr>
					<td colSpan={3}>Chưa có bài kiểm tra!</td>
				</tr>
			);
		}
	}

	fetchAttendRows() {
		if (
			this.state.attendList instanceof Array &&
			this.state.attendList.length > 0
		) {
			return this.state.attendList.map((object, i) => {
				return (
					<RowAttend
						obj={object}
						key={object._id}
						index={i}
						{...this.props}
					/>
				);
			});
		} else {
			return (
				<tr>
					<td colSpan={3}>Chưa có dữ liệu!</td>
				</tr>
			);
		}
	}

	fetchReviewRows() {
		if (
			this.state.reviews instanceof Array &&
			this.state.reviews.length > 0
		) {
			return this.state.reviews.map((object, i) => {
				return (
					<RowReview
						obj={object}
						key={object._id}
						index={i}
						{...this.props}
					/>
				);
			});
		} else {
			return (
				<tr>
					<td colSpan={3}>Chưa có dữ liệu!</td>
				</tr>
			);
		}
	}

	replaceAvatar = () => {
		if (this.state.user != null) {
			const name = this.state.user.fullname;
			let arr = name.split(' ');
			let length = parseFloat(arr.length) - 1;
			let firstLetter = arr[0][0];
			let secondLetter = arr[length][0];
			return firstLetter + secondLetter;
		}
	};
	randomColor = () => {
		const color =
			'#' +
			'0123456789abcdef'
				.split('')
				.map(function (v, i, a) {
					return i > 5 ? null : a[Math.floor(Math.random() * 16)];
				})
				.join('');
		return color;
	};
	render() {
		const prevYear = new Date().getFullYear() - 1;
		const currentYear = new Date().getFullYear();
		return (
			<>
				<div className="page-contactbook">
					<div className="container">
						<div className="logo">
							<a href="/">
								<img src="https://cdn.luyenthitiendat.vn/assets/DAICOVIET-LOGO.png" alt="" />
							</a>
						</div>
						<h1>SỔ LIÊN LẠC - THEO DÕI KẾT QUẢ HỌC TẬP</h1>
						<div className="sll-search">
							<div className="input-group mb-2">
								<input className="form-control" placeholder="Mã học sinh hoặc số điện thoại"
									name="phone"
									value={this.state.phone}
									onChange={this.handleChange}
								/>
								<div class="input-group-prepend">
									<button className="btn" onClick={this.handleClick}>Xem sổ liên lạc</button>
								</div>
							</div>
						</div>
						{this.props.code === 200 && this.props.contactBook ? (
							<div className="contactbook-main">
								<div className="">
									<div>
										{this.state.user !=
											null &&
											this.state.user
												.avatar != null ? (
												<div className="avatar">
													<div className="img-replace img-raised rounded-circle img-fluid">
														<img
															src={
																this
																	.state
																	.user !=
																	null
																	? imgURL +
																	this
																		.state
																		.user
																		.avatar
																	: ''
															}
															alt="avatar"
															className="replace-avatar"
														/>
													</div>
												</div>
											) : (
												<div className="avartar">
													<div className="img-replace img-raised rounded-circle img-fluid">
														<div
															className="replace-avatar"
															style={{
																backgroundColor:
																	'#7519B7',
															}}>
															{this.replaceAvatar()}
														</div>
													</div>
												</div>
											)}
										<div className="name">
											<h1
												className="title"
												style={{
													textTransform:
														'uppercase',
												}}>
												{this.state
													.user !=
													null
													? this.state
														.user
														.fullname
													: ''}
											</h1>
											<div className="row">
												<div className="col-md-6">
													<div className="student-info-title">
														<span className="text-cb">
															Ngày
															sinh:
                                    </span>{' '}
														<span
															style={{
																color:
																	'#d3321d',
															}}>
															{this
																.state
																.user !=
																null &&
																this
																	.state
																	.user
																	.dob
																? this
																	.state
																	.user
																	.dob
																: 'Chưa cập nhật'}
														</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="student-info-title">
														<span className="text-cb">
															Email:
                                    </span>{' '}
														<span
															style={{
																color:
																	'#d3321d',
															}}>
															{this
																.state
																.user !=
																null
																? this
																	.state
																	.user
																	.email
																: ''}
														</span>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-6">
													<div className="student-info-title">
														<span className="text-cb">
															SĐT
															cá
															nhân:
                                    </span>{' '}
														<span
															style={{
																color:
																	'#d3321d',
															}}>
															{this
																.state
																.user !=
																null
																? this
																	.state
																	.user
																	.phone
																: ''}
														</span>
													</div>
												</div>
												<div className="col-md-6">
													<div className="student-info-title">
														<span className="text-cb">
															SĐT
															phụ
															huynh:
                                    </span>{' '}
														<span
															style={{
																color:
																	'#d3321d',
															}}>
															{this
																.state
																.user !=
																null
																? this
																	.state
																	.user
																	.parent_phone
																: ''}
														</span>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-12">
													<div className="contactbook-classroom">
														<span className="text-cb">
															Lớp
															đang
															theo
															học:
                                    </span>{' '}
														<span
															style={{
																color:
																	'#d3321d',
															}}>
															{this
																.state
																.classrooms !==
																''
																? this.stringClass(
																	this
																		.state
																		.classrooms,
																)
																: ''}
														</span>
													</div>
												</div>
											</div>
											<div className="row">
												<div className="col-md-12">
													<div className="student-info-title">
														<span className="text-cb">
															Học
															phí
															đã
															nộp:
                                    </span>{' '}
														<span
															style={{
																color:
																	'#d3321d',
															}}>
															{this
																.state
																.total !==
																null
																? this.state.total.toLocaleString(
																	'en-EN',
																	{
																		minimumFractionDigits: 0,
																	},
																)
																: ''}
															đ
                                    </span>
													</div>
												</div>
											</div>
										</div>
										<div className="description">
											<div className="row">
												<div className="col-md-12">
													<div
														className="row"
														style={{
															textAlign:
																'center',
														}}>
														<div className="col-md-6">
															<select
																className="form-control contactbook-select"
																onChange={
																	this
																		.handleChangeSelect
																}
																value={
																	this
																		.state
																		.classroom_id
																}
																name="classroom_id">
																<option>Chọn lớp</option>
																{this.fetchRowsClass()}
															</select>
														</div>
														<div className="col-md-3">
															<select
																className="form-control contactbook-select"
																onChange={
																	this
																		.handleChangeSelect
																}
																value={
																	this
																		.state
																		.month
																}
																name="month">
																<option>Chọn tháng</option>
																<option value="1">Tháng 1</option>
																<option value="2">Tháng 2</option>
																<option value="3">Tháng 3</option>
																<option value="4">Tháng 4</option>
																<option value="5">Tháng 5</option>
																<option value="6">Tháng 6</option>
																<option value="7">Tháng 7</option>
																<option value="8">Tháng 8</option>
																<option value="9">Tháng 9</option>
																<option value="10">Tháng 10</option>
																<option value="11">Tháng 11</option>
																<option value="12">Tháng 12</option>
															</select>
														</div>
														<div className="col-md-3">
															<select
																className="form-control contactbook-select"
																onChange={
																	this
																		.handleChangeSelect
																}
																value={
																	this
																		.state
																		.year
																}
																name="year">
																<option>Chọn năm</option>
																<option value={prevYear}>{prevYear}</option>
																<option value={currentYear}>{currentYear}</option>																
															</select>
														</div>
													</div>
													<div
														className="row mt-3"
														style={{
															paddingTop: 20,
														}}>
														<div className="col-md-4">
															<span className="text-cb">
																Điểm
                                TB:{' '}
																{this
																	.state
																	.rank !==
																	null
																	? Math.round(
																		this
																			.state
																			.avg_point *
																		10,
																	) /
																	10
																	: 0}
															</span>
														</div>
														<div className="col-md-4">
															<span className="text-cb">
																Xếp
                                hạng:{' '}
																{this
																	.state
																	.rank !==
																	null
																	? this
																		.state
																		.rank
																	: ''}
															</span>
														</div>
														<div className="col-md-4">
															<span className="text-cb">
																Đi
                                học:{' '}
																{this
																	.state
																	.chuyencan !==
																	null
																	? this
																		.state
																		.chuyencan
																	: ''}{' '}
																buổi
                                </span>
														</div>
													</div>
													<div className="row">
														<div className="table-contactbook">
															<table className="table">
																<thead>
																	<tr>
																		<th>Bài kiểm tra</th>
																		<th
																			style={{
																				textAlign:
																					'center',
																			}}>
																			Điểm
																			số
                                            </th>
																		<th
																			style={{
																				textAlign:
																					'right',
																			}}>
																			Ngày gửi bài
                                            </th>
																	</tr>
																</thead>
																<tbody>
																	{this.fetchRows()}
																</tbody>
															</table>
														</div>
													</div>
													<div className="row">
														<div className="table-contactbook">
															<table className="table">
																<thead>
																	<tr>
																		<th>Ngày đi học</th>
																		<th
																			style={{
																				textAlign:
																					'right',
																			}}>
																			Giờ học
                                            </th>
																	</tr>
																</thead>
																<tbody>
																	{this.fetchAttendRows()}
																</tbody>
															</table>
														</div>
													</div>
													<div className="row">
														<div className="table-contactbook">
															<table className="table">
																<thead>
																	<tr>
																		<th>Nhận xét</th>
																		<th
																			style={{
																				textAlign:
																					'right',
																			}}>
                                            </th>
																	</tr>
																</thead>
																<tbody>
																	{this.fetchReviewRows()}
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						) : (
								<>
								</>
							)}
					</div>
				</div>
			</>
		);
	}
}
function mapStateToProps(state, ownProps) {
	return {
		contactBook: state.contactbook.contactBook,
		code: state.contactbook.code,
	};
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({ getContactBook }, dispatch);
}
let ContactBookContainer = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ContactBook),
);
export default ContactBookContainer;