import * as ActionTypes from './type';
import axios from 'axios';
import {notification} from 'antd';
import {initAPI, redirectToLogin} from '../../config/api';

export function listMember(data) {
	initAPI();

	return async dispatch => {
		await axios
			.post(`/classroom/list-member`, data)
			.then(res => {
				dispatch({type: 'PAGING', page: data.page});
				const members = res.data.data.records;
				const total = res.data.data.totalRecord;
				const limit = res.data.data.perPage;				
				dispatch({ type: ActionTypes.LIST_MEMBER, members, total, limit});
			})
			.catch(async err => {
				let msg = err.response.data.message;
				if (err && err.response && err.response.status === 401) {
					msg = err.response.data.message;
					await redirectToLogin(err.response.status);
				}
				notification.error({
					message: msg,
					placement: 'topRight',
					top: 50,
					duration: 3,
				});
			});
	};
}
