import React, { Component } from 'react';
import Moment from 'moment';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getContactBook } from '../../redux/contactbook/action';

class Row extends Component {
    constructor(props) {
        super();
        this.state = {
            check: false,
        };
    }
    render() {
        return (
            <tr>
                <td>
                    {this.props.obj.exam.code} - {this.props.obj.exam.name}
                </td>
                <td style={{ textAlign: 'center' }}>{this.props.obj.point}</td>
                <td style={{ textAlign: 'right' }}>
                    {Moment(this.props.obj.created_at).format('DD/MM/YYYY')}{' '}
                </td>
            </tr>
        );
    }
}

class RowAttend extends Component {
    constructor(props) {
        super();
        this.state = {
            check: false,
        };
    }
    render() {
        return (
            <tr>
                <td>
                    {Moment(this.props.obj.attended_date).format('DD/MM/YYYY')}{' '}
                </td>
                <td style={{ textAlign: 'right' }}>
                    {Moment(this.props.obj.attended_date).format('HH:ss')}{' '}
                </td>
            </tr>
        );
    }
};

class RowReview extends Component {
    constructor(props) {
        super();
        this.state = {
            check: false,
        };
    }
    render() {
        return (
            <tr>
                <td>
                    {this.props.obj.teacher}
                </td>
                <td style={{ textAlign: 'left' }}>
                    {this.props.obj.comment}
                </td>
            </tr>
        );
    }
};

class ContactBook extends Component {
    constructor(props) {
        super();
        this.state = {
            user: null,
            month: '',
            year: new Date().getFullYear(),
            classroom_id: '',
            total: 0,
            phone: null,
        };
    }
    handleChange = e => {
        var name = e.target.name;
        var value = e.target.value;
        this.setState({
            [name]: value,
        });
    };
    handleClick = async e => {
        e.preventDefault();
        if (this.state.phone !== null) {
            await this.props.getContactBook({ phone: this.state.phone });
        }
    }

    handleChangeSelect = async e => {
        var name = e.target.name;
        var value = e.target.value;
        await this.setState({
            [name]: value,
        });
        const data = {
            phone: this.state.phone,
            month: parseInt(this.state.month),
            year: parseInt(this.state.year),
            classroom_id: this.state.classroom_id,
        };
        await this.props.getContactBook(data);
    };
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.contactBook) {
            const {
                user,
                bills,
                chuyencan,
                classrooms,
                testings,
                avg_point,
                rank,
                attendList,
                reviews
            } = nextProps.contactBook;
            var total = 0;
            bills.forEach(bill => {
                total += parseFloat(bill.total);
            });
            this.setState({
                user: user,
                bills,
                chuyencan,
                classrooms,
                testings,
                avg_point,
                rank,
                total,
                attendList,
                reviews
            });
        }
    }
    async componentDidMount() {
        if (this.props.contactBook != null) {
            const {
                user,
                bills,
                chuyencan,
                classrooms,
                testings,
                avg_point,
                rank,
                attendList,
                reviews
            } = this.props.contactBook;
            var total = 0;
            bills.forEach(bill => {
                total += parseFloat(bill.total);
            });
            this.setState({
                user: user,
                bills,
                chuyencan,
                classrooms,
                testings,
                avg_point,
                rank,
                total,
                attendList,
                reviews
            });
        }
    }
    stringClass = data => {
        if (data instanceof Array) {
            const arrCopy = Array.from(data, ele => ele.name);
            return arrCopy.join(', ');
        }
        return null;
    };
    fetchRowsClass() {
        if (this.state.classrooms instanceof Array) {
            return this.state.classrooms.map((obj, i) => {
                return (
                    <option value={obj._id} key={obj._id.toString()}>
                        {obj.name}
                    </option>
                );
            });
        }
    }
    fetchRows() {
        if (
            this.state.testings instanceof Array &&
            this.state.testings.length > 0
        ) {
            return this.state.testings.map((object, i) => {
                return (
                    <Row
                        obj={object}
                        key={object._id}
                        index={i}
                        {...this.props}
                    />
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan={3}>Chưa có bài kiểm tra!</td>
                </tr>
            );
        }
    }

    fetchAttendRows() {
        if (
            this.state.attendList instanceof Array &&
            this.state.attendList.length > 0
        ) {
            return this.state.attendList.map((object, i) => {
                return (
                    <RowAttend
                        obj={object}
                        key={object._id}
                        index={i}
                        {...this.props}
                    />
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan={3}>Chưa có dữ liệu!</td>
                </tr>
            );
        }
    }

    fetchReviewRows() {
        if (
            this.state.reviews instanceof Array &&
            this.state.reviews.length > 0
        ) {
            return this.state.reviews.map((object, i) => {
                return (
                    <RowReview
                        obj={object}
                        key={object._id}
                        index={i}
                        {...this.props}
                    />
                );
            });
        } else {
            return (
                <tr>
                    <td colSpan={3}>Chưa có dữ liệu!</td>
                </tr>
            );
        }
    }

    replaceAvatar = () => {
        if (this.state.user != null) {
            const name = this.state.user.fullname;
            let arr = name.split(' ');
            let length = parseFloat(arr.length) - 1;
            let firstLetter = arr[0][0];
            let secondLetter = arr[length][0];
            return firstLetter + secondLetter;
        }
    };
    randomColor = () => {
        const color =
            '#' +
            '0123456789abcdef'
                .split('')
                .map(function (v, i, a) {
                    return i > 5 ? null : a[Math.floor(Math.random() * 16)];
                })
                .join('');
        return color;
    };
    render() {
        return (
            <>
                <div className="page-test-online">
                    <div className="container">
                        <div className="logo text-center">
                            <a href="/">
                                <img width={90} src="https://cdn.luyenthitiendat.vn/assets/DAICOVIET-LOGO.png" alt="" />
                            </a>
                        </div>
                        <h1 className="text-center">KIỂM TRA & ĐÁNH GIÁ NĂNG LỰC</h1>
                        <div className="sll-search">
                            <div className="input-group mb-2">
                                <input className="form-control" placeholder="Họ và tên"
                                    name="phone"
                                    value={this.state.phone}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="sll-search">
                            <div className="input-group mb-2">
                                <input className="form-control" placeholder="Số điện thoại"
                                    name="phone"
                                    value={this.state.phone}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="sll-search">
                            <div className="input-group mb-2">
                                <input className="form-control" placeholder="Email"
                                    name="phone"
                                    value={this.state.phone}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="sll-search">
                            <div className="input-group mb-2">
                                <input className="form-control" placeholder="Địa chỉ"
                                    name="phone"
                                    value={this.state.phone}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="sll-search">
                            <div className="input-group mb-2">
                                <input className="form-control" placeholder="Trường theo học"
                                    name="phone"
                                    value={this.state.phone}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="sll-search">
                            <div className="input-group mb-2">
                                <input className="form-control" placeholder="Lớp đang học"
                                    name="phone"
                                    value={this.state.phone}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="sll-search">
                            <div className="input-group mb-2">
                                <input className="form-control" placeholder="Mục tiêu điểm số"
                                    name="phone"
                                    value={this.state.phone}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="sll-search">
                            <div className="input-group mb-2">
                                <select className="form-control">
                                    <option value="">Chọn môn</option>
                                    <option value="">Toán</option>
                                    <option value="">Lý</option>
                                    <option value="">Văn</option>
                                    <option value="">Anh</option>
                               </select>
                            </div>
                        </div>
                        <div className="sll-search text-center">
                        <button className="btn btn-primary" type="submit">Làm bài kiểm tra</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
function mapStateToProps(state, ownProps) {
    return {
        contactBook: state.contactbook.contactBook,
        code: state.contactbook.code,
    };
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getContactBook }, dispatch);
}
let ContactBookContainer = withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ContactBook),
);
export default ContactBookContainer;