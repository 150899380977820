import React, { Component } from "react";
import { Route, withRouter, Switch } from "react-router-dom";
import Exam from "./Exam";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Videos from "./Videos";
import TestDetail from "./TestDetail";
import MenuClasses from "./MenuClasses";
import StatusClasses from "./StatusClasses";
import Member from "./Member";

import {
	showClassroom,
	classOverview,
	subjectShow,
} from "../redux/classroom/action";
import { listMember } from "../redux/member/action";
import ClassOverview from "./ClassOverview";
import ClassTesting from "./ClassTesting";
import SearchVideo from "./SearchVideo";
import Document from "./Document";
import ExamViewAgain from "./ExamViewAgain";

class MasterClasses extends Component {
	constructor(props) {
		super(props);
		const date = new Date();
		let month = date.getMonth() + 1;
		let year = date.getFullYear();
		this.state = {
			keyword: "",
			month: month,
			year: year,
			classroom_id: "",
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		this.setState({
			classroom_id: nextProps.match.params.id,
		});
	}

	componentDidMount = async () => {
		await this.setState({
			classroom_id: this.props.match.params.id,
		});

		await this.props.showClassroom({ id: this.props.match.params.id });
		if (
			typeof this.props.classroom === "object" &&
			this.props.classroom.subject !== "undefined"
		) {
			if (this.props.classroom.subject) {
				await this.props.subjectShow({
					id: this.props.classroom.subject.id,
				});
			}

		}
	};
	render() {
		return (
			<>
				<MenuClasses {...this.props} />
				<div className="main-body">
					<Route
						exact
						path={`${this.props.match.url}/`}
						render={() => (
							<ClassOverview class_id={this.props.match.params.id} />
						)}
					/>
					<Route
						path={`${this.props.match.url}/overview`}
						render={() => (
							<ClassOverview
								{...this.props}
								class_id={this.state.classroom_id}
							/>
						)}
					/>
					<Route
						path={`${this.props.match.url}/members`}
						render={() => (
							<Member
								{...this.props}
								class_id={this.props.match.params.id}
							/>
						)}
					/>

					<Route
						path={`${this.props.match.url}/search-video`}
						render={() => (
							<SearchVideo
								{...this.props}
								class_id={this.props.match.params.id}
							/>
						)}
					/>

					<Route
						path={`${this.props.match.url}/video-preview`}
						render={() => (
							<Videos
								{...this.props}
								class_id={this.props.match.params.id}
							/>
						)}
					/>

					<Route
						exact
						path={`${this.props.match.url}/testing`}
						render={() => <Exam {...this.props} />}
					/>
					<Route
						exact
						path={`${this.props.match.url}/testings`}
						render={() => (
							<ClassTesting
								{...this.props}
								class_id={this.props.match.params.id}
							/>
						)}
					/>
					<Route
						exact
						path={`${this.props.match.url}/testings/:id/:class/view-again`}
						render={() => (
							<ExamViewAgain
								{...this.props}
								class_id={this.props.match.params.id}
							/>
						)}
					/>
					<Route
						exact
						path={`${this.props.match.url}/testings/:id`}
						render={() => <TestDetail {...this.props} />}
					/>

					<Switch>
						<Route
							exact
							path={`${this.props.match.url}/`}
							component={StatusClasses}
						/>
						<Route
							exact
							path={`${this.props.match.url}/overview`}
							component={StatusClasses}
						/>
						<Route
							exact
							path={`${this.props.match.url}/member`}
							render={() => <StatusClasses />}
						/>
						<Route
							exact
							path={`${this.props.match.url}/documents`}
							render={() => (
								<Document class_id={this.props.match.params.id} />
							)}
						/>
					</Switch>
				</div>
			</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		classroom: state.classroom.classroom,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{ showClassroom, classOverview, listMember, subjectShow },
		dispatch
	);
}

let MasterClassesContainer = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(MasterClasses)
);

export default MasterClassesContainer;
