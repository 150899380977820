import * as ActionTypes from './type';
import axios from 'axios';
import {notification} from 'antd';
import {initAPI} from '../../config/api';

export function createRegistation(data) {
	initAPI();

	return async dispatch => {
		await axios
			.post(`/registration/create`, data)
			.then(res => {
				if (res.data.code === 200) {
					notification.success({
						message: res.data.message,
						placement: 'topRight',
						top: 50,
						duration: 3,
					});
					dispatch({type: ActionTypes.CREATE_REGISTRATION});
				} else {
					notification.error({
						message: res.data.message,
						placement: 'topRight',
						top: 50,
						duration: 3,
					});
				}
			})
			.catch(err => {
				notification.error({
					message: 'Đăng ký không thành công',
					placement: 'topRight',
					top: 50,
					duration: 3,
				});
			});
	};
}
