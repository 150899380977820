import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import Moment from 'moment';
import {listTesting} from '../redux/testing/action';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

class Row extends Component {
	constructor(props) {
		super();
		this.state = {
			check: false,
		};
	}

	render() {
		return (
			<tr>
				<td>{this.props.index + 1}</td>
				<td>{this.props.obj.exam.code}</td>
				<td>
					{this.props.obj.status !== 'REVIEW'
						? Math.round(this.props.obj.point * 10) / 10
						: null}
				</td>
				<td>{this.props.obj.status}</td>
				<td>
					{this.props.obj.updated_at &&
						Moment(this.props.obj.updated_at).format(
							'DD/MM/YYYY h:mm:ss',
						)}
				</td>
				<td>
					<Link
						to={
							`${this.props.match.path}/` +
							this.props.obj._id +
							'/view'
						}
						class="preview">
						Xem bài
					</Link>
				</td>
			</tr>
		);
	}
}

class Test extends Component {
	constructor(props) {
		super();
		this.state = {
			data: [],
			limit: '',
		};
	}

	getData = (pageNumber = 1) => {
		const data = {
			page: pageNumber,
			limit: this.state.limit,
		};
		if (this.state.keyword != null) {
			data['keyword'] = this.state.keyword;
		}
		return data;
	};

	async componentDidMount() {
		await this.props.listTesting(this.getData());
		if (this.props.limit) {
			await this.setState({
				limit: this.props.limit,
			});
		}
	}

	fetchRows() {
		if (this.props.testings instanceof Array) {
			return this.props.testings.map((object, i) => {
				return (
					<Row
						obj={object}
						key={object._id}
						index={i}
						{...this.props}
					/>
				);
			});
		}
	}

	render() {
		return (
			<div id="table-test">
				<div class="title-test">
					<span>Danh sách bài thi</span>
				</div>
				<div class="mytable">
					<table id="list-test">
						<tr>
							<th>STT</th>
							<th>Mã bài thi</th>
							<th>Điểm</th>
							<th>Trạng thái</th>
							<th>Ngày gửi bài</th>
							<th>Hành động</th>
						</tr>

						{this.fetchRows()}
					</table>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		testings: state.testing.testings,
		limit: state.testing.limit,
		page: state.testing.page,
		total: state.testing.total,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({listTesting}, dispatch);
}

withRouter(connect(mapStateToProps, mapDispatchToProps)(Row));
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Test));
