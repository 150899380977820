import * as ActionTypes from "./type";
import { isUndefined } from "util";
const initialState = {
	data: {},
	initAnswers: [],
	answers: [],
	dataAnswer: [],
	examData: [],
	testingExamData: [],
	testings: [],
	questions: [],
	testing: null,
	exams: null,
	total: 0,
	page: 1,
	limit: 20,
	ids: [],
	checkAll: false,
	qtyTrue: 0,
	totalAnswer: 0,
	countPending: 0,
	result: null,
	answerData: null,
	examPreview: null,
	errMessage: null,
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.LIST_TESTING:
			return {
				...state,
				testings: action.testings,
				total: action.total,
				testingExamData: action.testingExamData,
				limit: action.limit,
				ids: [],
				questions: [],
				totalAnswer: 0,
			};
		case ActionTypes.LIST_EXAM:
			return {
				...state,
				exams: action.examsClass,
				examData: action.examData
			};
		case ActionTypes.SHOW_TESTING:
			return {
				...state,
				data: action.data,
				totalAnswer: 0,
			};
		case ActionTypes.DETAIL_TESTING:
			const _answers = Object.assign([], action.testing.testing.answers);
			const questions = Object.assign([], action.testing.questions);
			const total = questions.length;

			if (_answers.length === 0) {
				questions.map((ele) => {
					ele.value = null;
					return ele;
				});
			} else {
				_answers.forEach((ans) => {
					questions.forEach((ele) => {
						if (ans.question_id === ele._id) {
							ans = Object.assign(ans, ele);
						}
					});
				});
			}

			const iterator = _answers.values();
			var qtyTrue = 0;
			for (const ele of iterator) {
				if (ele.value === ele.answer) {
					qtyTrue++;
				}
			}
			return {
				...state,
				testing: action.testing,
				questions: Object.assign(
					[],
					_answers.length === 0 ? questions : _answers
				),
				qtyTrue,
				total: total,
			};

		case ActionTypes.RESET:
			return {
				...state,
				answers: [],
				initAnswers: [],
				totalAnswer: 0,
				result: null,
			};
		case ActionTypes.CREATE_TESTING:
			return {
				...state,
				result: action.result,
			};
		case ActionTypes.INIT_ANSWER:
			const initAnswers = state.initAnswers;
			return {
				...state,
				initAnswers: Object.assign(
					initAnswers,
					initAnswers.push(action.answer)
				),
			};
		case ActionTypes.CLICK_ANSWER:
			const answers = state.answers;
			const initAnswersCopy = state.initAnswers;
			var totalAnswer = state.totalAnswer;

			const check = answers.find(
				(ele) => ele.question_id === action.answer.question_id
			);

			var newAnswers = null;

			if (isUndefined(check)) {
				newAnswers = Object.assign(
					answers,
					answers.push(action.answer)
				);
				totalAnswer += 1;
			} else {
				answers.forEach((ele) => {
					if (ele.question_id === action.answer.question_id) {
						ele.value = action.answer.value;
					}
				});

				newAnswers = Object.assign([], answers);
			}

			initAnswersCopy.forEach((ele) => {
				newAnswers.forEach((item) => {
					if (ele.question_id === item.question_id) {
						ele.value = item.value;
					}
				});
			});

			return {
				...state,
				answers: newAnswers,
				totalAnswer: totalAnswer,
				dataAnswer: initAnswersCopy,
			};
		case ActionTypes.COUNT_PENDING:
			return {
				...state,
				countPending: action.countPending,
			};
		case ActionTypes.VIEW_ANSWER:
			return {
				...state,
				answerData: action.answerData,
			};
		case ActionTypes.RESET_VIEWDATA:
			return {
				...state,
				answerData: null,
			};
		case ActionTypes.EXAM_PREVIEW:
			return {
				...state,
				examPreview: action.examPreview,
			};
		case ActionTypes.ERROR:
			return {
				...state,
				errMessage: action.errMessage,
			};
		case ActionTypes.ERROR_RESET:
			return {
				...state,
				errMessage: null,
			};
		default:
			return state;
	}
};

export default reducer;
