import * as ActionTypes from "./type";
import axios from "axios";
import { initAPI, responseError, notify } from "../../config/api";

export function listDocument(data) {
	initAPI();
	return async dispatch => {
		await axios
			.post(`/document/list`, data)
			.then(res => {
				notify(res, false);
				if (res.data.code === 200) {
					dispatch({ type: "PAGING", page: data.page });
					const documents = res.data.data.records;
					const total = res.data.data.totalRecord;
					const limit = res.data.data.perPage;
					dispatch({
						type: ActionTypes.LIST_DOCUMENT,
						documents,
						total,
						limit
					});
				}
			})
			.catch(async err => {
				responseError(err);
			});
	};
}

export function showDocument(id) {
	initAPI();
	return async dispatch => {
		const data = {
			id: id			
		};
		await axios
			.post(`document/detail`, data)
			.then(res => {
				notify(res, false);
				if (res.data.code === 200) {
					const document = res.data.data;
					dispatch({ type: ActionTypes.SHOW_DOCUMENT, document });
				}
			})
			.catch(async err => {
				responseError(err);
			});
	};
}
