import * as ActionTypes from './type';

const initState = {
	data: null,
	redirect: false,
	image: null,
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case 'UPLOAD_IMAGE':
			return {
				...state,
				image: action.data[0],
			};
		case ActionTypes.UPDATE_SETTING:
			return {
				...state,
				redirect: true,
			};
		case ActionTypes.SETTING_WEBSITE:
			return {
				...state,
				data: action.data,
			};

		default:
			return state;
	}
};
export default reducer;
