import * as ActionTypes from './type';
const initialState = {
    messages: [],
    message: null,
    total: 0,
    page: 1,
    limit: 20,
    ids: [],
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.LIST_MESSAGE:
            return {
                ...state,
                messages: action.messages,
                total: action.total,
                limit: action.limit,
                ids: [],
            };
        case ActionTypes.SHOW_MESSAGE:
            return {
                ...state,
                message: action.message
            }
        default:
            return state;
    }
};

export default reducer;
