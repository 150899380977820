import axios from "axios";
import { initAPI, redirectToLogin } from "../../config/api";
import * as ActionTypes from "./type";
import { notification } from "antd";

export function getContactBook(data) {
	initAPI();

	return async dispatch => {
		await axios
			.post(`/user/solienlac`, data)
			.then(res => {
				let contactBook = res.data.data;
				let code = res.data.code;
				dispatch({ type: ActionTypes.CONTACT_BOOK, contactBook, code });
			})
			.catch(async err => {
				let msg = err.response.data.message;
				if (err && err.response && err.response.status === 401) {
					msg = err.response.data.message;
					await redirectToLogin(err.response.status);
				}
				notification.error({
					message: msg,
					placement: "topRight",
					top: 50,
					duration: 3
				});
			});
	};
}
