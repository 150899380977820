import authReducer from './auth/reducer';
import profileReducer from './profile/reducer';
import {combineReducers} from 'redux';
import testingReducer from './testing/reducer';
import classroomReducer from './classroom/reducer';
import memberReducer from './member/reducer';
import videoReducer from './video/reducer';
import contactBookReducer from './contactbook/reducer';
import documentReducer from './document/reducer';
import messageReducer from './message/reducer';
import settingReducer from './setting/reducer';

const allReducers = combineReducers({
	auth: authReducer,
	testing: testingReducer,
	profile: profileReducer,
	classroom: classroomReducer,
	member: memberReducer,
	video: videoReducer,
	contactbook: contactBookReducer,
	document: documentReducer,
	setting: settingReducer,
	message: messageReducer
});

export default allReducers;
