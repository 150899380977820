import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {notification} from 'antd';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {getVideo, resetVideo} from '../redux/video/action';

class SearchVideo extends Component {
	constructor(props) {
		super();
		this.state = {
			code: '',
		};
	}

	onChange = e => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value,
		});
	};
	componentDidMount() {
		this.setState({
			redirect: this.props.redirect,
		});
	}

	handleSubmit = async () => {
		if (this.state.code === '') {
			notification.warning({
				message: 'Mã là bắt buộc',
				placement: 'topRight',
				top: 50,
				duration: 3,
			});
		} else {
			await this.props.getVideo({
				code: this.state.code,
				classroom_id: this.props.class_id,
			});
			if (this.props.redirect === true) {
				await this.setState({code: ''});
				await this.props.history.push(
					'/app/classrooms/' + this.props.class_id + '/video-preview',
				);
			}
		}
	};

	closeModal = async () => {
		await this.props.history.push(
			this.props.history.pathname + '/video-preview',
		);
	};

	componentDidUpdate(prevProps) {
		if (this.props.redirect !== prevProps.redirect) {
			this.setState({
				redirect: this.props.redirect,
			});
		}
	}

	render() {
		return (
			<div id="profile" className="content page-container">
				<div className="page-title">
					<h2>Xem video bài giảng</h2>
				</div>
				<div
					className="main-profile-fix"
					style={{background: `transparent`}}>
					<div className="search-video">
						<input
							type="text"
							placeholder="Nhập mã"
							name="code"
							onChange={this.onChange}
							defaultValue={this.state.code}
							className="form-control"
							autoFocus
							style={{maxWidth: 300, marginRight: 10}}
						/>

						<button
							type="button"
							className="btn btn-primary"
							onClick={this.handleSubmit}>
							Xem video
						</button>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		redirect: state.video.redirect,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({getVideo, resetVideo}, dispatch);
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(SearchVideo),
);
