import * as ActionTypes from "./type";
const initialState = {
	video: null,
	ids: [],
	redirect: false,
	code: null
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.GET_VIDEO:
			return {
				...state,
				video: action.video,
				redirect: action.redirect,
				code: action.code
			};

		case ActionTypes.ERROR:
			return {
				...state,
				video: null,
				redirect: false
			};

		case "RESET":
			return {
				...state,
				video: null,
				ids: [],
				redirect: false
			};
		default:
			return state;
	}
};

export default reducer;
