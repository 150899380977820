import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { message } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
	showTesting,
	resetState,
	clickAnswer,
	createTesting,
	initAnswer,
	viewAnswer,
	resetViewAnswer,
} from "../redux/testing/action";
import { isObject, isUndefined } from "util";

import $ from "jquery";
import Vimeo from '@u-wave/react-vimeo';
const base64 = require("base-64");

class Row extends Component {
	constructor(props) {
		super(props);
		this.state = {
			style: {
				background: "green",
			},
			answer: null,
			isOpen: false,
			vimeoID: null
		};
	}

	async componentDidMount() {
		const data = {
			question_id: this.props.obj._id,
			value: this.state.answer,
		};

		await this.props.initAnswer(data);
		if (this.props.totalAnswer === 0) {
			$("input[type=radio]").attr("checked", false);
		}
	}

	handleClick = async (answer) => {
		await this.setState({
			answer,
		});
		const data = {
			question_id: this.props.obj._id,
			value: answer,
		};
		await this.props.clickAnswer(data);
	};

	renderButton = () => {
		const data = ["A", "B", "C", "D"];

		return data.map((item, i) => {
			return (
				<div className="answer" key={i}>
					<label className="answer-checkmark">
						<input
							type="radio"
							name={`question-${this.props.obj._id}`}
							onClick={(e) => this.handleClick(item)}
						/>
						<span className="checkmark"></span>
					</label>
					<b>{item}</b>
				</div>
			);
		});
	};

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.answerData !== null) {
			let linkVimeo = nextProps.answerData.video_link;
			let vimeo_id = null;
			if (linkVimeo && linkVimeo.indexOf('vimeo') >= 0) {
				let data = linkVimeo.split("/");
				vimeo_id = data[data.length - 1];
				this.setState({
					vimeoID: vimeo_id
				})
			}
			if (nextProps.answerData._id !== this.props.obj._id) {
				this.setState({
					isOpen: false,
				});
			}
		}
	}

	handlePlyrScript = async () => {
		await this.removeScript();
		await this.addScript();
	};

	addScript = () => {
		const plyrscript = document.createElement("script");
		plyrscript.innerText =
			"var player = new Plyr('#player', {youtube: { noCookie: true, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 0 }});";
		document.body.appendChild(plyrscript);
	};

	removeScript = () => {
		var nodeList = document.getElementsByTagName("script");
		for (var i = 0; i < nodeList.length; i++) {
			if (
				nodeList[i].innerText ===
				"var player = new Plyr('#player', {youtube: { noCookie: true, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 0 }});"
			) {
				nodeList[i].remove();
			}
		}
	};

	viewAnswer = async () => {
		await this.setState({
			isOpen: !this.state.isOpen,
		});

		if (this.state.isOpen === true) {
			await this.props.viewAnswer({ id: this.props.obj._id });
			this.handlePlyrScript();
		} else {
			await this.removeScript();
		}
	};

	render() {
		return (
			<div
				className="box-testexercise"
				id={`question` + (this.props.index + 1)}
			>
				<div className="box-question">
					<div className="question">
						<span>Câu {this.props.index + 1}:</span>
						<span>{this.props.obj.name}</span>
					</div>

					<div className="embed-question">
						<div
							dangerouslySetInnerHTML={{
								__html: this.props.obj.question,
							}}
							className="html-text"
						></div>
					</div>
				</div>
				<div className="box-answer">{this.renderButton()}</div>

				{this.state.isOpen === true &&
				this.props.answerData !== null ? (
					<div>
						<h4>Lời giải:</h4>
						{this.props.answerData.answer_content !== null ? (
							<div className="box-question">
								<div className="question">
									<span style={{ color: "white" }}>
										{this.props.index + 1}
									</span>
									<span>{this.props.obj.name}</span>
								</div>
								<div className="embed-question">
									<div
										dangerouslySetInnerHTML={{
											__html: this.props.answerData
												.answer_content,
										}}
										className="html-text"
									></div>
								</div>
							</div>
						) : (
							<div className="text-danger">
								Chưa cập nhật lời giải
							</div>
						)}
						<div className="embed-question">
							{
								this.state.isOpen === true &&
								this.props.answerData !== null &&
								this.state.vimeoID && this.state.vimeoID !== null ? (
									<Vimeo
										vimeo-id={this.state.vimeoID}
										video={this.state.vimeoID}
										autoplay={true}
										speed={true}
										responsive={true}
										showTitle={false}
										showByline={false}
										showPortrait={false}
										className="block-vimeo-id"
									/>
								) : <div
										id="player"
										data-plyr-provider="youtube"
										data-plyr-embed-id={base64.decode(
										this.props.answerData.v_id
									)}
								/>
							}
						</div>
					</div>
				) : null}

				<div className="text-center" style={{ padding: 20 }}>
					<button
						onClick={this.viewAnswer}
						className={
							this.state.isOpen === true
								? "btn button-close"
								: "btn btn-primary btn-send-mail"
						}
						style={{ borderRadius: 20 }}
					>
						{this.state.isOpen === true ? "Đóng" : "Xem lời giải"}
					</button>
				</div>
			</div>
		);
	}
}

class Exam extends Component {
	constructor(props) {
		super();
		this.state = {
			ModalText: "Bạn có chắc muốn gửi bài không ?",
			visible: false,
			confirmLoading: false,
			totalAnswer: 0,
			ModalTextNotify: "Bạn đã sẵn sàng làm bài kiểm tra chưa ?",
			visibleNotify: false,
			confirmLoadingNotify: false,
			stop: false,
			loading: false,
			visibleResult: false,
			files: [],
			timeStart: null,
			exam: null,
			questions: [],
			time: 0,
		};
	}

	fetchRows() {
		if (this.props.data.questions instanceof Array) {
			return this.props.data.questions.map((object, i) => {
				return (
					<Row
						obj={object}
						key={object._id}
						index={i}
						clickAnswer={this.props.clickAnswer}
						initAnswer={this.props.initAnswer}
						viewAnswer={this.props.viewAnswer}
						resetViewAnswer={this.props.resetViewAnswer}
						answerData={this.props.answerData}
					/>
				);
			});
		}
	}

	async componentDidMount() {
		await this.props.showTesting({
			id: this.props.match.params.id,
			classroom_id: this.props.match.params.class,
		}); //test

		if (this.props.data) {
			const {
				questions,
				exam,
				time_req,
				exam_classroom,
			} = this.props.data;

			var timeRemain = 0;
			if (exam_classroom.is_fixed_time === true) {
				if (
					exam_classroom.started_at !== null &&
					time_req !== null &&
					exam.time !== null
				) {
					var start_at = new Date(exam_classroom.started_at);
					var currentTime = new Date(time_req);
					var time = exam.time;

					var diffDate = Math.floor((currentTime - start_at) / 60000);

					if (time >= diffDate) {
						timeRemain = time - diffDate;
					} else {
						timeRemain = 0;
					}
				}
			} else {
				timeRemain = exam.time;
			}

			await this.setState({
				questions,
				exam,
				time: timeRemain,
				isTime: exam.time,
			});
		}
	}

	async componentWillUnmount() {
		await this.props.resetState();
	}

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	handleOk = async () => {
		var data;
		if (this.props.data.exam.type === "TU_LUAN") {
			data = new FormData();
			data.append("exam_id", this.props.match.params.id);
			data.append("classroom_id", this.props.match.params.class);
			data.append("testing_type", this.props.data.exam.type);
			data.append("answers", this.props.dataAnswer);

			for (var i = 0; i < this.state.files.length; i++) {
				data.append("files[" + i + "]", this.state.files[i]);
			}
		} else {
			data = {
				exam_id: this.props.match.params.id,
				answers: this.props.dataAnswer,
				classroom_id: this.props.match.params.class,
			};
		}

		await this.setState({
			visible: false,
			confirmLoading: false,
			stop: true,
			loading: false,
		});
		message.loading("Đang chấm điểm bài thi", 1).then(async () => {
			await this.props.createTesting(data);
			await this.setState({
				visibleResult: true,
				files: [],
			});
		});
	};

	handleCancel = () => {
		this.setState({
			visible: false,
		});
	};

	showModalNotify = () => {
		this.setState({
			visibleNotify: true,
		});
	};

	handleOkNotify = async () => {
		if (this.props.errMessage !== null) {
			await this.props.history.push(
				"/app/classrooms/" + this.props.match.params.class + "/testings"
			);
		} else {
			await this.setState({
				visibleNotify: false,
				confirmLoadingNotify: false,
				timeStart: Date.now(),
			});
		}
	};

	handleCancelNotify = () => {
		this.props.history.push("/app");
	};

	handleCancelResult = () => {
		this.setState({
			visibleResult: false,
		});
	};

	onDrop = (files) => {
		this.setState({ files });
	};

	fetchAnchorLink() {
		if (this.props.data.questions instanceof Array) {
			return this.props.data.questions.map((item, i) => {
				var style = {};
				if (this.props.answers.length !== 0) {
					const check = this.props.answers.find(
						({ question_id }) => question_id === item._id
					);
					if (isUndefined(check)) {
						style = {};
					} else {
						style = {
							background: "#1e88e5",
							color: "white",
						};
					}
				}
				return (
					<li key={i}>
						<a href={"#question" + (i + 1)}>
							<button style={style}>{i + 1}</button>
						</a>
					</li>
				);
			});
		}
	}

	fetchListFiles() {
		if (this.state.files instanceof Array) {
			return this.state.files.map((file) => (
				<li key={file.path}>{file.path}</li>
			));
		}
	}

	percent = (result) => {
		const { num_right, num_wrong } = result;

		const percent =
			(parseFloat(num_right) /
				(parseFloat(num_right) + parseFloat(num_wrong))) *
			100;
		return Math.round(percent * 100) / 100;
	};

	renderInfo = (result) => {
		const { point } = result;
		const pointTest = Math.round(parseFloat(point) * 10) / 10;
		if (pointTest >= 9 && pointTest <= 10) {
			return '"Bạn làm bài rất suất sắc, tiếp tục duy trì kết quả này nha!"';
		} else if (pointTest >= 7 && pointTest < 9) {
			return '"Bạn làm bài cũng ổn đấy, hãy cố gắng chinh phục mức điểm cao hơn nữa!"';
		} else if (pointTest >= 5 && pointTest < 7) {
			return '"Bạn làm bài kém quá, chắc chưa ôn kĩ bài rồi phải không? Lần sau phải cố gắng đó nha!"';
		} else if (pointTest >= 0 && pointTest < 5) {
			return '"Bạn lười học quá đấy, cần phải tích cực học và làm bài nhiều hơn nữa!"';
		}
	};

	readURL = async (e) => {
		var input = e.target;

		if (input.files && input.files[0]) {
			var data = this.state.files;
			data.push(input.files[0]);
			await this.setState({
				files: data,
			});
			var reader = new FileReader();

			reader.onload = function (e) {
				var img = `<img src="${e.target.result}"/>`;

				$(".list-image").prepend(img);
			};

			reader.readAsDataURL(input.files[0]);
		}
	};

	render() {
		return (
			<>
				{this.state.exam === null ? null : (
					<>
						<div className="col-center">
							<div id="testexercise">
								<>
									<div className="box-question-test">
										<div id="overview">
											<div className="top-overview">
												<div className="t-overview-left w-100 m-0">
													<h2>
														Bài thi:{" "}
														<span
															style={{
																color:
																	"#FFDE32",
																fontSize:
																	"35px",
															}}
														>
															{isObject(
																this.props.data
																	.exam
															) &&
															this.props.data.exam
																.name != null
																? this.props
																		.data
																		.exam
																		.name
																: ""}
														</span>
													</h2>
													<div>
														<div className="col-6">
															<p>
																Mã đề:{" "}
																<span>
																	{isObject(
																		this
																			.props
																			.data
																			.exam
																	) &&
																	this.props
																		.data
																		.exam
																		.code !=
																		null
																		? this
																				.props
																				.data
																				.exam
																				.code
																		: ""}
																</span>
															</p>
														</div>
														<div className="col-6">
															<p>
																Thời gian làm
																bài:{" "}
																<span>
																	{isObject(
																		this
																			.props
																			.data
																			.exam
																	) &&
																	this.props
																		.data
																		.exam
																		.time !=
																		null
																		? Math.floor(
																				this
																					.props
																					.data
																					.exam
																					.time
																		  )
																		: "10 phút"}
																</span>
															</p>
														</div>
													</div>
													<div className="box-description">
														<p>
															Chúc bạn có những
															trải nghiệm thật
															tuyệt vời với Luyện
															Thi Tiến Đạt.
														</p>
													</div>
												</div>
											</div>
										</div>

										{this.props.data !== null &&
										this.props.data.exam.type ===
											"TU_LUAN" ? (
											<div
												style={{
													width: "100%",
													marginTop: 15,
													marginBottom: 10,
												}}
											>
												<embed
													src={
														this.props.data.exam
															.doc_link !==
														"undefinded"
															? this.props.data
																	.exam
																	.doc_link
															: null
													}
													type="application/pdf"
													width="100%"
													height="600px"
												/>
											</div>
										) : (
											this.fetchRows()
										)}
									</div>
								</>
							</div>
						</div>
						<div className="col-right">
							<div className="box-question-selection">
								<div className="top-q-select">
									<h2>
										{this.props.data.exam.type === "TU_LUAN"
											? null
											: "Chuyển nhanh đến câu hỏi"}
									</h2>
									{this.props.data.exam.type ===
									"TU_LUAN" ? null : (
										<ul>{this.fetchAnchorLink()}</ul>
									)}
								</div>
							</div>
						</div>
					</>
				)}
			</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		token: state.auth.token,
		user: state.auth.user,
		data: state.testing.data,
		initAnswers: state.testing.initAnswers,
		answers: state.testing.answers,
		answerData: state.testing.answerData,
		dataAnswer: state.testing.dataAnswer,
		totalAnswer: state.testing.totalAnswer,
		result: state.testing.result,
		errMessage: state.testing.errMessage,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			showTesting,
			resetState,
			clickAnswer,
			createTesting,
			initAnswer,
			viewAnswer,
			resetViewAnswer,
		},
		dispatch
	);
}

withRouter(connect(mapStateToProps, mapDispatchToProps)(Row));

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Exam));
