import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { listClassroom } from '../redux/classroom/action';

class Row extends Component {
	constructor(props) {
		super();
		this.state = {};
	}

	render() {
		return (
			<div className="class">
				<div className="box-yellow">
					{
						this.props.obj.image ? <span><NavLink
							to={`${this.props.match.path}/${this.props.obj._id}`}><img src={this.props.obj.image} alt="" /></NavLink></span> : null
					}
				</div>
				<div className="title">
					<NavLink
						to={`${this.props.match.path}/${this.props.obj._id}`}>
						{this.props.obj.name}
					</NavLink>
					<p>
						Mã lớp: <b>{this.props.obj.code}</b>
					</p>
					<p>
						Giáo viên: <b>{this.props.obj.teacher}</b>
					</p>
				</div>
			</div>
		);
	}
}

class ClassList extends Component {
	constructor(props) {
		super();
		this.state = {
			keyword: '',
		};
	}

	fetchRows() {
		if (this.props.classrooms instanceof Array) {
			return this.props.classrooms.map((object, i) => {
				return (
					<Row
						obj={object}
						key={object._id}
						index={i}
						{...this.props}
					/>
				);
			});
		}
	}

	onChange = e => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value,
		});
	};

	getData = (pageNumber = 1) => {
		const data = {
			page: pageNumber,
			limit: this.state.limit,
		};
		if (this.state.keyword != null) {
			data['keyword'] = this.state.keyword;
		}
		return data;
	};

	async componentDidMount() {
		await this.props.listClassroom(this.getData());
		if (this.props.limit) {
			await this.setState({
				limit: this.props.limit,
			});
		}
	}

	onSubmit = e => {
		e.preventDefault();
		this.props.listClassroom(this.getData());
	};

	render() {
		return (
			<>
				<div id="header-home">
					<div className="page-title">
						<h2>Lớp đã tham gia</h2>
					</div>
					<div className="page-tools">
						<div className="search-class">
							<form onSubmit={this.onSubmit}>
								<div className="form-group">
									<input
										type="text"
										placeholder="Tìm kiếm..."
										name="keyword"
										className="input-v2"
										onChange={this.onChange}
									/>
									<i
										className="fa fa-search"
										aria-hidden="true"></i>
								</div>
							</form>
						</div>
						<div className="filter-class">
							<select className="input-v2">
								<option value="">Tất cả</option>
								<option value="">A-Z</option>
								<option value="">Z-A</option>
								<option value="">Mới nhất</option>
								<option value="">Cũ nhất</option>
							</select>
						</div>
					</div>
				</div>
				<div id="class-list" className="col-center page-container">
					<div className="box-class-list">{this.fetchRows()}</div>
				</div>
			</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		classrooms: state.classroom.classrooms,
		limit: state.classroom.limit,
		page: state.classroom.page,
		total: state.classroom.total,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ listClassroom }, dispatch);
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ClassList),
);
