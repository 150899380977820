import * as ActionTypes from "./type";
import axios from "axios";
import { notification } from "antd";
import { initAPI, redirectToLogin } from "../../config/api";

export function getVideo(data) {
	initAPI();

	return async dispatch => {
		await axios
			.post(`question/get-video`, data)
			.then(res => {
				if (res.data.code === 200 && res.data.data != null) {
					notification.success({
						message: res.data.message,
						placement: "topRight",
						top: 50,
						duration: 3
					});
					const video = res.data.data;
					dispatch({
						type: ActionTypes.GET_VIDEO,
						video,
						redirect: true,
						code: data.code
					});
				} else {
					notification.warning({
						message: res.data.message,
						placement: "topRight",
						top: 50,
						duration: 3
					});
					dispatch({
						type: ActionTypes.GET_VIDEO,
						video: null,
						redirect: false
					});
				}
			})
			.catch(async err => {
				let msg = err.response.data.message;
				if (err && err.response && err.response.status === 401) {
					msg = err.response.data.message;
					await redirectToLogin(err.response.status);
				}
				notification.error({
					message: msg,
					placement: "topRight",
					top: 50,
					duration: 3
				});
			});
	};
}

export function resetVideo() {
	return dispatch => {
		dispatch({ type: "RESET" });
	};
}
