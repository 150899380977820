import * as ActionTypes from "./type";
import axios from "axios";
import { notification } from "antd";
import { initAPI, redirectToLogin } from "../../config/api";

export function listTesting(data) {
	return async (dispatch) => {
		await initAPI();
		await axios
			.post(`/testing/list`, data)
			.then((res) => {
				dispatch({ type: "PAGING", page: data.page });
				const testings = res.data.data.records;
				const testingExamData = res.data.data.examData;
				const total = res.data.data.totalRecord;
				const limit = res.data.data.perPage;
				dispatch({
					type: ActionTypes.LIST_TESTING,
					testings,
					testingExamData,
					total,
					limit,
				});
			})
			.catch(async (err) => {
				if (typeof err.response.data !== "undefined") {
					let msg = err.response.data.message;
					if (err && err.response && err.response.status === 401) {
						msg = err.response.data.message;
						await redirectToLogin(err.response.status);
					}
					notification.error({
						message: msg,
						placement: "topRight",
						top: 50,
						duration: 3,
					});
				}
			});
	};
}

export function listExam(data) {
	return async (dispatch) => {
		await initAPI();
		await axios
			.post(`/testing/list`, data)
			.then((res) => {
				const examsClass = res.data.data.records;
				const examData = res.data.data.examData;
				dispatch({ type: ActionTypes.LIST_EXAM, examsClass, examData });
			})
			.catch(async (err) => {
				if (typeof err.response.data !== "undefined") {
					let msg = err.response.data.message;
					if (err && err.response && err.response.status === 401) {
						msg = err.response.data.message;
						await redirectToLogin(err.response.status);
					}
					notification.error({
						message: msg,
						placement: "topRight",
						top: 50,
						duration: 3,
					});
				}
			});
	};
}

export function countPending(data) {
	return async (dispatch) => {
		await initAPI();
		await axios
			.post(`/exam/count-pending`, data)
			.then((res) => {
				const countPending = res.data.data;
				dispatch({ type: ActionTypes.COUNT_PENDING, countPending });
			})
			.catch(async (err) => {
				if (typeof err.response.data !== "undefined") {
					let msg = err.response.data.message;
					if (err && err.response && err.response.status === 401) {
						msg = err.response.data.message;
						await redirectToLogin(err.response.status);
					}
					notification.error({
						message: msg,
						placement: "topRight",
						top: 50,
						duration: 3,
					});
				}
			});
	};
}

export function showTesting(data) {
	return async (dispatch) => {
		await initAPI();
		await axios
			.post(`/exam/detail`, data)
			.then((res) => {
				dispatch({
					type: ActionTypes.ERROR_RESET,
				});
				if (res.data.code === 200) {
					const data = res.data.data;
					dispatch({ type: ActionTypes.SHOW_TESTING, data });
				} else {
					notification.warning({
						message: res.data.message,
						placement: "bottomRight",
						bottom: 50,
						duration: 3,
					});
				}
			})
			.catch(async (err) => {
				if (typeof err.response.data !== "undefined") {
					dispatch({
						type: ActionTypes.ERROR,
						err: true,
						errMessage: err.response.data.message,
					});
					let msg = err.response.data.message;
					if (err && err.response && err.response.status === 401) {
						msg = err.response.data.message;
						await redirectToLogin(err.response.status);
					}
					notification.error({
						message: msg,
						placement: "topRight",
						top: 50,
						duration: 3,
					});
				}
			});
	};
}

export function detailTesting(data) {
	return async (dispatch) => {
		await initAPI();
		await axios
			.post(`testing/detail`, data)
			.then((res) => {
				let testing = res.data.data;
				dispatch({ type: ActionTypes.DETAIL_TESTING, testing });
			})
			.catch(async (err) => {
				if (typeof err.response.data !== "undefined") {
					let msg = err.response.data.message;
					if (err && err.response && err.response.status === 401) {
						msg = err.response.data.message;
						await redirectToLogin(err.response.status);
					}
					notification.error({
						message: msg,
						placement: "topRight",
						top: 50,
						duration: 3,
					});
				}
			});
	};
}

export function createTesting(data) {
	return async (dispatch) => {
		await initAPI();
		await axios
			.post(`/testing/create`, data)
			.then((res) => {
				if (res.data.code === 200) {
					const result = res.data.data;
					dispatch({
						type: ActionTypes.CREATE_TESTING,
						result: result,
					});
				} else {
					notification.warning({
						message: res.data.message,
						placement: "bottomRight",
						bottom: 50,
						duration: 3,
					});
				}
			})
			.catch(async (err) => {
				if (typeof err.response.data !== "undefined") {
					let msg = err.response.data.message;
					if (err && err.response && err.response.status === 401) {
						msg = err.response.data.message;
						await redirectToLogin(err.response.status);
					}
					notification.error({
						message: msg,
						placement: "topRight",
						top: 50,
						duration: 3,
					});
				}
			});
	};
}

export function initAnswer(data) {
	return async (dispatch) => {
		await dispatch({ type: ActionTypes.INIT_ANSWER, answer: data });
	};
}

export function clickAnswer(data) {
	return async (dispatch) => {
		await dispatch({ type: ActionTypes.CLICK_ANSWER, answer: data });
	};
}

export function resetState() {
	return async (dispatch) => {
		await dispatch({ type: ActionTypes.RESET });
	};
}

export function viewAnswer(data) {
	return async (dispatch) => {
		await initAPI();
		await await axios
			.post(`question/answer`, data)
			.then((res) => {
				let answerData = res.data.data;
				dispatch({ type: ActionTypes.VIEW_ANSWER, answerData });
			})
			.catch(async (err) => {
				if (typeof err.response.data !== "undefined") {
					let msg = err.response.data.message;
					if (err && err.response && err.response.status === 401) {
						msg = err.response.data.message;
						await redirectToLogin(err.response.status);
					}
					notification.error({
						message: msg,
						placement: "topRight",
						top: 50,
						duration: 3,
					});
				}
			});
	};
}

export function resetViewAnswer(data) {
	return async (dispatch) => {
		await dispatch({ type: ActionTypes.RESET_VIEWDATA });
	};
}

export function examPreview(data) {
	return async (dispatch) => {
		await initAPI();
		await await axios
			.post(`exam/preview`, data)
			.then((res) => {
				var examPreview = res.data.data;
				dispatch({ type: ActionTypes.EXAM_PREVIEW, examPreview });
			})
			.catch(async (err) => {
				if (typeof err.response.data !== "undefined") {
					let msg = err.response.data.message;
					if (err && err.response && err.response.status === 401) {
						msg = err.response.data.message;
						await redirectToLogin(err.response.status);
					}
					notification.error({
						message: msg,
						placement: "topRight",
						top: 50,
						duration: 3,
					});
				}
			});
	};
}
