import React, { Component } from "react";
import { NavLink, Route, withRouter, Switch } from "react-router-dom";
import { notification, Modal } from "antd";
import Profile from "./Profile";
import Exam from "./Exam";
import { bindActionCreators } from "redux";

import { connect } from "react-redux";

import { logout } from "../redux/auth/action";
import Videos from "./Videos";
import Test from "./Test";
import TestDetail from "./TestDetail";
import Viewword from "./Viewword";

import { changePassword } from "../redux/profile/action";
import { getContactBook } from "../redux/contactbook/action";
import Ranking from "./ranking";
import ClassList from "./ClassList";
import MenuMain from "./MenuMain";
import StatusMain from "./StatusMain";
import MasterClassesContainer from "./MasterClasses";
import Bill from "./Bill";
import ContactBook from "./ContactBook";
import Document from "./Document";
import DocumentShow from "./document/DocumentShow";
import Message from "./message/Message";
import MessageShow from "./message/MessageShow";
import $ from "jquery";
import ClassroomAccess from "./ClassroomAccess";
import * as Icon from "react-feather";
import { setCookie } from "../config/api";

class Home extends Component {
	constructor(props) {
		super();
		this.state = {
			password: "",
			new_password: "",
			confirm: "",
			phoneNumber: "",
			isChangePass: false
		};
	}

	logout = () => {
		setCookie("TOKEN", null, 0);
		this.props.logout();
	};

	handleChange = (e) => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value,
		});
	};

	handleClickChangePass = (e) => {
		this.setState({
			isChangePass: true
		});
	}

	handleSubmitPhone = async (e) => {
		e.preventDefault();
		const data = {
			phone: this.state.phoneNumber,
		};

		if (this.state.phoneNumber !== "") {
			await this.props.getContactBook(data);

			await this.props.history.push("/so-lien-lac");
		} else {
			this.phoneInput.focus();
			notification.error({
				message: "Không được để trống",
				placement: "topRight",
				top: 50,
				duration: 3,
			});
		}
	};

	checkRoute = () => {
		if (document.location.href.includes("/classrooms/")) {
			return true;
		}
	};

	checkRouteTest = () => {
		if (document.location.pathname.includes("/test/")) {
			return true;
		}
		return false;
	};

	componentDidMount() {
		$(document).ready(function () {
			var modal = document.getElementById("contact-book");
			var btn = document.getElementById("myBtn");
			var span = document.getElementsByClassName("close-home-page")[0];

			if (btn !== null) {
				btn.onclick = function () {
					modal.style.display = "block";
				};
			}

			if (span != null) {
				span.onclick = function () {
					modal.style.display = "none";
				};
			}

			window.onclick = function (event) {
				if (event.target === modal) {
					modal.style.display = "none";
				}
			};
		});
	}

	render() {
		return (
			<>

				<div id="main">
					<div className={this.checkRoute() !== true ? 'app-container d-flex' : 'app-container'}>

						{this.checkRoute() !== true && (
							<MenuMain {...this.props} />
						)}

							<div className={this.checkRoute() !== true ? 'main-body' : 'main-classroom d-flex'}>
								{this.checkRoute() !== true && (
								<div id="header-home" style={{display: 'none'}}>
									<div className="header-right-1">
										<div className="home">
											<NavLink
												to={`${this.props.match.url}/profile`}
											><Icon.User color={"#f05225"}
												id="show-user"
												style={{
													width: "20px",
													cursor: "pointer",
												}}
												/>{" "}
												{this.props.user.fullname != null
													? 'Chào,' + this.props.user.fullname
													: "Chào, Admin"}
											</NavLink>
										</div>
									</div>
								</div>
							)}
								<Switch>
									<Route
										exact
										path={`${this.props.match.path}/profile`}
										render={() => <Profile />}
									/>
									<Route
										exact
										path={`${this.props.match.path}/classroom-access`}
										render={() => <ClassroomAccess />}
									/>
									<Route
										exact
										path={`${this.props.match.path}/testings/:id`}
										render={() => <Exam />}
									/>
									<Route
										exact
										path={`${this.props.match.path}/test/:id/:class`}
										render={() => <Exam />}
									/>
									<Route
										exact
										path={`${this.props.match.path}/testings`}
										render={() => <Test />}
									/>
									<Route
										exact
										path={`${this.props.match.path}/testings/:id/view`}
										render={() => <TestDetail />}
									/>
									<Route
										exact
										path={`${this.props.match.path}/videos`}
										render={() => <Videos />}
									/>
									<Route
										exact
										path={`${this.props.match.path}/viewword`}
										render={() => <Viewword />}
									/>
									<Route
										exact
										path={`${this.props.match.path}/ranking`}
										render={() => <Ranking />}
									/>
									<Route
										exact
										path={`${this.props.match.path}/classrooms`}
										render={() => <ClassList />}
									/>
									<Route
										exact
										path={`${this.props.match.path}/documents`}
										render={() => <Document />}
									/>
									<Route
										exact
										path={`${this.props.match.path}/documents/:id/view`}
										render={() => <DocumentShow />}
									/>
									<Route
										exact
										path={`${this.props.match.path}/messages`}
										render={() => <Message />}
									/>
									<Route
										exact
										path={`${this.props.match.path}/message/:id/view`}
										render={() => <MessageShow />}
									/>
									<Route
										exact
										path={`${this.props.match.path}/bills`}
										render={() => <Bill />}
									/>
									<Route
										exact
										path={`${this.props.match.path}/solienlac`}
										render={() => <ContactBook />}
									/>
									<Route
										path={`${this.props.match.path}/classrooms/:id`}
										render={() => <MasterClassesContainer />}
									/>
								</Switch>

								<Switch>
									<Route
										exact
										path={`${this.props.match.path}/`}
										component={StatusMain}
									/>
									<Route
										exact
										path={`${this.props.match.path}/overview`}
										component={StatusMain}
									/>
									<Route
										exact
										path={`${this.props.match.path}/testings/:id`}
										component={StatusMain}
									/>
									<Route
										exact
										path={`${this.props.match.path}/testings`}
										component={StatusMain}
									/>
									<Route
										exact
										path={`${this.props.match.path}/testings/:id/view`}
										component={StatusMain}
									/>
									<Route
										exact
										path={`${this.props.match.path}/viewword`}
										component={StatusMain}
									/>
									<Route
										exact
										path={`${this.props.match.path}/ranking`}
										component={StatusMain}
									/>
									<Route
										exact
										path={`${this.props.match.path}/classrooms`}
										component={StatusMain}
									/>
									<Route
										exact
										path={`${this.props.match.path}/documents`}
										component={StatusMain}
									/>
									<Route
										exact
										path={`${this.props.match.path}/bills`}
										component={StatusMain}
									/>
								</Switch>
							</div>
					</div>
				</div>
				{/* <!-- The Modal --> */}


				<Modal
					footer={null}
					width={500}
					closable={true}
					visible={this.state.isChangePass}
					onOk={this.handleOk}
					onCancel={this.handleCancel}
					okText="Bắt đầu ngay"
					cancelText="Làm lại"
					cancelButtonProps={{
						style: {
							display: "none",
						},
					}}
				>
					<h3 className="text-center">Đổi mật khẩu</h3>
					<form
						onSubmit={this.handleSubmit}
						action=""
						method="post"
						id="from_change_pass"
					>
						<div className="formcontrol">
							<div className="controlinput">
								<input
									type="password"
									placeholder="Mật khẩu cũ..."
									value={this.state.password}
									onChange={this.handleChange}
									name="password"
									className="form-control"
									required
								/>
							</div>
						</div>
						<div className="formcontrol">
							<div className="controlinput">
								<input
									type="password"
									placeholder="Mật khẩu mới..."
									value={this.state.new_password}
									onChange={this.handleChange}
									name="new_password"
									id="new_password"
									className="form-control"
									required
								/>
							</div>
						</div>
						<div className="formcontrol">
							<div className="controlinput">
								<input
									type="password"
									placeholder="Xác nhận mật khẩu..."
									value={this.state.confirm}
									onChange={this.handleChange}
									name="confirm"
									className="form-control"
									required
								/>
							</div>
						</div>
						<button type="submit" className="btn-1">
							Xác nhận
						</button>
					</form>

				</Modal>

				<div id="contact-book" className="modal">
					{/* Modal content */}
					<div className="modal-content">
						<div className="modal-header">
							<span className="close-home-page">×</span>
							<h2>Sổ liên lạc</h2>
						</div>
						<div className="modal-body">
							<form onSubmit={this.handleSubmitPhone}>
								<div className="form-group">
									<input
										type="text"
										name="phoneNumber"
										placeholder="Nhập số điện thoại..."
										className="phone form-control"
										value={this.state.phoneNumber}
										ref={(input) => {
											this.phoneInput = input;
										}}
										onChange={this.handleChange}
									/>
								</div>
								<div className="form-group text-center mt-3">
									<button
										type="submit"
										className="btn btn-primary"
									>
										Xem
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		token: state.auth.token,
		user: state.auth.user,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{ logout, changePassword, getContactBook },
		dispatch
	);
}

let LogoutContainer = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Home)
);

export default LogoutContainer;
