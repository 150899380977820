import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class Row extends Component {
	constructor(props) {
		super();
		this.state = {
			check: false,
		};
	}

	render() {
		return (
			<li
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<div className="stt_status">
					<div className="stt">{this.props.index + 1}</div>
				</div>
				<div className="info info_classes">
					<div className="name-info">
						<b>{this.props.obj.fullname}</b>
					</div>
					<div className="medium_score">
						<p
							style={{
								fontSize: 18,
								fontWeight: "600",
								textAlign: "right",
								color: "#ae1c30",
							}}
						>
							{Math.round(this.props.obj.avg_point * 10) / 10}
						</p>
					</div>
				</div>
			</li>
		);
	}
}

class StatusClasses extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		if (this.props.overview != null) {
			var {
				classroom_avg_point,
				num_student,
				student_avg_point,
				student_num_testing,
				student_ranking,
				testings,
			} = this.props.overview;
			this.setState({
				classroom_avg_point,
				num_student,
				student_avg_point,
				student_num_testing,
				student_ranking,
				testings,
			});
		}
	}

	fetchRows = () => {
		if (this.props.overview) {
			var { classroom_top_ranking, members } = this.props.overview;
			var data = Array.from(classroom_top_ranking, (ele) => {
				return Object.assign(
					{},
					{
						id: ele._id.id,
						code: ele._id.code,
						avg_point: ele.avg_point,
					}
				);
			});

			// eslint-disable-next-line array-callback-return
			var dataFilter = data.filter((ele) => {
				const check = members.filter((item) => ele.id === item._id);
				if (check.length !== 0) {
					return ele;
				}
			});

			dataFilter.map((ele) => {
				members.map((item) => {
					if (ele.id === item._id) {
						return Object.assign(ele, item);
					}
					return null;
				});
				return null;
			});

			if (dataFilter.length !== 0) {
				return dataFilter.map((object, i) => {
					return <Row obj={object} key={object._id} index={i} />;
				});
			} else {
				return null;
			}
		}
	};

	render() {
		return (
			<>
				<div className="col-right">
					<div className="status-classes">
						<div className="top-status">
							<h2>Top 10</h2>
						</div>
						<div className="box-status">
							<ul>{this.fetchRows()}</ul>
						</div>
					</div>
				</div>
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		classroom: state.classroom.classroom,
		overview: state.classroom.overviewClass,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(StatusClasses)
);
