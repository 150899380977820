import * as ActionTypes from './type';
import axios from 'axios';
import { initAPI, notify, responseError} from '../../config/api';

export function listMessage(data) {
    initAPI();
    return async dispatch => {
        await axios
            .post(`/message/my`, data)
            .then(res => {
                notify(res, false);
                dispatch({ type: 'PAGING', page: data.page });
                const messages = res.data.data.records;
                const total = res.data.data.totalRecord;
                const limit = res.data.data.perPage;
                dispatch({
                    type: ActionTypes.LIST_MESSAGE,
                    messages,
                    total,
                    limit,
                });
            })
            .catch(async err => {
                responseError(err);
            });
    };
}

export function showMessage(id) {
    initAPI();
    return async dispatch => {
        const data = {
            id: id,
            type:'FROM_LIST'
        };
        await axios
            .post(`message/detail`, data)
            .then(res => {
                notify(res, false);
                if (res.data.code === 200) {
                    let message = res.data.data;
                    dispatch({ type: ActionTypes.SHOW_MESSAGE, message });
                }
            })
            .catch(async err => {
                responseError(err);
            });
    };
}
