import React, {Component} from 'react';

export default class Viewword extends Component {
	render() {
		return (
			<div class="content">
				<div class="top-content">
					<button class="filter">Đề số 1</button>
				</div>
				<div class="shadow-blue">
					<div class="question-number">
						<span>Câu số 3:</span>
					</div>
					<div class="d-title">
						<p>
							<b>[Toán - Lớp 12]</b>
							giải tự luận giúp mình ạ
						</p>
					</div>
					{/* <img src="/assets/img/1570362925775.jpg" alt=""> */}
					<div class="idea">
						{/* <img src="/assets/img/reply.png" alt=""> */}
						<span>Trả lời</span>
						<button class="answer">A</button>
						<button class="answer">B</button>
						<button class="answer">C</button>
						<button class="answer">D</button>
					</div>
				</div>
			</div>
		);
	}
}
