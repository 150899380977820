import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import Moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { classOverview as getOverview } from "../redux/classroom/action";
import { listMember } from "../redux/member/action";
import { isUndefined } from "util";

class Row extends Component {
	constructor(props) {
		super();
		this.state = {};
	}

	render() {
		return (
			<div className="group-column">
				<div className="column">
					<span>
						<NavLink
							className="overview-exam-code"
							to={`${this.props.match.url}/testings/${this.props.obj._id}`}>
							{!isUndefined(this.props.obj.exam) &&
								this.props.obj.exam.code}
						</NavLink>
					</span>
				</div>
				<div className="column">
					<span>
						<NavLink
							style={{ fontWeight: "600", color: "#666" }}
							to={`${this.props.match.url}/testings/${this.props.obj._id}`}
						>{!isUndefined(this.props.obj.exam) &&
							this.props.obj.exam.name}
						</NavLink>
					</span>
				</div>
				<div className="column">
					<span>
						{!isUndefined(this.props.obj.point) &&
							Math.round(this.props.obj.point * 10) / 10}
					</span>
				</div>
				<div className="column">
					<span>
						{Moment(this.props.obj.created_at).format("DD/MM/YYYY")}{" "}
					</span>
				</div>
			</div>
		);
	}
}

class ClassOverview extends Component {
	constructor(props) {
		super(props);
		const date = new Date();
		let month = date.getMonth() + 1;
		let year = date.getFullYear();
		this.state = {
			keyword: "",
			month: month,
			year: year
		};
	}

	handleChange = async e => {
		var name = e.target.name;
		var value = e.target.value;
		await this.setState({
			[name]: value
		});
		await this.props.getOverview(this.getData());
	};

	fetchRows() {
		if (this.props.overview != null) {
			if (this.props.overview.testings instanceof Array) {
				return this.props.overview.testings.map((object, i) => {
					return (
						<Row
							obj={object}
							key={object._id}
							index={i}
							{...this.props}
						/>
					);
				});
			}
		}
	}

	getData = () => {
		const data = {
			id: this.props.class_id,
			month: this.state.month,
			year: this.state.year
		};
		return data;
	};

	componentDidMount = async () => {
		await this.props.getOverview(this.getData());
		if (this.props.overview != null) {
			var {
				classroom_avg_point,
				num_student,
				classroomUser,
				student_avg_point,
				student_num_testing,
				student_ranking,
				testings
			} = this.props.overview;
			await this.setState({
				classroom_avg_point,
				num_student,
				student_avg_point,
				student_num_testing,
				student_ranking,
				testings,
				classroomUser
			});
		}
	};

	fetchMonth = () => {
		const month = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
		return month.map(month => {
			return (
				<option value={month} key={month}>
					Tháng {month}
				</option>
			);
		});
	};

	fetchYear = () => {
		const datetime = new Date();

		const yearNow = datetime.getFullYear();

		const yearAgo = datetime.getFullYear() - 2;

		var arr = [];
		var i;
		for (i = parseInt(yearAgo); i <= parseInt(yearNow); i++) {
			arr.push(i);
		}
		if (arr.length !== 0) {
			return arr.map(year => {
				return (
					<option value={year} key={year}>
						Năm {year}
					</option>
				);
			});
		}
	};

	render() {
		const { name, code } = this.props.classroom;
		let sobuoiconlai = 0;
		if (this.state.classroomUser) {
			if (this.state.classroomUser.sobuoihoc && this.state.classroomUser.sobuoihoc) {
				sobuoiconlai = parseInt(this.state.classroomUser.sobuoihoc) - parseInt(this.state.classroomUser.buoidahoc);
			}
		}

		if (this.props.overview !== null) {
			var {
				classroom_avg_point,
				num_student,
				student_avg_point,
				student_num_testing,
				student_ranking,
			} = this.props.overview;
		}
		return (
			<div id="overview" className="col-center">
				<div className="top-overview">
					<div className="t-overview-left">
						<h2>{name}</h2>

						<div className="row row-overview">
							<span className="code-class-label">
								Mã lớp{" "}
								<span className="code-class">{code}</span>
							</span>
							<select
								className="select-css"
								name="month"
								onChange={this.handleChange}
								value={this.state.month}
							>
								<option value="">Chọn tháng</option>
								{this.fetchMonth()}
							</select>
							<select
								className="select-css"
								name="year"
								onChange={this.handleChange}
								value={this.state.year}
							>
								{this.fetchYear()}
							</select><br/>
							<span style={{ color: '#fff' }}>Số buổi còn lại:
								<strong style={{ color: '#fff', fontSize: 20, paddingLeft: 10 }}>{!isNaN(sobuoiconlai) ? sobuoiconlai : 0}</strong>
							</span>
						</div>
					</div>
					<div className="t-overview-right">
						<div className="box-class-number">
							<div className="title-c-number">
								<h3>Sĩ số lớp</h3>
								<div className="number-sts">
									{this.props.overview != null && num_student}
								</div>
							</div>
						</div>
						<div className="box-class-number">
							<div className="title-c-number">
								<h3>ĐTB của bạn</h3>
								<div className="number-sts">
									{this.props.overview != null &&
										!isNaN(student_avg_point)
										? Math.round(student_avg_point * 10) /
										10
										: ""}
								</div>
							</div>
						</div>
					</div>
					<div className="t-overview-right">
						<div className="box-class-number">
							<div className="title-c-number">
								<h3>ĐTB Lớp</h3>
								<div className="number-sts">
									{this.props.overview != null &&
										!isNaN(classroom_avg_point)
										? Math.round(classroom_avg_point * 10) /
										10
										: ""}
								</div>
							</div>
						</div>
						<div className="box-class-number">
							<div className="title-c-number">
								<h3>Xếp hạng</h3>
								<div className="number-sts">
									{this.props.overview != null &&
										!isNaN(student_ranking)
										? Math.round(student_ranking)
										: "KXH"}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="personal-scoreboard">
					<div className="scoreboard-left">
						<div className="title">
							<h2>Bảng điểm cá nhân</h2>
							<Link
								className="btn-1"
								to={this.props.match.url + "/testings?tab=1"}
							>
								Xem tất cả (
								{this.props.overview != null &&
									student_num_testing}
								)
							</Link>
						</div>
						<div className="box-content">
							<div className="group-column overview-testing-heading">
								<div className="column">
									<b>Mã đề</b>
								</div>
								<div className="column">
									<b>Tên đề thi</b>
								</div>
								<div className="column">
									<b>Điểm</b>
								</div>
								<div className="column">
									<b>Ngày thi</b>
								</div>
							</div>
							<br />
							{this.fetchRows()}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		classroom: state.classroom.classroom,
		overview: state.classroom.overviewClass,
		members: state.member.members
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ getOverview, listMember }, dispatch);
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ClassOverview)
);
