import React, { Component } from "react";
import { Route, Redirect, withRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Index from "./components/Index";
import ContactBook from "./components/ContactBook";
import TestOnline from "./components/test/TestOnline";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Home from "./components/Home";
import ForgetPassword from "./components/ForgetPassword";

import { login } from "./redux/auth/action";
import { getCookie } from "./config/api";

class App extends Component {
	constructor(props) {
		super(props);

		var token = null;
		if (getCookie("TOKEN")) {
			token = getCookie("TOKEN");
		}
		this.state = {
			token: token
		};
	}

	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.token != null) {
			this.setState({
				token: nextProps.token
			});
		}
	}

	checkRouteTest = () => {
		if (document.location.pathname.includes("/test/")) {
			return true;
		}
		if (document.location.pathname.includes("/test-online/")) {
			return true;
		}
		if (document.location.pathname.includes("/app/classrooms")) {
			return true;
		}
		if (document.location.pathname.includes("/app/classroom-access")) {
			return true;
		}
		if (document.location.pathname.includes("/app/profile")) {
			return true;
		}
		if (document.location.pathname.includes("/app/documents")) {
			return true;
		}
		if (document.location.pathname.includes("/app/messages")) {
			return true;
		}

		if (document.location.pathname.includes("/app/message")) {
			return true;
		}

		if (document.location.pathname.includes("/app/bills")) {
			return true;
		}
		return false;
	};

	checkRouteNotToken = () => {
		if (document.location.pathname.includes("/so-lien-lac")) {
			return true;
		}
		if (document.location.pathname.includes("/test-online")) {
			return true;
		}
		if (document.location.pathname.includes("/login")) {
			return true;
		}
		if (document.location.pathname.includes("/signup")) {
			return true;
		}
		if (document.location.pathname.includes("/forget-password")) {
			return true;
		}
		return false;
	};

	render() {
		return (
			<>
				<Route exact path="/" component={Index} />
				<Route exact path="/so-lien-lac" component={ContactBook} />
				<Route exact path="/test-online" component={TestOnline} />
				<Route exact path="/login" component={Login} />
				<Route exact path="/signup" component={Signup} />
				<Route exact path="/forget-password" component={ForgetPassword}/>

				{this.state.token == null ? (
					<>
						{this.checkRouteNotToken() !== true ? (
							<Redirect to="/" />
						) : null}
					</>
				) : (
						<>
							<Switch>
								<Redirect exact from="/app" to="/app/classrooms" />

								<PrivateRoute
									path="/app"
									token={this.state.token}
									component={Home}
								/>
							</Switch>
							
							{this.checkRouteTest() !== true ? (
								<>
									{this.state.token !== null ? (
										<Switch>
											<Redirect
												exact
												from="/app"
												to="/app/classrooms"
											/>

											<PrivateRoute
												path="/app"
												token={this.state.token}
												component={Home}
											/>
										</Switch>
									) : (
											<Redirect to="/" />
										)}
								</>
							) : null}
						</>
					)}
			</>
		);
	}
}

function PrivateRoute({ children, ...rest }) {
	return (
		<Route
			{...rest}
			render={({ location }) =>
				rest.token !== null ? (
					children
				) : (
						<Redirect
							to={{
								pathname: "/login",
								state: { from: location }
							}}
						/>
					)
			}
		/>
	);
}

function mapStateToProps(state) {
	return {
		token: state.auth.token
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ login }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
