import React, { Component } from 'react';
import {
	NavLink,
	withRouter,
} from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getVideo, resetVideo } from '../redux/video/action';
import { getContactBook } from '../redux/contactbook/action';
import { logout } from "../redux/auth/action";
import { setCookie } from "../config/api";
import * as Icon from 'react-feather';

class MenuMain extends Component {
	constructor(props) {
		super();
		this.state = {
			unreadMessageCount: 0,
		};
	}

	logout = async () => {
		await localStorage.clear();
		await localStorage.removeItem("persist:app");
		await this.props.logout();
		setCookie("TOKEN", null, 0);
		this.props.logout();
		window.location.href = "/login";
	};

	render() {
		return (
			<>
				<div className="list col-left leftMenu">
					<div className="sidebar-logo">
					<a href="/"><img src="https://cdn.luyenthitiendat.vn/assets/v2/logo.svg" alt="DCV"/></a>
					</div>
					<ul>
						<li>
							<NavLink
								to={`${this.props.match.url}/profile`}
								className="click_t_menu "
								activeClassName="active">
								<div className="class-menu-icon">
									<Icon.User style={{ width: '20px', color:'#aaa' }} />
								</div>
								Tài khoản
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`${this.props.match.url}/classroom-access`}
								className="click_t_menu"
								activeClassName="active">
								<div className="class-menu-icon">
									<Icon.Navigation style={{ width: '20px', color:'#aaa' }} />
								</div>
								Truy cập lớp
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`${this.props.match.url}/classrooms`}
								className="click_t_menu"
								activeClassName="active">
								<div className="class-menu-icon">
									<Icon.Tag style={{ width: '20px', color:'#aaa' }} />
								</div>
								Lớp học
							</NavLink>
						</li>
						<li style={{ display: 'none' }}>
							<NavLink
								to={`${this.props.match.url}/documents`}
								className="click_t_menu"
								activeClassName="active">
								<div className="class-menu-icon">
									<Icon.BookOpen style={{ width: '20px', color:'#aaa' }} />
								</div>
								Tài liệu
							</NavLink>
						</li>
						<li>
							<NavLink
								to={`${this.props.match.url}/bills`}
								className="click_t_menu"
								activeClassName="active">
								<div className="class-menu-icon">
									<Icon.CreditCard style={{ width: '20px', color:'#aaa' }} />
								</div>
								Học phí
							</NavLink>
						</li>
						<li style={{ position: 'relative' }}>
							<NavLink
								to={`${this.props.match.url}/messages`}
								className="click_t_menu"
								activeClassName="active">
								<div className="class-menu-icon">
									<Icon.Bell style={{ width: '20px', color:'#aaa' }} />
								</div>
								Thông báo
								{this.state.unreadMessageCount !== 0 ? (
									<span className="unreadMessageCount">{this.state.unreadMessageCount}</span>
								) : null}
							</NavLink>
						</li>
						<li style={{ position: 'relative' }}>
							<NavLink
								onClick={this.logout} to="/logout"
								className="click_t_menu1">
								<div className="class-menu-icon">
									<Icon.LogOut style={{ width: '20px', color:'#aaa' }} />
								</div> Đăng xuất
							</NavLink>
						</li>
					</ul>
				</div>
			</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		redirect: state.video.redirect,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ getVideo, resetVideo, getContactBook, logout }, dispatch);
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(MenuMain),
);
