import * as ActionTypes from './type';
const initialState = {
	documents: [],
	document: null,
	total: 0,
	page: 1,
	limit: 20,
	ids: [],
};
const reducer = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.LIST_DOCUMENT:
			return {
				...state,
				documents: action.documents,
				total: action.total,
				limit: action.limit,
				ids: [],
			};
		case ActionTypes.SHOW_DOCUMENT:
			return {
				...state,
				document: action.document
			};
		default:
			return state;
	}
};

export default reducer;
