import React, { Component } from "react";
import * as Icon from "react-feather";
class Row extends Component {
	constructor(props) {
		super();
		this.state = {
			check: false,
		};
	}

	render() {
		return (
			<li>
				<div className="avata">
					<div className="box-avata">
						<Icon.User
							id="show-user"
							style={{
								width: "20px",
								cursor: "pointer",
								color: "#9d9d9d",
							}}
						/>
					</div>
				</div>
				<div className="info">
					<div className="name-info">
						<b>{this.props.obj.user.name}</b>
					</div>
					<div className="point_score">
						<span>
							{Math.round(this.props.obj.point * 10) / 10}
						</span>
					</div>
				</div>
			</li>
		);
	}
}

export default class StatusClassesTest extends Component {
	fetchRows() {
		if (this.props.data instanceof Array) {
			return this.props.data.map((object, i) => {
				return <Row obj={object} key={object._id} index={i} />;
			});
		}
	}

	render() {
		return (
			<>
				<div className="col-right">
					<div className="status-classes">
						<div className="top-status">
							<h2>Học sinh làm bài tốt nhất</h2>
						</div>
						<div className="box-status">
							<ul>{this.fetchRows()}</ul>
						</div>
					</div>
				</div>
			</>
		);
	}
}
