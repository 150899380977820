import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { notification } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { classroomAccess } from '../redux/classroom/action';

class ClassroomAccess extends Component {
	constructor(props) {
		super();
		this.state = {
			code: '',
		};
	}

	onChange = e => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value,
		});
	};
	componentDidMount() {
		this.setState({
			redirect: this.props.redirect,
		});
	}

	handleSubmit = async () => {
		if (this.state.code === '') {
			notification.warning({
				message: 'Mã là bắt buộc',
				placement: 'topRight',
				top: 50,
				duration: 3,
			});
		} else {
			await this.props.classroomAccess({ code: this.state.code });

			if (this.props.accessData !== null) {
				await this.setState({
					code: '',
				});
				if (this.props.accessData && this.props.accessData.classroom_id) {
					await this.props.history.push(
						'/app/classrooms/' + this.props.accessData.classroom_id
					);
				}
			}
		}
	};

	closeModal = async () => {
		await this.props.history.push('/app/videos');
	};

	componentDidUpdate(prevProps) {
		if (this.props.redirect !== prevProps.redirect) {
			this.setState({
				redirect: this.props.redirect,
			});
		}
	}

	render() {
		return (
			<>
				<div id="header-home">
					<div className="page-title">
						<h2>Truy cập lớp học</h2>
					</div>
				</div>
				<div id="profile" className="content page-container">
					<div
						className="main-profile-fix"
						style={{ background: `transparent` }}>
						<div className="classroom-access">
							<input
								type="text"
								placeholder="Nhập mã"
								name="code"
								onChange={this.onChange}
								defaultValue={this.state.code}
								className="input-v2"
								autoFocus
							/>

							<button
								type="button"
								className="button-v2"
								onClick={this.handleSubmit}>
								Truy cập
							</button>
						</div>
					</div>
				</div>
			</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		accessData: state.classroom.accessData,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ classroomAccess }, dispatch);
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ClassroomAccess),
);
