import * as ActionTypes from './type';

const initialState = {
   userInfo : {},
   dataRank : [],
   dataBill : [],
   dataSatistic : {},
}

const reducer = (state = initialState, action) => {
   
    switch (action.type) {

        case ActionTypes.SHOW_PROFILE:
            return {
                ...state,
                userInfo: action.userInfo,
            };

        case ActionTypes.SHOW_RANK:
    
            return {
                ...state,
                dataRank: action.dataRank,
            };
        
            case ActionTypes.SHOW_BILL:
    
            return {
                ...state,
                dataBill: action.dataBill,
            };

        case ActionTypes.SHOW_STATISTIC:

            return {
                ...state,
                dataSatistic: action.dataSatistic,
            };


        case ActionTypes.UPDATE_PROFILE:
            return {
                ...state,
                userInfo: action.payload.data
            };
        case ActionTypes.CHANGE_PASSWORD:

        return {
            ...state,
        };
        default:
            return state;
    }
}
export default reducer;