import axios from "axios";
import { initAPI, redirectToLogin } from "../../config/api";
import * as ActionTypes from "./type";
import { notification } from "antd";

export function showProfile() {
	initAPI();

	return async dispatch => {
		const data = {};

		await axios
			.post(`/user/profile`, data)
			.then(res => {
				let userInfo = res.data.data;
				dispatch({ type: ActionTypes.SHOW_PROFILE, userInfo });
			})
			.catch(async err => {
				let msg = err.response.data.message;
				if (err && err.response && err.response.status === 401) {
					msg = err.response.data.message;
					await redirectToLogin(err.response.status);
				}
				notification.error({
					message: msg,
					placement: "topRight",
					top: 50,
					duration: 3
				});
			});
	};
}

export function showRank() {
	initAPI();

	return async dispatch => {
		const data = {};

		await axios
			.post(`/user/rank`, data)
			.then(res => {
				let dataRank = res.data.data;
				dispatch({ type: ActionTypes.SHOW_RANK, dataRank });
			})
			.catch(async err => {
				let msg = err.response.data.message;
				if (err && err.response && err.response.status === 401) {
					msg = err.response.data.message;
					await redirectToLogin(err.response.status);
				}
				notification.error({
					message: msg,
					placement: "topRight",
					top: 50,
					duration: 3
				});
			});
	};
}

export function showBill() {
	initAPI();

	return async dispatch => {
		const data = {};

		await axios
			.post(`/user/bills`, data)
			.then(res => {
				let dataBill = res.data.data;
				dispatch({ type: ActionTypes.SHOW_BILL, dataBill });
			})
			.catch(async err => {
				let msg = err.response.data.message;
				if (err && err.response && err.response.status === 401) {
					msg = err.response.data.message;
					await redirectToLogin(err.response.status);
				}
				notification.error({
					message: msg,
					placement: "topRight",
					top: 50,
					duration: 3
				});
			});
	};
}

export function showStatistic() {
	initAPI();

	return async dispatch => {
		const data = {};

		await axios
			.post(`/user/statistic`, data)
			.then(res => {
				let dataSatistic = res.data.data;
				dispatch({ type: ActionTypes.SHOW_STATISTIC, dataSatistic });
			})
			.catch(async err => {
				let msg = err.response.data.message;
				if (err && err.response && err.response.status === 401) {
					msg = err.response.data.message;
					await redirectToLogin(err.response.status);
				}
				notification.error({
					message: msg,
					placement: "topRight",
					top: 50,
					duration: 3
				});
			});
	};
}

export function updateProfile(data) {
	initAPI();

	return async dispatch => {
		await axios
			.post(`user/update-profile`, data)
			.then(res => {
				if (res.data.code === 200) {
					notification.success({
						message: res.data.message,
						placement: "topRight",
						top: 50,
						duration: 3
					});
					dispatch({
						type: ActionTypes.UPDATE_PROFILE,
						payload: res.data
					});
				} else {
					notification.error({
						message: res.data.message,
						placement: "topRight",
						top: 50,
						duration: 3
					});
				}
			})
			.catch(async err => {
				let msg = err.response.data.message;
				if (err && err.response && err.response.status === 401) {
					msg = err.response.data.message;
					await redirectToLogin(err.response.status);
				}
				notification.error({
					message: msg,
					placement: "topRight",
					top: 50,
					duration: 3
				});
			});
	};
}

export function changePassword(data) {
	initAPI();

	return async dispatch => {
		await axios
			.post(`/user/change-password`, data)
			.then(res => {
				if (res.data.code === 200) {
					notification.success({
						message: res.data.message,
						placement: "topRight",
						top: 50,
						duration: 3
					});
					dispatch({ type: ActionTypes.CHANGE_PASSWORD, data });
				} else {
					notification.error({
						message: res.data.message,
						placement: "topRight",
						top: 50,
						duration: 3
					});
				}
			})
			.catch(async err => {
				let msg = err.response.data.message;
				if (err && err.response && err.response.status === 401) {
					msg = err.response.data.message;
					await redirectToLogin(err.response.status);
				}
				notification.error({
					message: msg,
					placement: "topRight",
					top: 50,
					duration: 3
				});
			});
	};
}
