import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { listDocument } from '../redux/document/action';

class Row extends Component {
	constructor(props) {
		super();
		this.state = {};
	}

	render() {
		const { subject } = this.props.obj;		
		return (
			<div className="col-md-4">
				<div className="document-item">
					<h3 className="document-title">{this.props.obj.name}</h3>
					<div className="document-level">
						Lớp: {this.props.obj.class_name}
					</div>
					<div className="document-subject">Môn: {subject.name}</div>
					<div className="document-subject">
						GV: {this.props.obj.teacher}
					</div>
					<div className="document-download">
						<a
							href={
								'/app/documents/' +
								this.props.obj._id + '/view'
							}
							className="btn-2">
							<i className="fa fa-download" /> Xem tài liệu
						</a>
					</div>
				</div>
			</div>
		);
	}
}

class Document extends Component {
	constructor(props) {
		super();
		this.state = {
			keyword: '',
			classroom_id: ""
		};
	}

	fetchRows() {
		if (this.props.documents instanceof Array) {
			return this.props.documents.map((object, i) => {
				return (
					<Row
						obj={object}
						key={object._id}
						index={i}
						{...this.props}
					/>
				);
			});
		}
	}

	onChange = e => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value,
		});
	};

	getData = (pageNumber = 1) => {
		const data = {
			page: pageNumber,
			limit: this.state.limit,
			classroom_id: this.props.class_id,
		};
		if (this.state.keyword != null) {
			data['keyword'] = this.state.keyword;
		}
		return data;
	};

	async componentDidMount() {
		await this.props.listDocument(this.getData());
		if (this.props.limit) {
			await this.setState({
				limit: this.props.limit,
			});
		}
	}

	onSubmit = e => {
		e.preventDefault();
		this.props.listDocument(this.getData());
	};

	render() {
		return (
			<div id="class-list" className="col-center page-container">
				<div className="page-title">
					<h2>Kho tài liệu</h2>
				</div>
				<div className="top-class-list">
					<div className="search-class">
						<form onSubmit={this.onSubmit}>
							<div className="form-group">
								<input
									type="text"
									placeholder="Tìm kiếm theo môn..."
									name="keyword"
									onChange={this.onChange}
								/>
								<i
									className="fa fa-search"
									aria-hidden="true"></i>
							</div>
						</form>
					</div>
					<div className="filter-class">
						<select>
							<option value="">Tất cả</option>
							<option value="">A-Z</option>
							<option value="">Z-A</option>
							<option value="">Mới nhất</option>
							<option value="">Cũ nhất</option>
						</select>
					</div>
				</div>
				<div className="box-class-list list-document">
					{this.fetchRows()}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		documents: state.document.documents,
		limit: state.document.limit,
		page: state.document.page,
		total: state.classroom.total,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ listDocument }, dispatch);
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Document),
);
