import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Modal, message } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Teacher from "../../src/teacher.png";

import {
	showTesting,
	resetState,
	clickAnswer,
	createTesting,
	initAnswer,
} from "../redux/testing/action";
import { isObject, isUndefined } from "util";
import Countdown from "react-countdown";
import $ from "jquery";

class Row extends Component {
	constructor(props) {
		super(props);
		this.state = {
			style: {
				background: "green",
			},
			answer: null,
		};
	}

	async componentDidMount() {
		const data = {
			question_id: this.props.obj._id,
			value: this.state.answer,
		};

		await this.props.initAnswer(data);
		if (this.props.totalAnswer === 0) {
			$("input[type=radio]").attr("checked", false);
		}
	}

	handleClick = async (answer) => {
		await this.setState({
			answer,
		});
		const data = {
			question_id: this.props.obj._id,
			value: answer,
		};
		await this.props.clickAnswer(data);
	};

	renderButton = () => {
		const data = ["A", "B", "C", "D"];

		return data.map((item, i) => {
			return (
				<div className="answer" key={i}>
					<label className="answer-checkmark">
						<input
							type="radio"
							name={`question-${this.props.obj._id}`}
							onClick={(e) => this.handleClick(item)}
						/>
						<span className="checkmark"></span>
					</label>
					<b>{item}</b>
				</div>
			);
		});
	};

	render() {
		return (
			<div
				className="box-testexercise"
				id={`question` + (this.props.index + 1)}
			>
				<div className="box-question">
					<div className="question">
						<span>Câu {this.props.index + 1}:</span>
						<span>{this.props.obj.name}</span>
					</div>

					<div className="embed-question">
						<div
							dangerouslySetInnerHTML={{
								__html: this.props.obj.question,
							}}
							className="html-text"
						></div>
					</div>
				</div>
				<div className="box-answer">{this.renderButton()}</div>
			</div>
		);
	}
}

class Exam extends Component {
	constructor(props) {
		super();
		this.state = {
			ModalText: "Bạn có chắc muốn gửi bài không ?",
			visible: false,
			confirmLoading: false,
			totalAnswer: 0,

			ModalTextNotify: "Bạn đã sẵn sàng làm bài kiểm tra chưa ?",
			visibleNotify: true,
			confirmLoadingNotify: false,
			stop: false,

			loading: false,
			visibleResult: false,

			files: [],
			timeStart: null,
			exam: null,
			questions: [],
			time: 0,
		};
	}

	fetchRows() {
		if (this.props.data.questions instanceof Array) {
			return this.props.data.questions.map((object, i) => {
				return (
					<Row
						obj={object}
						key={object._id}
						index={i}
						clickAnswer={this.props.clickAnswer}
						initAnswer={this.props.initAnswer}
					/>
				);
			});
		}
	}

	async componentDidMount() {
		await this.props.showTesting({
			id: this.props.match.params.id,
			classroom_id: this.props.match.params.class,
		}); //test

		if (this.props.data) {
			const {
				questions,
				exam,
				time_req,
				exam_classroom,
			} = this.props.data;

			var timeRemain = 0;
			if (exam_classroom && exam_classroom.is_fixed_time === true) {
				if (
					exam_classroom.started_at !== null &&
					time_req !== null &&
					exam.time !== null
				) {
					var start_at = new Date(exam_classroom.started_at);
					var currentTime = new Date(time_req);
					var time = exam.time;

					var diffDate = Math.floor((currentTime - start_at) / 60000);

					if (time >= diffDate) {
						timeRemain = time - diffDate;
					} else {
						timeRemain = 0;
					}
				}
			} else {
				timeRemain = exam ? exam.time : 0;
			}

			await this.setState({
				questions,
				exam,
				time: timeRemain,
				isTime: exam ? exam.time : 0,
			});
		}
	}

	async componentWillUnmount() {
		await this.props.resetState();
	}

	showModal = () => {
		this.setState({
			visible: true,
		});
	};

	handleOk = async () => {
		var data;
		if (this.props.data.exam.type === "TU_LUAN") {
			data = new FormData();
			data.append("exam_id", this.props.match.params.id);
			data.append("classroom_id", this.props.match.params.class);
			data.append("testing_type", this.props.data.exam.type);
			data.append("answers", this.props.dataAnswer);

			for (var i = 0; i < this.state.files.length; i++) {
				data.append("files[" + i + "]", this.state.files[i]);
			}
		} else {
			data = {
				exam_id: this.props.match.params.id,
				answers: Object.assign(
					this.props.dataAnswer,
					this.props.initAnswers
				),
				classroom_id: this.props.match.params.class,
			};
		}

		await this.setState({
			visible: false,
			confirmLoading: false,
			stop: true,
			loading: false,
		});
		message.loading("Đang chấm điểm bài thi", 1).then(async () => {
			await this.props.createTesting(data);
			await this.setState({
				visibleResult: true,
				files: [],
			});
		});
	};

	handleCancel = () => {
		this.setState({
			visible: false,
		});
	};

	showModalNotify = () => {
		this.setState({
			visibleNotify: true,
		});
	};

	handleOkNotify = async () => {
		if (this.props.errMessage !== null) {
			await this.props.history.push(
				"/app/classrooms/" + this.props.match.params.class + "/testings"
			);
		} else {
			await this.setState({
				visibleNotify: false,
				confirmLoadingNotify: false,
				timeStart: Date.now(),
			});
		}
	};

	handleCancelNotify = () => {
		this.props.history.push("/app");
	};

	handleCancelResult = () => {
		this.setState({
			visibleResult: false,
		});
	};

	onDrop = (files) => {
		this.setState({ files });
	};

	fetchAnchorLink() {
		if (this.props.data.questions instanceof Array) {
			return this.props.data.questions.map((item, i) => {
				var style = {};
				if (this.props.answers.length !== 0) {
					const check = this.props.answers.find(
						({ question_id }) => question_id === item._id
					);
					if (isUndefined(check)) {
						style = {};
					} else {
						style = {
							background: "#1e88e5",
							color: "white",
						};
					}
				}
				return (
					<li key={i}>
						<a href={"#question" + (i + 1)}>
							<button style={style}>{i + 1}</button>
						</a>
					</li>
				);
			});
		}
	}

	fetchListFiles() {
		if (this.state.files instanceof Array) {
			return this.state.files.map((file) => (
				<li key={file.path}>{file.path}</li>
			));
		}
	}

	percent = (result) => {
		const { num_right, num_wrong } = result;

		const percent =
			(parseFloat(num_right) /
				(parseFloat(num_right) + parseFloat(num_wrong))) *
			100;
		return Math.round(percent * 100) / 100;
	};

	renderInfo = (result) => {
		const { point } = result;
		const pointTest = Math.round(parseFloat(point) * 10) / 10;
		if (pointTest >= 9 && pointTest <= 10) {
			return '"Bạn làm bài rất suất sắc, tiếp tục duy trì kết quả này nha!"';
		} else if (pointTest >= 7 && pointTest < 9) {
			return '"Bạn làm bài cũng ổn đấy, hãy cố gắng chinh phục mức điểm cao hơn nữa!"';
		} else if (pointTest >= 5 && pointTest < 7) {
			return '"Bạn làm bài kém quá, chắc chưa ôn kĩ bài rồi phải không? Lần sau phải cố gắng đó nha!"';
		} else if (pointTest >= 0 && pointTest < 5) {
			return '"Bạn lười học quá đấy, cần phải tích cực học và làm bài nhiều hơn nữa!"';
		}
	};

	readURL = async (e) => {
		var input = e.target;

		if (input.files && input.files[0]) {
			var data = this.state.files;
			data.push(input.files[0]);
			await this.setState({
				files: data,
			});
			var reader = new FileReader();

			reader.onload = function (e) {
				var img = `<img src="${e.target.result}"/>`;

				$(".list-image").prepend(img);
			};

			reader.readAsDataURL(input.files[0]);
		}
	};

	render() {
		const { visible, confirmLoading, ModalText } = this.state;
		const { visibleNotify, confirmLoadingNotify } = this.state;
		const { questions } = this.props.data;

		var time = 0;

		if (this.state.time !== 0) {
			time = this.state.time;
		}

		var until = time * 60000 + this.state.timeStart;
		return (
			<>
				{this.state.visibleNotify === true ? (
					<Modal
						className="modal-exam"
						confirmLoading={confirmLoadingNotify}
						bodyStyle={{
							minHeight: 200,
						}}
						closable={false}
						visible={visibleNotify}
						onOk={this.handleOkNotify}
						okButtonProps={{
							style: {
								backgroundColor: "#007bff",
								borderColor: "#007bff",
							},
						}}
						onCancel={this.handleCancelNotify}
						okText={
							this.props.errMessage !== null ? (
								<span style={{ color: "white" }}>Đóng</span>
							) : (
									<span style={{ color: "white" }}>Sẵn sàng</span>
								)
						}
						cancelText="Bỏ qua"
					>
						<div className="widget">
							<div className="widget-left">
								<img src={Teacher} alt="hinh anh" />
							</div>
							<div className="widget-right">
								{this.props.errMessage !== null ? (
									<p className="widget-info">
										{this.props.errMessage}
									</p>
								) : (
										<p className="widget-info">
											Bạn đã sẵn sàng làm bài kiểm tra chưa?
										</p>
									)}
							</div>
						</div>
					</Modal>
				) : (
						<>
							<div className="col-center">
								<div id="testexercise">
									<>
										<div className="box-question-test">
											<div id="overview">
												<div className="top-overview">
													<div className="t-overview-left w-100 m-0">
														<h2>
															Bài thi:{" "}
															<span
																style={{
																	color:
																		"#FFDE32",
																	fontSize:
																		"35px",
																}}
															>
																{isObject(
																	this.props.data
																		.exam
																) &&
																	this.props.data.exam
																		.name != null
																	? this.props
																		.data
																		.exam
																		.name
																	: ""}
															</span>
														</h2>
														<div>
															<div className="col-6">
																<p>
																	Mã đề:{" "}
																	<span>
																		{isObject(
																			this
																				.props
																				.data
																				.exam
																		) &&
																			this.props
																				.data
																				.exam
																				.code !=
																			null
																			? this
																				.props
																				.data
																				.exam
																				.code
																			: ""}
																	</span>
																</p>
															</div>
															<div className="col-6">
																<p>
																	Thời gian làm
																bài:{" "}
																	<span>
																		{isObject(
																			this
																				.props
																				.data
																				.exam
																		) &&
																			this.props
																				.data
																				.exam
																				.time !=
																			null
																			? Math.floor(
																				this
																					.props
																					.data
																					.exam
																					.time
																			)
																			: "10 phút"}
																	</span>
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>

											{this.props.data !== null &&
												this.props.data.exam.type ===
												"TU_LUAN" ? (
													<div
														style={{
															width: "100%",
															marginTop: 15,
															marginBottom: 10,
														}}
													>
														<embed
															src={
																this.props.data.exam
																	.doc_link !==
																	"undefinded"
																	? this.props.data
																		.exam
																		.doc_link
																	: null
															}
															type="application/pdf"
															width="100%"
															height="600px"
														/>
													</div>
												) : (
													this.fetchRows()
												)}
										</div>
									</>
									{/* ) : (
                                <b>Đề thi này không tồn tại</b>
                            )
                    } */}
								</div>
							</div>
							<div className="col-right">
								<div className="box-question-selection">
									<div className="top-q-select">
										<h2>
											{this.props.data.exam.type === "TU_LUAN"
												? "Đính kèm ảnh bài làm"
												: "Chọn câu hỏi"}
										</h2>
										{this.props.data.exam.type === "TU_LUAN" ? (
											<>
												<div className="list-image">
													<div className="upload-btn-wrapper">
														<div className="widget-upload">
															+
													</div>
														<input
															type="file"
															name="file"
															onChange={(e) =>
																this.readURL(e)
															}
														/>
													</div>
												</div>
											</>
										) : (
												<ul>{this.fetchAnchorLink()}</ul>
											)}
									</div>
									<div className="bottom-q-select">
										{this.props.data.exam.type ===
											"TU_LUAN" ? null : (
												<>
													<div className="number-questions-b">
														<i
															className="fa fa-pencil"
															aria-hidden="true"
														></i>
														<span>
															{this.props.totalAnswer}/
													{questions.length} câu
												</span>
													</div>
												</>
											)}

										<div className="homework-time">
											<i
												className="fa fa-clock-o"
												aria-hidden="true"
											></i>
											{until ? (
												<Countdown
													daysInHours={true}
													date={until}
													onComplete={this.handleOk}
												>
													<span>Hết thời gian</span>
												</Countdown>
											) : null}
										</div>
										<div className="finish">
											<button
												className="btn-1"
												id="posting-button"
												onClick={this.showModal}
											>
												Nộp bài
											<span>
													<i
														className="fa fa-chevron-right"
														aria-hidden="true"
													></i>
												</span>
											</button>
											<Modal
												className="modal-exam-notify"
												title="Thông báo"
												closable={false}
												visible={visible}
												onOk={this.handleOk}
												confirmLoading={confirmLoading}
												onCancel={this.handleCancel}
												okText="Gửi bài ngay"
												cancelText="Chưa gửi"
											>
												<p>{ModalText}</p>
											</Modal>
											{this.props.result !== null && (
												<div id="result-testing">
													<Modal
														closable={true}
														className="modal-exam-result"
														visible={
															this.state.visibleResult
														}
														onOk={() =>
															this.props.history.push(
																"/app/classrooms/" +
																this.props.match
																	.params
																	.class +
																"/testings/" +
																this.props
																	.result._id
															)
														}
														onCancel={
															this.handleCancelResult
														}
														okText="Xem kết quả"
														cancelText="Làm lại"
														cancelButtonProps={{
															style: {
																display: "none",
															},
														}}
													>
														<div className="widget">
															<div className="widget-left">
																<img
																	src={Teacher}
																	alt="hinh anh"
																/>
															</div>
															<div className="widget-right">
																<p className="widget-title">
																	Kết quả điểm số
																bài thi{" "}
																	{
																		this.props
																			.result
																			.exam
																			.name
																	}
																</p>
																{this.props.data
																	.exam.type ===
																	"TU_LUAN" ? (
																		<p className="widget-info">
																			Bài làm của
																			bạn đã được
																			gửi đi, bạn
																			có thể xem
																			lại bài đã
																			làm trong
																			mục bài đã
																			làm.
																		</p>
																	) : (
																		<>
																			<p className="widget-point">
																				{Math.round(
																					parseFloat(
																						this
																							.props
																							.result
																							.point
																					) *
																					10
																				) / 10}
																			</p>
																			<p className="widget-true-question">
																				{
																					this
																						.props
																						.result
																						.num_right
																				}{" "}
																		câu đúng
																	</p>
																			<p className="widget-true-question">
																				{this.percent(
																					this
																						.props
																						.result
																				)}
																		% chính
																		xác
																	</p>
																			<p className="widget-info">
																				{this.renderInfo(
																					this
																						.props
																						.result
																				)}
																			</p>{" "}
																		</>
																	)}
															</div>
														</div>
													</Modal>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
						</>
					)}
			</>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		token: state.auth.token,
		user: state.auth.user,
		data: state.testing.data,
		initAnswers: state.testing.initAnswers,
		answers: state.testing.answers,
		dataAnswer: state.testing.dataAnswer,
		totalAnswer: state.testing.totalAnswer,
		result: state.testing.result,
		errMessage: state.testing.errMessage,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{ showTesting, resetState, clickAnswer, createTesting, initAnswer },
		dispatch
	);
}

withRouter(connect(mapStateToProps, mapDispatchToProps)(Row));

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Exam));
