import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {showRank} from '../redux/profile/action';

class Row extends Component {
	constructor(props) {
		super();
		this.state = {};
	}

	render() {
		var point = parseFloat(this.props.obj.point).toFixed(1);
		return (
			<tr>
				<td>{this.props.obj.code}</td>
				<td>{this.props.obj.fullname}</td>
				<td>{point}</td>
			</tr>
		);
	}
}

class Ranking extends Component {
	constructor(props) {
		super();
		this.state = {};
	}

	fetchRows() {
		if (this.props.dataRank instanceof Array) {
			return this.props.dataRank.map((object, i) => {
				return (
					<Row
						obj={object}
						key={object._id}
						index={i}
						{...this.props}
					/>
				);
			});
		}
	}

	async componentDidMount() {
		await this.props.showRank();
	}

	render() {
		return (
			<div id="ranking" class="col-center">
				<div class="title-ranking">
					<span>Bảng xếp hạng</span>
				</div>
				<div class="table_ranking">
					<table class="mytable">
						<tr>
							<th>Mã học sinh</th>
							<th>Họ tên</th>
							<th>Điểm</th>
						</tr>
						<tbody>{this.fetchRows()}</tbody>
					</table>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		dataRank: state.profile.dataRank,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({showRank}, dispatch);
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Ranking),
);
