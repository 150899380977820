import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { signup } from '../redux/auth/action';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class Signup extends Component {
	constructor(props) {
		super();
		this.state = {
			fullname: '',
			phone: '',
			email: '',
			password: '',
		};
	}
	componentDidMount() {
		const { token, updateToken } = this.context;
		this.setState({
			token,
			updateToken,
		});
	}
	handleChange = e => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value,
		});
	};

	handleSubmit = async e => {
		e.preventDefault();
		const data = {
			fullname: this.state.fullname,
			phone: this.state.phone,
			email: this.state.email,
			password: this.state.password,
		};
		await this.props.signup(data);
		if (this.props.token !== null) {
			this.props.history.push('/app/profile');
		} else {
			this.props.history.push('/signup');
		}
	};
	render() {
		return (
			<div id="box-login">
				<div className="login-left">
					<img className="shape-2" src="https://cdn.luyenthitiendat.vn/assets/v2/anhdaidien.svg" alt="Dai Co Viet" />
					<a href="/" className="logo">
						<img src="https://cdn.luyenthitiendat.vn/assets/v2/logo-dcv.svg" alt="Dai Co Viet" />
					</a>
				</div>
				<div className="login-right">
					<div className="login-heading">
						<h2>Đăng ký</h2>
						<div className="registration">
							<span>Đã có tài khoản?</span>
							<Link className="signup" to="/login">Đăng nhập</Link>
						</div>
					</div>
					<form onSubmit={this.handleSubmit}>
						<div className="formcontrol">
							<div className="controlinput">
								<input
								className="input-v2"
									type="text"
									placeholder="Họ và Tên"
									onChange={this.handleChange}
									value={this.state.fullname}
									name="fullname"
								/>
							</div>
						</div>
						<div className="formcontrol">
							<div className="controlinput">
								<input
								className="input-v2"
									type="text"
									placeholder="Số Điện Thoại"
									onChange={this.handleChange}
									value={this.state.phone}
									name="phone"
								/>
							</div>
						</div>
						<div className="formcontrol">
							<div className="controlinput">
								<input
								className="input-v2"
									type="text"
									placeholder="Email"
									onChange={this.handleChange}
									value={this.state.email}
									name="email"
								/>
							</div>
						</div>

						<div className="formcontrol">
							<div className="controlinput">
								<input
								className="input-v2"
									type="password"
									placeholder="Mật Khẩu"
									onChange={this.handleChange}
									value={this.state.password}
									name="password"
								/>
							</div>
						</div>

						<button
							className="button-v2 btn-send-mail"
							type="submit">
							Đăng ký
						</button>
					</form>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		token: state.auth.token,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ signup }, dispatch);
}

let SignupContainer = withRouter(
	connect(mapStateToProps, mapDispatchToProps)(Signup),
);
export default SignupContainer;
