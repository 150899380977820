import * as ActionTypes from "./type";
import axios from "axios";
import { notification } from "antd";
import { initAPI, setCookie, redirectToLogin } from "../../config/api";

export function listClassroom(data) {
	initAPI();

	return async (dispatch) => {
		await axios
			.post(`/classroom/list`, data)
			.then((res) => {
				dispatch({ type: "PAGING", page: data.page });
				const classrooms = res.data.data.records;
				const total = res.data.data.totalRecord;
				const limit = res.data.data.perPage;
				dispatch({
					type: ActionTypes.LIST_CLASSROOM,
					classrooms,
					total,
					limit,
				});
			})
			.catch(async (err) => {
				let msg = err.response.data.message;
				if (err && err.response && err.response.status === 401) {
					msg = err.response.data.message;
					await redirectToLogin(err.response.status);
				}
				notification.error({
					message: msg,
					placement: "topRight",
					top: 50,
					duration: 3,
				});
			});
	};
}

export function listChapterCategory(data) {
	initAPI();

	return async (dispatch) => {
		await axios
			.post(`/classroom/list-chapter-category`, data)
			.then((res) => {
				if (res.data.code === 200) {
					const chapterCategories = res.data.data;
					dispatch({ type: ActionTypes.LIST_CHAPTER_CATEGORY, chapterCategories });
				} else {
					notification.warning({
						message: res.data.message,
						placement: "bottomRight",
						bottom: 50,
						duration: 3,
					});
				}
			})
			.catch(async (err) => {
				let msg = err.response.data.message;
				if (err && err.response && err.response.status === 401) {
					msg = err.response.data.message;
					await redirectToLogin(err.response.status);
				}
				notification.error({
					message: msg,
					placement: "topRight",
					top: 50,
					duration: 3,
				});
			});
	};
}

export function showCategory(data) {
	initAPI();

	return async (dispatch) => {
		await axios
			.post(`/category/detail`, data)
			.then((res) => {
				if (res.data.code === 200) {
					const category = res.data.data;
					dispatch({ type: ActionTypes.SHOW_CATEGORY, category });
					setCookie('LTTD_view_video', 1, 1);
				} else {
					notification.warning({
						message: res.data.message,
						placement: "bottomRight",
						bottom: 50,
						duration: 3,
					});
					const category = {
						is_empty: true
					};
					dispatch({ type: ActionTypes.SHOW_CATEGORY, category });
				}
			})
			.catch(async (err) => {
				let msg = err.response.data.message;
				if (err && err.response && err.response.status === 401) {
					msg = err.response.data.message;
					await redirectToLogin(err.response.status);
				}

				setCookie('LTTD_view_video', 1, -1);
				notification.error({
					message: msg,
					placement: "topRight",
					top: 50,
					duration: 3,
				});
			});
	};
}

export function showClassroom(data) {
	initAPI();

	return async (dispatch) => {
		await axios
			.post(`/classroom/detail`, data)
			.then((res) => {
				if (res.data.code === 200) {
					const classroom = res.data.data;
					dispatch({ type: ActionTypes.SHOW_CLASSROOM, classroom });
				} else {
					notification.warning({
						message: res.data.message,
						placement: "bottomRight",
						bottom: 50,
						duration: 3,
					});
				}
			})
			.catch(async (err) => {
				let msg = err.response.data.message;
				if (err && err.response && err.response.status === 401) {
					msg = err.response.data.message;
					await redirectToLogin(err.response.status);
				}
				notification.error({
					message: msg,
					placement: "topRight",
					top: 50,
					duration: 3,
				});
			});
	};
}

export function classOverview(data) {
	initAPI();

	return async (dispatch) => {
		await axios
			.post(`classroom/overview`, data)
			.then((res) => {
				let overview = res.data.data;
				dispatch({ type: ActionTypes.OVERVIEW_CLASSROOM, overview });
			})
			.catch(async (err) => {
				let msg = err.response.data.message;
				if (err && err.response && err.response.status === 401) {
					msg = err.response.data.message;
					await redirectToLogin(err.response.status);
				}
				notification.error({
					message: msg,
					placement: "topRight",
					top: 50,
					duration: 3,
				});
			});
	};
}

export function classroomAccess(data) {
	initAPI();

	return async (dispatch) => {
		await axios
			.post(`classroom/access-by-code`, data)
			.then((res) => {
				const data = res.data.data;

				dispatch({ type: ActionTypes.CLASSROOM_ACCESS, data });
			})
			.catch(async (err) => {
				let msg = err.response.data.message;
				if (err && err.response && err.response.status === 401) {
					msg = err.response.data.message;
					await redirectToLogin(err.response.status);
				}
				notification.error({
					message: msg,
					placement: "topRight",
					top: 50,
					duration: 3,
				});
			});
	};
}

export function subjectShow(data) {
	return async (dispatch) => {
		await initAPI();
		await axios
			.post(`subject/detail`, data)
			.then((res) => {
				const data = res.data.data;

				dispatch({ type: ActionTypes.SUBJECT_SHOW, data });
			})
			.catch(async (err) => {
				let msg = err.response.data.message;
				if (err && err.response && err.response.status === 401) {
					msg = err.response.data.message;
					await redirectToLogin(err.response.status);
				}
				notification.error({
					message: msg,
					placement: "topRight",
					top: 50,
					duration: 3,
				});
			});
	};
}
