import React from "react";
import ReactDOM from "react-dom";
import "./App.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";

import { createStore, applyMiddleware } from "redux";
import allReducers from "./redux/reducer";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
	key: "app",
	storage,
	blacklist: [
		"testing",
		"profile",
		"classroom",
		"member",
		"video",
		"contactbook",
		"document",
		"setting"
	]
};

const persistedReducer = persistReducer(persistConfig, allReducers);

let store = createStore(persistedReducer, applyMiddleware(thunk));

//display console
store.subscribe(() => {
	return null;
});
ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</Provider>,
	document.getElementById("wrapper")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
