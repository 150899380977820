import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isUndefined } from "util";

import {
	listTesting,
	detailTesting,
	viewAnswer,
	resetViewAnswer,
} from "../redux/testing/action";
import StatusClassesTest from "./StatusClassesTest";
import Vimeo from '@u-wave/react-vimeo';
const base64 = require("base-64");

class Row extends Component {
	constructor(props) {
		super();
		this.state = {
			styleTrue: {
				background: "#0cba09",
				// border: "2px solid #1890ff"
			},
			styleFalse: {
				background: "#f52a2a",
				border: "3px solid #2196f3"
			},
			styleDefault: {
				background: "none",
			},
			answer: null,
			value: null,
			isOpen: false,
			vimeoID: null,
			bunny_video_id: null,
		};
	}

	componentDidMount = async () => {
		this.setState({
			answer: this.props.obj.answer,
			value: this.props.obj.value,
			// idOpen: this.props.obj._id,
		});
	};
	UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.answerData !== null) {
			let linkVideo = nextProps.answerData.video_link;
			let vimeo_id = null;
			let bunny_video_id = null;
			if (linkVideo && linkVideo.indexOf('vimeo') >= 0) {
				let data = linkVideo.split("/");
				vimeo_id = data[data.length - 1];
				this.setState({
					vimeoID: vimeo_id
				})
			}

			if (linkVideo && linkVideo.indexOf("mediadelivery.net") >= 0) {
				const v_id = linkVideo.split("/");
				bunny_video_id = v_id[v_id.length - 1];
				this.setState({
					bunny_video_id: bunny_video_id
				})
			}

			if (nextProps.answerData._id !== this.props.obj._id) {
				this.setState({
					isOpen: false,
				});
			}
		}
	}

	handlePlyrScript = async () => {
		await this.removeScript();
		await this.addScript();
	};

	addScript = () => {
		const plyrscript = document.createElement("script");
		plyrscript.innerText =
			"var player = new Plyr('#player', {youtube: { noCookie: true, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 0 }});";
		document.body.appendChild(plyrscript);
	};

	removeScript = () => {
		var nodeList = document.getElementsByTagName("script");
		for (var i = 0; i < nodeList.length; i++) {
			if (
				nodeList[i].innerText ===
				"var player = new Plyr('#player', {youtube: { noCookie: true, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 0 }});"
			) {
				nodeList[i].remove();
			}
		}
	};

	viewAnswer = async () => {
		await this.setState({
			isOpen: !this.state.isOpen,
		});

		if (this.state.isOpen === true) {
			await this.props.viewAnswer({ id: this.props.obj._id });
			this.handlePlyrScript();
		} else {
			await this.removeScript();
		}
	};

	renderButton = () => {
		const data = ["A", "B", "C", "D"];

		return data.map((item, i) => {
			var style = {};
			if (this.state.value !== null) {
				if (
					(this.state.value === item &&
						this.state.value === this.state.answer) ||
					this.state.answer === item
				) {
					style = this.state.styleTrue;
				} else if (
					this.state.value === item &&
					this.state.value !== this.state.answer
				) {
					style = this.state.styleFalse;
				}
			} else {
				style = this.state.styleDefault;
			}

			return (
				<div className="answer" key={i}>
					<label className="answer-checkmark">
						<input type="radio" />
						<span className="checkmark" style={style}></span>
					</label>
					<b>{item}</b>
				</div>
			);
		});
	};

	renderVideoBlock = () => {
		if (this.state.isOpen === true &&
			this.props.answerData !== null) {
			if (this.state.vimeoID && this.state.vimeoID !== null) {
				return <Vimeo
					vimeo-id={this.state.vimeoID}
					video={this.state.vimeoID}
					autoplay={true}
					speed={true}
					responsive={true}
					showTitle={false}
					showByline={false}
					showPortrait={false}
					className="block-vimeo-id"
				/>
			} else if (this.state.bunny_video_id && this.state.bunny_video_id !== null) {
				return <div className="text-center" id={'v-' + this.state.bunny_video_id} key={this.state.bunny_video_id}>
					<iframe allow="fullscreen;" title="video" width="100%" height="450" src={this.props.answerData.video_link + '?autoplay=true&loop=false&muted=false&preload=true'}></iframe>
				</div>
			} else {
				return <div
					id="player"
					data-plyr-provider="youtube"
					data-plyr-embed-id={base64.decode(
						this.props.answerData.v_id
					)}
				/>
			}
		}
	}

	render() {
		return (
			<div
				className="box-testexercise"
				id={`question` + (this.props.index + 1)}
			>
				<div className="box-question">
					<div className="question">
						<span>Câu {this.props.index + 1}:</span>
						<span>{this.props.obj.name}</span>
					</div>
					<div className="embed-question">
						<div
							dangerouslySetInnerHTML={{
								__html: this.props.obj.question,
							}}
							className="html-text"
						></div>
					</div>
				</div>
				<div className="box-answer">
					{this.renderButton()}
					{this.state.answer === this.state.value ? (
						<div className="answer answer-true">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={20}
								height={20}
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								strokeWidth={2}
								strokeLinecap="round"
								strokeLinejoin="round"
								className="feather feather-check mx-2"
							>
								<polyline points="20 6 9 17 4 12" />
							</svg>
						</div>
					) : (
						<div className="answer answer-false">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width={20}
								height={20}
								viewBox="0 0 24 24"
								fill="none"
								stroke="currentColor"
								strokeWidth={2}
								strokeLinecap="round"
								strokeLinejoin="round"
								className="feather feather-x mx-2"
							>
								<line x1={18} y1={6} x2={6} y2={18} />
								<line x1={6} y1={6} x2={18} y2={18} />
							</svg>
						</div>
					)}
				</div>
				{this.state.isOpen === true &&
					this.props.answerData !== null ? (
					<div>
						<h4 className="loigiai">Lời giải:</h4>
						{this.props.answerData.answer_content !== null ? (
							<div className="box-question">
								<div className="question">
									<span style={{ color: "white" }}>
										{this.props.index + 1}
									</span>
									<span>{this.props.obj.name}</span>
								</div>
								<div className="embed-question">
									<div
										dangerouslySetInnerHTML={{
											__html: this.props.answerData
												.answer_content,
										}}
										className="html-text"
									></div>
								</div>
							</div>
						) : (
							<div className="text-danger">
								Chưa cập nhật lời giải
							</div>
						)}
						<div className="embed-question">
							{this.renderVideoBlock()}
						</div>
					</div>
				) : null}

				<div className="text-right" style={{ padding: 20 }}>
					<button
						onClick={this.viewAnswer}
						className={
							this.state.isOpen === true
								? "button-v2 button-close"
								: "button-v2 btn-send-mail"
						}
						style={{ borderRadius: 20 }}
					>
						{this.state.isOpen === true ? "Đóng" : "Xem lời giải"}
					</button>
				</div>
			</div>
		);
	}
}

class RowImage extends Component {
	constructor(props) {
		super();
		this.state = {};
	}

	render() {
		return (
			<div className="box-testexercise">
				<div className="box-question">
					<div className="embed-question">
						<img alt="" src={this.props.obj} />
					</div>
				</div>
			</div>
		);
	}
}

class TestDetail extends Component {
	constructor(props) {
		super();
		this.state = {
			questions: [],
		};
	}

	fetchRows() {
		if (this.state.questions instanceof Array) {
			return this.state.questions.map((object, i) => {
				return (
					<Row
						obj={object}
						key={object._id}
						index={i}
						viewAnswer={this.props.viewAnswer}
						resetViewAnswer={this.props.resetViewAnswer}
						answerData={this.props.answerData}
					/>
				);
			});
		}
	}

	fetchRowsImage() {
		if (this.state.answer_files instanceof Array) {
			return this.state.answer_files.map((object, i) => {
				return <RowImage obj={object} key={i} index={i} />;
			});
		}
	}

	getData = (pageNumber = 1) => {
		const data = {
			page: pageNumber,
			limit: this.state.limit,
		};
		if (this.state.keyword != null) {
			data["keyword"] = this.state.keyword;
		}
		if (this.state.tags != null) {
			data["tags"] = this.state.tags;
		}
		return data;
	};

	async componentDidMount() {
		const data = {
			id: this.props.match.params.id,
		};
		await this.props.detailTesting(data);
		if (this.props.testing) {
			const { testing, exam } = this.props.testing;
			await this.setState({
				exam: exam,
				testing: testing,
				exam_type: exam.type,
				doc_link: exam.doc_link,
				user_id: testing.user_id,
				exam_id: testing.exam_id,
				exam_name: exam.name,
				created_at: testing.created_at,
				exam_code: exam.code,
				questions: this.props.questions,
				time: exam.time,
				answer_files: testing.answer_files,
			});
		}
	}

	render() {
		// let total = null;
		// let point = null;
		if (
			this.props.testing != null &&
			!isUndefined(this.props.testing.testing)
		) {
			// var num_right = this.props.testing.testing.num_right;
			// var num_wrong = this.props.testing.testing.num_wrong;
			// total = num_right + num_wrong;
			/// point = this.props.testing.testing.point;
		} else {
			// num_right = 0;
			// num_wrong = 0;
			// total = num_right + num_wrong;
			// point = 0;
		}
		return (
			<>
				<div className="col-center">
					<div id="testexercise">
						<>
							<div className="box-question-test">
								<div id="overview">
									<div className="top-overview">
										<div className="t-overview-left w-100 m-0">
											<h2>
												Bài thi:{" "}
												<span
													style={{
														color: "#FFDE32",
														fontSize: "35px",
													}}
												>
													{!isUndefined(
														this.state.exam_name
													)
														? this.state.exam_name
														: ""}
												</span>
											</h2>
											<div>
												<div className="col-6">
													<p>
														Mã đề:{" "}
														<span>
															{!isUndefined(
																this.state
																	.exam_code
															)
																? this.state
																	.exam_code
																: ""}
														</span>
													</p>
												</div>
												<div className="col-6">
													<p>
														Thời gian làm bài:{" "}
														<span>
															{this.state.time !=
																null
																? this.state
																	.time +
																" phút"
																: "10 phút"}
														</span>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								{this.state.exam_type === "TU_LUAN" ? (
									<>
										<div
											style={{
												width: "100%",
												marginTop: 15,
											}}
										>
											<embed
												src={
													this.state.exam.doc_link !==
														"undefinded"
														? this.state.exam
															.doc_link
														: null
												}
												type="application/pdf"
												width="100%"
												height="600px"
											/>
										</div>
										{this.state.answer_files.length !==
											0 ? (
											<>
												<h4 style={{ marginTop: 10 }}>
													Bài làm của bạn:
												</h4>
												{this.fetchRowsImage()}
											</>
										) : (
											"Không có dữ liệu bài làm"
										)}
									</>
								) : (
									this.fetchRows()
								)}
							</div>
						</>
					</div>
				</div>
				<StatusClassesTest
					data={
						this.props.testing !== null
							? this.props.testing.top_testings
							: []
					}
				/>
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		testing: state.testing.testing,
		questions: state.testing.questions,
		qtyTrue: state.testing.qtyTrue,
		total: state.testing.total,
		answerData: state.testing.answerData,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{ listTesting, detailTesting, viewAnswer, resetViewAnswer },
		dispatch
	);
}

connect(mapStateToProps, mapDispatchToProps)(Row);

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(TestDetail)
);
