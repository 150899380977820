import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { login } from "../redux/auth/action";

class Login extends Component {
	constructor(props) {
		super();
		this.state = {
			email: "",
			password: ""
		};
	}
	componentDidMount() {
		localStorage.clear();
		localStorage.removeItem("persist:app");

		const { token, updateToken } = this.context;
		this.setState({
			token,
			updateToken
		});
	}
	handleChange = e => {
		var name = e.target.name;
		var value = e.target.value;
		this.setState({
			[name]: value
		});
	};

	handleSubmit = async e => {
		e.preventDefault();
		const data = {
			email: this.state.email,
			password: this.state.password
		};
		await this.props.login(data);
		if (this.props.token !== null) {
			this.props.history.push("/app/classrooms");
		}
	};

	render() {
		return (
			<div id="box-login">
				<div className="login-left">
					<img className="shape-2" src="https://cdn.luyenthitiendat.vn/assets/v2/anhdaidien.svg" alt="Dai Co Viet" />
					<a href="/" className="logo">
						<img src="https://cdn.luyenthitiendat.vn/assets/v2/logo-dcv.svg" alt="Dai Co Viet" />
					</a>
				</div>
				<div className="login-right">
					<div className="login-heading">
						<h2>Đăng nhập</h2>
						<div className="registration">
							<span>Chưa có tài khoản?</span>
							<Link className="signup" to="/signup">Đăng ký</Link>
						</div>
					</div>
					<form onSubmit={this.handleSubmit}>
						<div className="formcontrol">
							<div className="controlinput">
								<input
									className="input-v2"
									type="text"
									placeholder="Mã học sinh hoặc số điện thoại"
									value={this.state.name}
									onChange={this.handleChange}
									name="email"
								/>
							</div>
						</div>
						<div className="formcontrol">
							<div className="controlinput">
								<input
									className="input-v2"
									type="password"
									placeholder="Mật khẩu"
									onChange={this.handleChange}
									name="password"
									value={this.state.password}
								/>
							</div>
						</div>
						<div className="text-right"><Link className="forgot_password" to="/forget-password">Quên mật khẩu</Link></div>
						<button
							className="btn-send-mail button-v2"
							type="submit"
						>
							Đăng nhập
						</button>
					</form>
				</div>

			</div>
		);
	}
}

function mapStateToProps(state, ownProps) {
	return {
		token: state.auth.token
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ login }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
