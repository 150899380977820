import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Moment from "moment";
import { listMessage } from '../../redux/message/action';

class Row extends Component {
    constructor(props) {
        super();
        this.state = {};
    }

    render() {
        return (
            <tr>
                <td width={50} style={{ textAlign: 'center' }}>
                    {this.props.index + 1}
                </td>
                <td>
                    <Link to={'/app/message/' + this.props.obj._id + '/view'}>
                        {this.props.obj.name}
                    </Link>
                </td>
                <td>
                    {this.props.obj.created_at &&
                        Moment(this.props.obj.created_at).format(
                            "DD/MM/YYYY HH:mm"
                        )}
                </td>
            </tr>
        );
    }
}

class Message extends Component {
    constructor(props) {
        super();
        this.state = {
            keyword: '',
        };
    }

    fetchRows() {
        if (this.props.messages instanceof Array) {
            return this.props.messages.map((object, i) => {
                return (
                    <Row
                        obj={object}
                        key={object._id}
                        index={i}
                        {...this.props}
                    />
                );
            });
        }
    }

    onChange = e => {
        var name = e.target.name;
        var value = e.target.value;
        this.setState({
            [name]: value,
        });
    };

    getData = (pageNumber = 1) => {
        const data = {
            page: pageNumber,
            limit: this.state.limit,
        };
        if (this.state.keyword != null) {
            data['keyword'] = this.state.keyword;
        }
        return data;
    };

    async componentDidMount() {
        await this.props.listMessage(this.getData());
        if (this.props.limit) {
            await this.setState({
                limit: this.props.limit,
            });
        }
    }

    onSubmit = e => {
        e.preventDefault();
        this.props.listMessage(this.getData());
    };

    render() {
        return (
            <>
            <div id="header-home">
					<div className="page-title">
						<h2>Thông báo</h2>
					</div>
					<div className="page-tools">
						<div className="search-class">
							<form onSubmit={this.onSubmit}>
								<div className="form-group">
									<input
										type="text"
										placeholder="Tìm kiếm..."
										name="keyword"
										className="input-v2"
										onChange={this.onChange}
									/>
									<i
										className="fa fa-search"
										aria-hidden="true"></i>
								</div>
							</form>
						</div>
					</div>
				</div>
                <div id="class-list" className="col-center page-container">
                    <div className="table-list">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }}>STT</th>
                                    <th>Tiêu đề</th>
                                    <th>Thời gian</th>
                                </tr>
                            </thead>
                            <tbody>{this.fetchRows()}</tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        messages: state.message.messages,
        limit: state.message.limit,
        page: state.message.page,
        total: state.message.total,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ listMessage }, dispatch);
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(Message),
);
