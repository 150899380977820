import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { listTesting, listExam, examPreview } from "../redux/testing/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class DocPreview extends Component {
	constructor(props) {
		super();
		this.state = {};
	}

	renderPdfView = () => {
		return (
			<embed
				src={
					this.props.dataExamPreview !== null
						? this.props.dataExamPreview.doc_link
						: null
				}
				type="application/pdf"
				width="100%"
				height="600px"
			/>
		);
	};

	renderGoogleDriveView = () => {
		if (this.props.dataExamPreview !== null) {
			let docConvert = null;
			const doc_link = this.props.dataExamPreview.doc_link;
			if (doc_link) {
				docConvert = doc_link.replace("/view", "/preview");
				return <iframe title="Video" src={docConvert} width="640" height="600"></iframe>;
			}
		}
	};

	render() {		
		if (this.props.dataExamPreview !== null) {
			return (
				<>
					{this.props.dataExamPreview.doc_type === "PDF"
						? this.renderPdfView()
						: this.renderGoogleDriveView()}
				</>
			);
		} else {
			return null;
		}
	}
}

function mapStateToProps(state) {
	return {
		dataExamPreview: state.testing.examPreview
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ listTesting, listExam, examPreview }, dispatch);
}

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(DocPreview)
);
