import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import Moment from "moment";
import { Modal, notification } from "antd";
import { getCookie, setCookie } from "../config/api";
import {
	listTesting,
	listExam,
	examPreview,
	showTesting,
} from "../redux/testing/action";
import {
	listChapterCategory,
	showCategory
} from "../redux/classroom/action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Tabs from "./tabs/Tabs";
import Teacher from "../../src/teacher.png";

import $ from "jquery";
import DocPreview from "./DocPreview";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Accordion, Card } from 'react-bootstrap';
import * as Icon from 'react-feather';
import Vimeo from '@u-wave/react-vimeo';

const arrayExamTestingID = [];

class Row extends Component {
	constructor(props) {
		super();
		this.state = {
			check: false,
		};
	}

	handleVideo = async (link) => {
		await this.props.showModalVideo(link);
	};

	render() {
		let videoLink = null;
		let docLink = null;
		if (this.props.testingExamData && this.props.testingExamData.length > 0) {
			for (let i = 0; i < this.props.testingExamData.length; i++) {
				if (this.props.obj.exam.id === this.props.testingExamData[i]._id) {
					videoLink = this.props.testingExamData[i].video_link;
					docLink = this.props.testingExamData[i].doc_link;
					break;
				}
			}
		}

		return (
			<tr>
				<td className="text-center">{this.props.index + 1}</td>
				<td>
					{this.props.obj.exam.code +
						" - " +
						this.props.obj.exam.name}
				</td>
				<td>{Math.round(this.props.obj.point * 10) / 10}</td>
				<td>
					{this.props.obj.updated_at &&
						Moment(this.props.obj.updated_at).format(
							"DD/MM/YYYY HH:mm:ss"
						)}
				</td>
				<td className="text-right">
					{
						videoLink ?
							<span title="Xem bài giảng">
								<Icon.PlayCircle
									color={'#abadaf'}
									onClick={() => this.handleVideo(videoLink)}
									style={{ width: '40px' }}
									className="lessson-icon pointer play-video-icon" />
							</span> : null
					}

					<Link
						to={`${this.props.match.path}/` + this.props.obj._id}
						className="preview" title="Xem lại bài"
					>

						<Icon.Edit color={'#abadaf'} onClick={this.handleOpen} style={{ width: '40px' }} className="pointer lessson-icon play-edit-icon" />
					</Link>
					{docLink ? <span title="Tài liệu"><a href={docLink} target={'_blank'}><Icon.Paperclip color={'#abadaf'} style={{ width: '40px' }} className="lessson-icon pointer play-download-icon" /></a></span> : null}
				</td>
			</tr>
		);
	}
}

class RowExam extends Component {
	constructor(props) {
		super();
		this.state = {
			check: false,
			isFinished: false,
			video_id: null,
			vimeo_id: null,
		};
	}

	handleOpenPreview = async () => {
		await this.props.showModal(this.props.obj.exam_id);
	};

	handleVideo = async (link) => {
		await this.props.showModalVideo(link);
	};

	handleOpen = async () => {
		if (this.props.obj.status === "DONE") {
			await this.props.history.push(
				`${this.props.match.path}/` +
				this.props.obj.exam.id +
				"/" +
				this.props.classroom_id +
				"/view-again"
			);
		} else {
			var timeNow = new Date();
			var timeFinished = new Date(this.props.obj.finished_at);

			if (this.props.obj.is_fixed_time === true) {
				if (timeFinished - timeNow < 0) {
					// thong bao het gio lam bai
					notification.warning({
						message: "Đã hết giờ vào làm bài",
						placement: "topRight",
						bottom: 50,
						duration: 3,
					});
				} else {
					await this.props.showTesting({
						id: this.props.obj.exam.id,
						classroom_id: this.props.classroom_id,
					});

					if (
						this.props.errMessage !== "" &&
						this.props.errMessage !== null
					) {
						console.log("loi");
					} else {
						await this.props.history.push(
							"/app/test/" +
							this.props.obj.exam.id +
							"/" +
							this.props.classroom_id
						);
					}
				}
			} else {
				await this.props.showTesting({
					id: this.props.obj.exam.id,
					classroom_id: this.props.classroom_id,
				});

				await this.props.history.push(
					"/app/test/" +
					this.props.obj.exam.id +
					"/" +
					this.props.classroom_id
				);
			}
		}
	};

	checkDate = () => {
		if (this.props.obj.is_fixed_time === false) {
			if (
				this.props.obj.started_at !== null &&
				this.props.obj.finished_at === null
			) {
				return (
					"Từ " +
					Moment(this.props.obj.started_at).format("DD/MM H:m") +
					")"
				);
			} else if (
				this.props.obj.started_at !== null &&
				this.props.obj.finished_at !== null
			) {
				return (
					"Từ " +
					Moment(this.props.obj.started_at).format("DD/MM H:m") +
					" đến " +
					Moment(this.props.obj.finished_at).format("DD/MM H:m") +
					")"
				);
			} else {
				return "";
			}
		} else {
			if (
				this.props.obj.started_at !== null &&
				this.props.obj.finished_at === null
			) {
				return (
					"Từ " +
					Moment(this.props.obj.started_at).format("DD/MM H:m")
				);
			} else if (
				this.props.obj.started_at !== null &&
				this.props.obj.finished_at !== null
			) {
				return (
					"Từ " +
					Moment(this.props.obj.started_at).format("DD/MM H:m") +
					" đến " +
					Moment(this.props.obj.finished_at).format("DD/MM H:m")
				);
			} else {
				return null;
			}
		}
	};

	render() {
		let videoLink = null;
		let docLink = null;
		if (this.props.examData && this.props.examData.length > 0) {
			for (let i = 0; i < this.props.examData.length; i++) {
				if (this.props.obj.exam.id === this.props.examData[i]._id) {
					videoLink = this.props.examData[i].video_link;
					docLink = this.props.examData[i].doc_link;
					break;
				}
			}
		}

		return (
			<tr className="tr-exam">
				<td className="text-center">{this.props.index + 1}</td>
				<td>
					{this.props.obj.exam.code +
						" - " +
						this.props.obj.exam.name}
				</td>
				<td>{this.props.obj.subject.name}</td>
				<td>
					{this.props.obj.type === "TRAC_NGHIEM"
						? "Trắc nghiệm"
						: "Tự luận"}
				</td>
				<td>
					{this.checkDate()}

					{this.props.obj.status === "DONE" ? (
						this.props.obj.is_fixed_time === false ? (
							<span className="text-danger">
								{" "}
								(Tự do - Đã kết thúc)
							</span>
						) : (
							<span className="text-danger"> (Đã kết thúc)</span>
						)
					) : this.props.obj.is_fixed_time === false ? (
						<span className="text-danger"> (Tự do - Chưa thi)</span>
					) : (
						<span className="text-danger"> (Chưa thi)</span>
					)}
				</td>
				<td className="text-right">
					{
						videoLink ?
							<span title="Xem bài giảng">
								<Icon.PlayCircle
									color={'#abadaf'}
									onClick={() => this.handleVideo(videoLink)}
									style={{ width: '40px' }}
									className="lessson-icon pointer play-video-icon" />
							</span> : null
					}


					{
						this.props.obj.status === "DONE" ?
							<span title="Xem lại bài" onClick={this.handleOpen}><Icon.Edit color={'#abadaf'} onClick={this.handleOpen} style={{ width: '40px' }} className="pointer lessson-icon play-edit-icon" /></span> :
							<span title="Làm bài" onClick={this.handleOpen}><Icon.Edit color={'#abadaf'} onClick={this.handleOpen} style={{ width: '40px' }} className="pointer lessson-icon play-edit-icon" /></span>}
					{docLink ? <span title="Tài liệu"><a onClick={this.handleOpen} href={docLink} target={'_blank'}><Icon.Paperclip color={'#abadaf'} style={{ width: '40px' }} className="lessson-icon pointer play-download-icon" /></a></span> : null}
				</td>
			</tr>
		);
	}
}

class ClassTesting extends Component {
	constructor(props) {
		super();
		this.state = {
			data: [],
			limit: "",
			ModalText: "Bạn đã sẵn sàng làm bài chưa ?",
			visible: false,
			visibleCategory: false,
			confirmLoading: false,
			ModalTextNotify: "Bạn đã sẵn sàng làm bài kiểm tra chưa ?",
			visibleNotify: false,
			confirmLoadingNotify: false,
			messageCategoryExam: '',
			isShowmodalVideo: false,
			videoID: null,
			vimeoID: null,

			arrayExamTestingID: []
		};
	}

	getData = (pageNumber = 1) => {
		const data = {
			classroom_id: this.props.class_id,
			page: pageNumber,
			limit: this.state.limit,
			status: "DONE",
		};
		if (this.state.keyword != null) {
			data["keyword"] = this.state.keyword;
		}
		return data;
	};

	getDataExam = (pageNumber = 1) => {
		const data = {
			classroom_id: this.props.class_id,
			page: pageNumber,
			limit: this.state.limit,
			status: "PENDING",
			not_in_ids: arrayExamTestingID
		};
		if (this.state.keyword != null) {
			data["keyword"] = this.state.keyword;
		}
		return data;
	};

	async componentDidMount() {
		setCookie('LTTD_view_video', 1, -2);
		await this.props.listChapterCategory({ classroom_id: this.props.class_id });
		await this.props.listTesting(this.getData());
		if (this.props.testings != null) {
			if (this.props.testings instanceof Array) {
				for (let i = 0; i < this.props.testings.length; i++) {
					if (arrayExamTestingID.indexOf(this.props.testings[i].exam.id) < 0) {
						arrayExamTestingID.push(this.props.testings[i].exam.id);
						this.state.arrayExamTestingID.push(this.props.testings[i].exam.id);
					}
				}

			}
		}
		await this.props.listExam(this.getDataExam());
		if (this.props.limit) {
			await this.setState({
				limit: this.props.limit,
			});
		}

		$(".modal-video").removeClass("show-modal");
	}

	fetchRows() {
		if (this.props.testings != null) {
			if (this.props.testings instanceof Array) {
				return this.props.testings.map((object, i) => {
					return (
						<Row
							obj={object}
							key={object._id}
							index={i}
							{...this.props}
							showModal={this.showModal}
							showModalVideo={this.showModalVideo}
						/>
					);
				});
			}
		}
	}

	fetchRowsExam() {
		if (this.props.exams != null) {
			if (this.props.exams instanceof Array) {
				// eslint-disable-next-line array-callback-return
				return this.props.exams.map((object, i) => {
					if (arrayExamTestingID.indexOf(object.exam_id) < 0) {
						return (
							<RowExam
								obj={object}
								key={object._id}
								index={i}
								{...this.props}
								showModal={this.showModal}
								showModalVideo={this.showModalVideo}
								handleOpen={this.handleOpen}
								classroom_id={this.props.class_id}
								{...this.props}
							/>
						);
					}
				});
			}
		}
	}

	showModal = async (id) => {
		await this.setState({
			visible: true,
		});
		var data = {
			classroom_id: this.props.class_id,
			exam_id: id,
		};
		await this.props.examPreview(data);
	};

	showModalVideo = async (url) => {
		let { videoID, vimeoID, bunny_video_id } = this.state;
		if (!url) {
			return null;
		}

		if (url && url.indexOf("youtube") >= 0) {
			const v_id = url.split("?v=");
			videoID = v_id[1] ? v_id[1] : null;
		}

		if (url && url.indexOf("vimeo") >= 0) {
			videoID = null;
		}

		if (!videoID) {
			let data = url.split("/");
			vimeoID = data[data.length - 1];
		}
		if (url && url.indexOf("mediadelivery.net") >= 0) {
			const v_id = url.split("/");
			bunny_video_id = v_id[v_id.length - 1];
		}

		this.setState({
			videoID,
			vimeoID,
			bunny_video_id,
			isShowmodalVideo: true
		})
	}

	handleOpen = async (id) => {
		await this.setState({
			visibleNotify: true,
		});
		await this.props.showTesting({
			id: id,
			classroom_id: this.props.class_id,
		});
	};

	handleClick = async () => {
		var timeNow = new Date();
		var timeFinished = new Date(this.props.obj.finished_at);

		if (this.props.obj.is_fixed_time === true) {
			if (timeFinished - timeNow < 0) {
				// thong bao het gio lam bai
				await this.setState({
					isFinished: true,
				});
				this.AlertPro.close();
				this.AlertProErr.open();
			} else {
				await this.props.showTesting({
					id: this.props.obj.exam.id,
					classroom_id: this.props.screenProps.classroom_id,
				});
				this.AlertPro.close();

				if (this.props.errMessage !== "") {
					this.AlertProErr.open();
				} else {
					await this.props.navigation.navigate("ClassExamStack", {
						test_id: this.props.obj.exam.id,
						classroom_id: this.props.screenProps.classroom_id,
					});
				}
			}
		} else {
			await this.props.showTesting({
				id: this.props.obj.exam.id,
				classroom_id: this.props.screenProps.classroom_id,
			});
			this.AlertPro.close();
			await this.props.navigation.navigate("ClassExamStack", {
				test_id: this.props.obj.exam.id,
				classroom_id: this.props.screenProps.classroom_id,
			});
		}
	};

	handleOk = async () => {
		this.setState({
			visible: false,
		});
	};

	handleCancel = () => {
		this.setState({
			visible: false,
			isShowmodalVideo: false,
			visibleCategory: false
		});
	};

	handlePlyrScript = async () => {
		await this.removeScript();
		await this.addScript();
	};

	addScript = () => {
		const plyrscript = document.createElement("script");
		plyrscript.innerText =
			"var player = new Plyr('#player', {youtube: { noCookie: true, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 0 }});";
		document.body.appendChild(plyrscript);
	};

	async UNSAFE_componentWillReceiveProps(nextProps) {
		if (nextProps.dataExamPreview !== this.props.dataExamPreview) {
			await this.handlePlyrScript();
		}
	}

	removeScript = () => {
		var nodeList = document.getElementsByTagName("script");

		for (var i = 0; i < nodeList.length; i++) {
			if (
				nodeList[i].innerText ===
				"var player = new Plyr('#player', {youtube: { noCookie: true, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 0 }});"
			) {
				nodeList[i].remove();
			}
		}
	};

	getIdVideo = (url) => {
		let video_id = null;
		let vimeo_id = null;
		let bunny_video_id = null;
		if (!url)
			return null;

		if (url && url.indexOf("youtube") >= 0) {
			const v_id = url.split("?v=");
			video_id = v_id[1] ? v_id[1] : null;
		}

		if (url && url.indexOf("vimeo") >= 0) {
			video_id = null;
			const v_id = url.split("/");
			vimeo_id = v_id[v_id.length - 1];
		}

		if (url && url.indexOf("mediadelivery.net") >= 0) {
			video_id = null;
			const v_id = url.split("/");
			bunny_video_id = v_id[v_id.length - 1];
		}

		const data = {
			video_id,
			vimeo_id,
			bunny_video_id
		};
		return data;
	};

	renderVideoList = () => {
		let _html = [];
		let { videoID, vimeoID, bunny_video_id } = this.state;
		if (videoID && videoID !== null) {
			var _url = 'https://www.youtube.com/embed/' + videoID;
			_html.push(
				<div className="text-center" id={'v-' + videoID} key={videoID}>
					<iframe allow="fullscreen;" title="video" width="100%" height="450" src={_url}></iframe>
				</div>
			);
		}
		
		if (vimeoID) {
			_html.push(
				<Vimeo
					vimeo-id={vimeoID}
					video={vimeoID}
					key={vimeoID}
					autoplay={true}
					speed={true}
					responsive={true}
					showTitle={false}
					showByline={false}
					showPortrait={false}
					className="block-vimeo-id"
				/>
			);
		}

		if (bunny_video_id) {
			_html.push(
				<div className="text-center" id={'v-' + bunny_video_id} key={bunny_video_id}>
					<iframe allow="fullscreen;" title="video" width="100%" height="450" src={_url + '?autoplay=true&loop=false&muted=false&preload=true'}></iframe>
				</div>
			);
		}

		return _html;
	}

	renderVideo = () => {
		let _html = [];
		if (this.state.visibleCategory) {
			let data = this.getIdVideo(this.props.category.video_link);
			if (data && data.video_id !== null) {
				var _url = 'https://www.youtube.com/embed/' + data.video_id;
				_html.push(
					<div className="text-center" id={'v-' + data.video_id} key={data.video_id}>
						<iframe allow="fullscreen;" title="video" width="650" height="390" src={_url}></iframe>
					</div>
				);
				return _html;
			}
			
			if (data && data.bunny_video_id) {
				_html.push(
					<div className="text-center" id={'v-' + data.bunny_video_id} key={data.bunny_video_id}>
						<iframe allow="fullscreen;" title="video" width="100%" height="450" src={this.props.category.video_link.replace('/play/', '/embed/') + '?autoplay=true&loop=false&muted=false&preload=true'}></iframe>
					</div>
				);
				return _html;
			}

		if (data && data.vimeo_id) {
				_html.push(
					<div className="text-center block-vimeo" id={'vmID-' + data.vimeo_id} key={data.vimeo_id}>
						<Vimeo
							vimeo-id={data.vimeo_id}
							video={data.vimeo_id}
							autoplay={true}
							speed={true}
							responsive={true}
							showTitle={false}
							showByline={false}
							showPortrait={false}
							className="block-vimeo-id"
						/>
					</div>
				);
				return _html;
			}
		}
		
		if (this.props.dataExamPreview !== null) {
			let arrayURL = [];
			var url = this.props.dataExamPreview.video_link;
			if (url)
				arrayURL = url.split('|');
			_html = [];

			if (arrayURL.length > 0) {
				for (let i = 0; i < arrayURL.length; i++) {
					let id = this.getIdVideo(arrayURL[i]);

					if (id !== null) {
						_html.push(
							<div className="widget" id={'v-' + i} key={i}>
								<div className="widget-center-preview">
									<p>
										<b>
											{" "}
											Video hướng dẫn đề thi{" "}
											{this.props.dataExamPreview.name !==
												null
												? this.props.dataExamPreview.name
												: null}{" "}
											-{" "}
											{this.props.dataExamPreview.subject !==
												null
												? this.props.dataExamPreview.subject
													.name
												: null}
										</b>
									</p>
									<div
										style={{ width: "100%" }}
										id="player"
										data-plyr-provider="youtube"
										data-plyr-embed-id={id}
									/>
								</div>
							</div>
						);
					}
				}
			}
			return _html;
		}
	};

	handleCancelNotify = () => {
		this.setState({ visibleNotify: !this.state.visibleNotify });
	};

	handleOpenCategoryPreview = async (category) => {
		this.setState({ visibleCategory: false });
		await this.props.showCategory({ id: category.id, classroom_id: this.props.class_id, action: 'VIEW_VIDEO' });

		if (this.props.category) {
			this.setState({ visibleCategory: true });
			return;
		}
	};

	handleOpenExam = async (_category, type = false) => {
		this.setState({ visibleCategory: false });
		let category = _category;
		let categoryID = category._id;
		if (type)
			categoryID = category.category.id;

		if (!categoryID) {
			this.setState({ messageCategoryExam: 'Bài tập chưa được cập nhật!' });
			return;
		}
		await this.props.showCategory({ id: categoryID, classroom_id: this.props.class_id, action: 'OPEN_EXAM' });
		if (this.props.category) {
			if (this.props.category.exam && this.props.category.exam.id) {
				if (this.state.arrayExamTestingID.indexOf(this.props.category.exam.id) < 0) {
					// Chua lam
					await this.props.history.push(
						`/app/test/` + this.props.category.exam.id + '/' + this.props.class_id
					);
				} else {
					// Dalam
					await this.props.history.push(
						`${this.props.match.path}/` +
						this.props.category.exam.id
					);
				}
			}
		}

		if (this.props.category) {
			this.setState({ visibleCategory: true });
			return;
		} else {
			this.setState({ visibleCategory: false });
			return;
		}
	};

	handleDownloadDoc = async (category) => {
		await this.props.showCategory({ id: category.id, classroom_id: this.props.class_id, action: 'DOWNLOAD_DOC' });
		if (this.props.category) {
			if (this.props.category.doc_link) {
				window.open(this.props.category.doc_link, '_blank');
			}
		}
	};


	fetchCategoryByChapter(chapter) {
		if (chapter.category && chapter.category.length > 0) {
			return chapter.category.map((object, i) => {
				let isAllow = false;
				const _date1 = new Date(object.publish_at).getTime();
				const _date2 = new Date().getTime();
				if (_date1 <= _date2)
					isAllow = true;
				return (
					<div key={i} className="category-item">
						<div className="category-name">
							{
								isAllow
									? <span style={{ cursor: 'pointer' }} onClick={() => this.handleOpenCategoryPreview(object.category)}>{object.category.name}</span>
									: <span>{object.category.name}</span>
							}
						</div>
						<div className="publish_at">{object.publish_at ? 'Ngày phát hành: ' + Moment(object.publish_at).format('DD/MM/YYYY') : 'Chưa phát hành'}</div>
						{isAllow ? <div className="category-actions">
							{
								object.video_link ?
									<span title="Xem bài giảng">
										<Icon.PlayCircle
											color={'#abadaf'}
											onClick={() => this.handleOpenCategoryPreview(object.category)}
											style={{ width: '40px' }}
											className="lessson-icon pointer play-video-icon" />
									</span> : null
							}
							{(object.exam && object.exam.id) ? <span title="Bài tập"><Icon.Edit color={'#abadaf'} onClick={() => this.handleOpenExam(object, true)} style={{ width: '40px' }} className="pointer lessson-icon play-edit-icon" /></span> : null}
							{object.doc_link ? <a onClick={(e) => this.handleDownloadDoc(object.category)} rel="noopener noreferrer" target="_blank" title="Tài liệu"><Icon.Paperclip color={'#abadaf'} style={{ width: '40px' }} className="lessson-icon pointer play-download-icon" /></a> : null}
						</div> : ''}
					</div>
				);
			});
		}
	}

	accordionChange(id) {
		var obj = document.getElementById("card_item_" + id);
		if (obj.classList.contains("card_category_show")) {
			obj.classList.remove("card_category_show");
			document.getElementById("lesson_icon_up_" + id).style.display = "block";
			document.getElementById("lesson_icon_down_" + id).style.display = "none";
		} else {
			obj.classList.add("card_category_show");
			document.getElementById("lesson_icon_up_" + id).style.display = "none";
			document.getElementById("lesson_icon_down_" + id).style.display = "block";
		}
		return;
	}

	fetchRowChapters() {
		if (this.props.chapterCategories instanceof Array) {
			return this.props.chapterCategories.map((object, i) => {
				const _items = this.fetchCategoryByChapter(object);
				return (<Card key={object.chapter.id}>
					<Accordion.Toggle as={Card.Header} className={object.chapter.id} eventKey={object.chapter.id} onClick={e => this.accordionChange(object.chapter.id)}>
						{object.chapter.name}
						<span id={"lesson_icon_down_" + object.chapter.id} className={"lesson-arrcodion-icon icon-down"}><Icon.ChevronDown color={"#ffffff"} /></span>
						<span id={"lesson_icon_up_" + object.chapter.id} className={"lesson-arrcodion-icon icon-up"}><Icon.ChevronUp color={"#ffffff"} /></span>
					</Accordion.Toggle>
					<Card.Body id={'card_item_' + object.chapter.id} className="card_category_show">{_items}</Card.Body>
				</Card>);
			});
		}
	}

	render() {
		const { visibleNotify, confirmLoadingNotify } = this.state;
		const urlParams = new URLSearchParams(window.location.search);
		const myParam = urlParams.get("tab");
		const { visible, visibleCategory } = this.state;

		return (
			<>
				<div className="col-center page-container">
					<div id="mytable">
						<Tabs active={myParam != null ? 1 : 0}>
							<div label="Bài học" className="tab-content">
								<div id="table-test-list">
									<div className="table-list">
										<Accordion defaultActiveKey="0">
											{this.fetchRowChapters()}
										</Accordion>
									</div>
								</div>
							</div>
							<div label="Bài chưa làm" className="tab-content">
								<div id="table-test">
									<div className="table-list">
										<table className="table table-striped">
											<thead>
												<tr>
													<th
														style={{
															textAlign: "center",
															width: "50px",
														}}
													>
														STT
													</th>
													<th>Tên bài thi</th>
													<th>Môn</th>
													<th>Loại đề thi</th>
													<th>Thời gian làm bài</th>
													<th
														className="text-right"
														style={{ minWidth: 90 }}
													>
														Hành động
													</th>
												</tr>
											</thead>

											<tbody>{this.fetchRowsExam()}</tbody>
										</table>
									</div>
								</div>
							</div>

							<div label="Bài đã làm">
								<div id="table-test">
									<div className="table-list">
										<table className="table table-striped">
											<thead>
												<tr>
													<th
														style={{
															textAlign: "center",
															width: "50px",
														}}
													>
														STT
													</th>
													<th>Mã bài thi</th>
													<th>Điểm</th>
													<th>Ngày gửi bài</th>
													<th className="text-right">Hành động</th>
												</tr>
											</thead>
											<tbody>{this.fetchRows()}</tbody>
										</table>
									</div>
								</div>
							</div>
						</Tabs>
						{getCookie('LTTD_view_video') && this.props.category ?
							<Modal
								footer={null}
								width={850}
								bodyStyle={{
									minHeight: 360,
									width: 850,
								}}
								closable={true}
								visible={visibleCategory}
								onOk={this.handleOk}
								onCancel={this.handleCancel}
								okText="Bắt đầu ngay"
								cancelText="Làm lại"
								cancelButtonProps={{
									style: {
										display: "none",
									},
								}}
							>
								<h3 className="text-center">{this.props.category ? this.props.category.name : ''}</h3>

								{this.renderVideo()}
								{this.props.category.num_view > 0 ? 
								<div className="text-center action_list" style={{ marginTop: 40 }}>Bạn còn: <b>{this.props.category.num_view}</b> lượt xem</div> : 
								<div className="text-center action_list" style={{ marginTop: 40 }}>Bạn đã hết lượt xem ({this.props.category.num_view})</div>
								}
								<div className="text-center action_list" style={{ marginTop: 40 }}>
									{this.props.category.doc_link ? <a href={this.props.category.doc_link}
										className="btn-3 _action"
									>Tải tài liệu</a> : null}

									{(this.props.category.exam && this.props.category.exam.id) ? <button
										type="button"
										className="btn-3 _action" onClick={() => this.handleOpenExam(this.props.category, false)}
									>Bài tập</button> : null}
								</div>
								<div style={{ color: 'red', textAlign: 'center', fontSize: 18 }}>{this.state.messageCategoryExam}</div>
								{this.props.category.content ? <div
									dangerouslySetInnerHTML={{
										__html: this.props.category.content
									}}></div> : <div className="text-center" style={{ marginTop: 20, textAlign: 'center', fontSize: 18 }}></div>}



							</Modal> : null
						}

						{/* {
						this.state.video_link 
						? 
						<Modal
							footer={null}
							width={650}
							closable={true}
							visible={true}
							onOk={this.handleOk}
							onCancel={this.handleCancel}
							okText="Video bài giảng"
							cancelText="Đóng"
							cancelButtonProps={{
								style: {
									display: "none",
								},
							}}
						>
							<div>{this.renderVideoList(this.state.video_link)}</div>

						</Modal> : null
						} */}

						{
							this.state.isShowmodalVideo
								?
								<Modal
									footer={null}
									width={650}
									closable={true}
									visible={true}
									onOk={this.handleOk}
									onCancel={this.handleCancel}
									okText="Video bài giảng"
									cancelText="Đóng"
									cancelButtonProps={{
										style: {
											display: "none",
										},
									}}
								>
									<div>{this.renderVideoList()}</div>

								</Modal> : null
						}


						<Modal
							footer={null}
							width={(this.props.dataExamPreview && this.props.dataExamPreview.video_link) ? 750 : 300}
							closable={true}
							visible={visible}
							onOk={this.handleOk}
							onCancel={this.handleCancel}
							okText="Bắt đầu ngay"
							cancelText="Làm lại"
							cancelButtonProps={{
								style: {
									display: "none",
								},
							}}
						>
							{
								this.props.dataExamPreview && this.props.dataExamPreview.video_link ?
									<div>
										{this.renderVideo()}
										<div className="widget dataExamPreview trung123">
											<DocPreview />
										</div>
									</div> : <div>Bài giảng chưa được cập nhật!</div>
							}

						</Modal>

						<Modal
							className="modal-exam"
							confirmLoading={confirmLoadingNotify}
							bodyStyle={{
								minHeight: 200,
							}}
							closable={false}
							visible={visibleNotify}
							onOk={this.handSubmit}
							okButtonProps={{
								style: {
									backgroundColor: "#007bff",
									borderColor: "#007bff",
								},
							}}
							onCancel={this.handleCancelNotify}
							okText={
								<span style={{ color: "white" }}>Sẵn sàng</span>
							}
							cancelText="Bỏ qua"
						>
							<div className="widget">
								<div className="widget-left">
									<img src={Teacher} alt="hinh anh" />
								</div>
								<div className="widget-right">
									<p className="widget-info">
										Bạn đã sẵn sàng làm bài kiểm tra chưa?
									</p>
								</div>
							</div>
						</Modal>
					</div>
				</div >
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		testings: state.testing.testings,
		testingExamData: state.testing.testingExamData,
		examData: state.testing.examData,
		limit: state.testing.limit,
		page: state.testing.page,
		total: state.testing.total,
		overview: state.classroom.overviewClass,
		exams: state.testing.exams,
		dataExamPreview: state.testing.examPreview,
		errMessage: state.testing.errMessage,
		chapterCategories: state.classroom.chapterCategories,
		category: state.classroom.category
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{ listTesting, listExam, examPreview, showTesting, listChapterCategory, showCategory },
		dispatch
	);
}

withRouter(connect(mapStateToProps, mapDispatchToProps)(Row));
export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(ClassTesting)
);
