import { baseURL } from './config';
import axios from 'axios';
import { notification } from 'antd';

export const setCookie = (cname, cvalue, exdays) => {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookie = (cname) =>{
    const name = cname + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export const initAPI = () => {    
    axios.defaults.baseURL = baseURL;
    axios.defaults.headers.post['Content-Type'] = 'application/json';    
    if (getCookie("TOKEN")) {        
        axios.defaults.headers.common['Authorization'] = getCookie("TOKEN");
    }
}

export const redirectToLogin = code => {
    if (code === 401) {        
        setCookie("TOKEN", null, 0);
        window.location.href = '/login';
    }
};

export const notify = (res, showSuccess = true) => {
    if (res.data.code !== 200) {
        notification.warning({
            message: res.data.message,
            placement: 'topRight',
            top: 50,
            duration: 3,
        });
    }

    if (res.data.code === 200 && showSuccess) {
        notification.success({
            message: res.data.message,
            placement: 'topRight',
            top: 50,
            duration: 3,
        });
    }
};

export const responseError = (err, _redirectToLogin = true) => {
    if (err && err.response) {
        if (err.response.status === 401) {
            if (_redirectToLogin)
                redirectToLogin(err.response.status);
        } else {
            if (err.response.data && err.response.data.message)
                notification.warning({
                    message: err.response.data.message,
                    placement: 'topRight',
                    top: 50,
                    duration: 3,
                });
        }
    } else {
        notification.error({
            message: 'Lỗi. Vui lòng thử lại!',
            placement: 'topRight',
            top: 50,
            duration: 3,
        });
    }
};
