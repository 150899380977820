import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { showBill } from '../redux/profile/action';
import Moment from 'moment';

class Row extends Component {
	constructor(props) {
		super();
		this.state = {};
	}

	render() {
		return (
			<tr>
				<td>{this.props.obj.student_code}</td>
				<td>{this.props.obj.student_name}</td>
				<td>{this.props.obj.total}</td>
				<td>{this.props.obj.type}</td>
				<td>{Moment(this.props.obj.billed_at).format('DD/MM/YYYY')}</td>
			</tr>
		);
	}
}

class Bill extends Component {
	constructor(props) {
		super();
		this.state = {};
	}

	fetchRows() {
		if (this.props.dataBill.items instanceof Array) {
			return this.props.dataBill.items.map((object, i) => {
				return (
					<Row
						obj={object}
						key={object._id}
						index={i}
						{...this.props}
					/>
				);
			});
		}
		return (<tr>
			<td colSpan={5} style={{ textAlign: 'center' }}>Chưa có bản ghi nào!</td>
		</tr>);
	}

	async componentDidMount() {
		await this.props.showBill();
	}

	render() {
		return (
			<>
			<div id="header-home">
					<div className="page-title">
						<h2>Danh sách phiếu thu</h2>
					</div>
				</div>
				<div id="ranking" className="col-center page-container">
					<div className="table-list">
						<table className="table bordered">
							<thead>
								<tr>
									<th>Mã học sinh</th>
									<th>Họ tên</th>
									<th>Tổng tiền</th>
									<th>Loại</th>
									<th>Ngày nộp</th>
								</tr>
							</thead>

							<tbody>{this.fetchRows()}</tbody>
						</table>
					</div>
				</div>
			</>
		);
	}
}

function mapStateToProps(state) {
	return {
		dataBill: state.profile.dataBill,
	};
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ showBill }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Bill));
